<template>
  <!-- Breadcrumb -->
  <div class="breadcrumb-bar-two">
    <div class="container">
      <div class="row align-items-center inner-banner">
        <div class="col-md-5 col-6 ">
          <h2 class="breadcrumb-title">{{ $t(title) }}</h2>
          <nav aria-label="breadcrumb" class="page-breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link to="/">{{ $t(text) }}</router-link>
              </li>
              <li class="breadcrumb-item" aria-current="page">{{ $t(text1) }}</li>
            </ol>
          </nav>
        </div>
        <div class="col-md-7 col-12 search-box-one aos" style="position: relative;">
          <form @submit.prevent="submitForm">
             <div>
                <b-alert v-model="showAlert" variant="danger" dismissible>{{ alertMessage }}</b-alert>
              </div>
            <div class="search-input search-line">
              <i class="feather-search bficon"></i>
              <div class="form-group mb-0">
                <input
                  v-model="searchQuery.searchText"
                  type="text"
                  class="form-control"
                  :placeholder="$t('search_placeholder')"
                  @input="onInput"
                  @blur="hideSuggestions"
                  @focus="onInput"
                />
                <!-- Suggestions Dropdown -->
                <ul v-if="showSuggestions" class="suggestions-list">
                  <li v-for="item in filteredSuggestions" :key="item.id" @click="selectSuggestion(item)">
                    {{ item.Title || item.name }}
                    <small v-if="item.Title" class="suggestion-type">{{ $t('speciality') }}</small>
                    <small v-else-if="item.type === 'Doctor'" class="suggestion-type">{{ $t('doctor') }}</small>
                    <small v-else class="suggestion-type">{{ $t('clinic') }}</small>
                  </li>
                </ul>
              </div>
            </div>
            <div class="search-input search-map-line">
              <i class="feather-map-pin"></i>
              <div class="form-group mb-0">
                <input
                  v-model="searchQuery.area"
                  type="text"
                  class="form-control"
                  :placeholder="$t('city_placeholder')"
                  @input="filterCities"
                  @blur="hideCitySuggestions"
                  @focus="filterCities"
                />
                <!-- Suggestions Dropdown -->
                <ul v-if="showCitySuggestions" class="suggestions-list">
                  <li v-for="city in filteredCities" :key="city" @click="selectCitySuggestion(city)">
                    {{ city.City }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="form-search-btn">
              <b-button class="btn" type="submit">{{ $t('search_button') }}</b-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Breadcrumb -->
</template>

<script>
import axios from 'axios';
import specializations from "@/assets/json/specialization_settings_doctor.json";
import cities from "@/assets/json/cities.json";
import debounce from 'lodash.debounce';
export default {
  data() {
    return {
      searchQuery: {
        searchText: "",
        area: "",
      },
      specializations: specializations,
      filteredSuggestions: [],
      showSuggestions: false,
      cities: cities,
      filteredCities: [],
      showCitySuggestions: false,
      doctors: [],
      clinics: [],
      showAlert: false,
      alertMessage: ""
    };
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    text1: {
      type: String,
      default: "",
    },
    queryParam: {
      type: Object,
      default: {
        filter: "",
        city: "",
      },
    }
  },
  mounted() {
    this.setFilters();
    this.fetchDoctorNames();
  },
  methods: {
    setFilters() {
      this.searchQuery.searchText = this.queryParam.filter || "";
      this.searchQuery.area = this.queryParam.city || "";
    },
    submitForm() {
      const { searchText, area } = this.searchQuery;
      if (searchText.trim().length === 0) {
        this.alertMessage = this.$t("home_search_empty_alert");
        this.showAlert = true;
        return;
      }
      const searchParams = {
        filter: searchText.trim(),
        city:  area.length === 0 ? "Mangalore" : area.trim(),
      };
      
      this.$router.push({ 
        name: 'search',
        query: searchParams,
      });
    },
    async fetchDoctorNames() {
      try {
        const response = await axios.get('doctors/namelist');
        this.doctors = response.data.doctors.map((doctor, index) => ({
          id: index,
          name: doctor.name,
          type: 'Doctor'
        }));
        this.clinics = response.data.clinics.map((clinic, index) => ({
          id: index,
          name: clinic.name,
          type: 'Clinic'
        })); 
      } catch (error) {
        console.error("Error fetching doctor and clinic names:", error);
      }
    },
    filterSuggestions() {
      const query = this.searchQuery.searchText.toLowerCase();
      if (query.length > 0) {
        this.filteredSuggestions = [];
        const filteredSpecialities = this.specializations.filter(item =>
          item.Title.toLowerCase().includes(query)
        );
        const filteredDoctors = this.doctors.filter(doctor =>
          doctor.name && doctor.name.toLowerCase().includes(query)
        );
        const filteredClinics = this.clinics.filter(clinic =>
          clinic.name && clinic.name.toLowerCase().includes(query)
        );

        this.filteredSuggestions = [...filteredSpecialities, ...filteredDoctors, ...filteredClinics];
        this.showSuggestions = true;
      } else {
        this.filteredSuggestions = [];
        this.showSuggestions = false;
      }
    },
    selectSuggestion(item) {
      this.searchQuery.searchText = item.Title || item.name;
      this.showSuggestions = false;
      this.defaultList = false;
      this.searchKey = item.Title || item.name; 
      this.City = this.searchQuery.area.length === 0 ? "Mangalore" : this.searchQuery.area;
       const searchParams = {
        filter: this.searchKey.trim(),
        city: this.City.trim()
      };
      this.$router.push({
        name: "search",
        query: searchParams
      });
    },
    filterCities() {
    if (this.searchQuery.area.length > 0) {
      this.filteredCities = this.cities.filter((city) =>
        city.City.toLowerCase().includes(this.searchQuery.area.toLowerCase())
      );
      this.showCitySuggestions = true;
    } else {
      this.showCitySuggestions = false;
    }
  },
  onInput: debounce(function () {
      this.filterSuggestions();
    }, 300),
  selectCitySuggestion(city) {
    this.searchQuery.area = city.City;
    this.showCitySuggestions = false;
  },
    hideSuggestions() {
      setTimeout(() => {
        this.showSuggestions = false;
      }, 200); // Timeout to allow click event to register
    },
    hideCitySuggestions() {
    setTimeout(() => {
      this.showCitySuggestions = false;
   }, 200);
  },
  } 
};
</script>

<style>
.suggestions-list {
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  background-color: #fff;
  position: absolute;
  width: 100%;
  z-index: 10;
   overflow-y: auto;
   max-height: 10px;
}

.suggestions-list li {
  padding: 10px;
  cursor: pointer;
}

.suggestions-list li:hover {
  background-color: #f0f0f0;
}
.suggestion-type {
  font-size: 12px;
  color: #999999;
}
</style>
