<template>
  <div class="main-wrapper">
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <layoutheader></layoutheader>

      <!-- Page Content -->
      <div class="doctor-content">
        <div class="container">
          <!-- Booking Success -->
          <div class="row">
            <div class="col-md-12">
              
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-lg-6 col-md-8">
              <div class="success-content">
                <div class="success-icon">
                  <i class="fas fa-circle-check"></i>
                </div>
                <h4>{{ $t('your_appointment_booked_successfully') }}</h4>
              </div>
              <div class="card booking-card">
                <div class="card-body booking-card-body booking-list-body">
                  <div class="booking-doctor-left booking-success-info">
                    <div class="booking-doctor-img">
                      <a href="javascript:void(0);">
                        <img
                          :src="doctorImage || require('@/assets/img/doctors/doctor-thumb-01.jpg')"
                          :alt="doctorDetails.doctorname"
                          class="img-fluid"
                        />
                      </a>
                    </div>
                    <div class="booking-doctor-info">
                      <h4>
                        <a href="javascript:void(0);">{{ doctorDetails.doctorname }}</a>
                      </h4>
                      <p>{{ doctorDetails.qualifications }}, {{ doctorDetails.specialization }}</p>
                      <div class="booking-doctor-location">
                        <p>
                          <a target="_blank"
                            :href="'https://www.google.com/maps/search/?api=1&ll=12.9141%2C74.8560&query_place_id=&z=13&query='+clinicname">
                            <i class="fas fa-house-chimney-medical"></i> {{ clinicname }}</a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="booking-list">
                    <div class="booking-date-list consultation-date-list">
                      <ul>
                        <li>{{ $t('booking_date') }}: <span>{{ formatDate(appointmentDetails.appointmentdate) }}</span></li>
                        <li>{{ $t('booking_time') }}: <span>{{ formatTime(appointmentDetails.appointmenttime) }}</span></li>
                        <!-- <li>
                          Type of Consultaion:
                          <span><i class="feather-video"></i> Video Consulting</span>
                        </li> -->
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="success-btn">
                <a href="javascript:void(0);" class="btn btn-primary prime-btn">
                  Add to Google Calendar
                </a>
                <a href="javascript:void(0);" class="btn btn-light"> Appointment </a>
              </div> -->
              <div class="success-dashboard-link">
                <router-link :to="dashboardLink">
                  <i class="fa-solid fa-arrow-left-long"></i> {{ $t('back_to_dashboard') }}
                </router-link>
              </div>
            </div>
          </div>
          <!-- /Booking Success -->
        </div>
      </div>
      <!-- /Page Content -->

      <cursor></cursor>
    </div>
    <!-- /Main Wrapper -->
  </div>
</template>
<script>
import axios from "axios";
import { format } from 'date-fns';
import { enUS } from 'date-fns/locale';
import moment from "moment";

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

export default {
  data() {
    return {
      patientId: null,
      appointmentId: null,
      appointmentDetails: {},
      doctorDetails: {},
      clinicname:this.$store.getters.getAppointmentDraft.clinicname,
      doctorImage: this.$store.getters.getAppointmentDraft.doctorImage,
      usertype:'',
    };
  },
   computed: {
    dashboardLink() {
      switch (this.usertype) {
        case 'Doctor':
          return '/doctor-dashboard';
        case 'Patient':
          return '/patient-dashboard';
        case 'Clinic':
          return '/clinic-dashboard';  
        default:
          return '/';
      }
    }
  },
  methods:{
    formatDate(date) {
      if (!date) return "";
      const parsedDate = moment(date, "YYYY-MM-DD");
      if (!parsedDate.isValid()) {
        console.error("Invalid date format:", date);
        return "";
      }
      return parsedDate.toDate().toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric"
      });
    },
    formatTime(time) {
      console.log(this.appointmenttime);
      if (!time) return "";
      return new Date(`2022-01-01T${time}`).toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
    },
    async fetchAppointmentDetails() {
      try {
        const response = await axios.get(`/patients/${this.patientId}/appointments/${this.appointmentId}`);
        this.appointmentDetails = response.data;
        console.log('Appointment Details:', this.appointmentDetails);
      } catch (error) {
        console.error('Error fetching appointment details:', error);
      }
    },
    async fetchDoctorDetails() {
      const doctorId = this.appointmentDetails.doctorid;
      console.log(doctorId)
      try {
        let docDetails = sessionStorage.getItem(`doctorDetails${doctorId}`);
        if (!docDetails) {
          const response = await axios.get(`/doctors/${doctorId}`);
          docDetails = response.data;
          sessionStorage.setItem(`doctorDetails${doctorId}`, JSON.stringify(docDetails));
        } else {
          docDetails = JSON.parse(docDetails);
        }
        this.doctorDetails = docDetails;
      } catch (error) {
        console.error('Error fetching doctor details:', error);
      }
    },  

  },
  mounted() {
    const user_type = sessionStorage.getItem('usertype')
    this.usertype = user_type
    this.$store.dispatch("resetAppointmentDraft", {
      });
    this.$store.dispatch("resetPatientDetails", {
    });
    this.patientId = this.$route.query.patientId;
    this.appointmentId = this.$route.params.appointmentId;
    
    console.log('Patient ID:', this.patientId);
    console.log('Appointment ID:', this.appointmentId);

    this.fetchAppointmentDetails().then(() => {
      this.fetchDoctorDetails();
    });
  },
};
</script>
