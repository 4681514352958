<template>
  <div id="pat_medicalrecords" class="tab-pane fade show active">
    <div class="text-end">
      <a
        href="javascript:;"
        class="add-new-btn"
        data-bs-toggle="modal"
        data-bs-target="#add_medical_records_modal"
      >Add Medical Records</a>
    </div>
    <div class="card card-table mb-0">
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-hover table-center mb-0">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Date</th>
                <th>Report Name</th>
                <th>Attachment</th>
                <th>Orderd By</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in medical_records" :key="item.id">
                <td>{{ item.reportid }}</td>
                <td>{{ item.patientname }}</td>
                <td>
                  {{ formatDate(item.testdate) }}
                  <!-- <span class="d-block text-info">{{ item.Time }}</span> -->
                </td>
                <td>{{item.testname}}</td>
                <td>
                  <a
                    href="javascript:void(0);"
                    :title="item.testname"
                    class="btn btn-primary btn-sm"
                    @click="downloadRecord(item.reportid, item.patientid,  item.testname)"
                  >
                    <i class="fa fa-download"></i>
                  </a>
                </td>
                <td>{{ item.orderby_doctor }}</td>
                <td>
                  <a
                    href="javascript:void(0);"
                    class="btn btn-sm bg-danger-light me-2"
                    @click="deleteRecord(item.reportid, item.testname)"
                  >
                    <i class="far fa-trash-alt"></i>
                  </a>
                  <a
                    href="javascript:void(0)"
                    class="btn btn-sm bg-info-light"
                    data-bs-toggle="modal"
                    data-bs-target="#patient_accesss_modal"
                    @click="giveAccess(item.reportid)"
                  >
                    <i class="fas fa-lock"></i> Give Access
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import medical_records from "@/assets/json/patients/medical-records.json";
import axios from "axios";
import Swal from "sweetalert2";
export default {
  props: {
    medical_records: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      
    };
  },

  methods: {
    deleteRecord(reportId, reportName) {
      Swal.fire({
        title: "Confirm Deletion",
        text: `Are you sure you want to delete the record "${reportName}"?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.isConfirmed) {
          axios
            .put(`patients/deletereport/${reportId}`)
            .then(response => {
              console.log(response.data.message);
              this.$emit("records-deleted");
            })
            .catch(error => {
              console.error("Error deleting medical record:", error);
            });
        }
      });
    },
    formatDate(dateString) {
      const options = { year: "numeric", month: "short", day: "numeric" };
      const date = new Date(dateString);
      return date.toLocaleDateString("en-US", options);
    },
    giveAccess(item) {
      this.$emit("give-access", item);
    },
    downloadRecord(reportId, patientId, reportName) {
      axios({
        method: "get",
        url: `/patients/${patientId}/report/${reportId}/download`,
        responseType: "blob"
      })
        .then(response => {
          console.log("Response:", response);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const fileExtension = this.getFileExtension(reportId);
          const fullReportName = `${reportName}.${fileExtension}`;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fullReportName);
          document.body.appendChild(link);
          link.click();
        })
        .catch(error => {
          console.error("Error downloading medical record:", error);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Failed to download the record!"
          });
        });
    },
    getFileExtension(reportId) {
      const record = this.medical_records.find(
        item => item.reportid === reportId
      );
      if (record && record.testfile_path) {
        return record.testfile_path.split(".").pop();
      }
      return "txt";
    }
  }
};
</script>
