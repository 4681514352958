<template>
  <div class="account-page">
    <div class="main-wrapper">
      <layoutheader></layoutheader>

      <!-- Page Content -->
      <div class="content top-space">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-8 offset-md-2">
              <!-- Login Tab Content -->
              <div class="account-content">
                <div class="row align-items-center justify-content-center">
                  <div class="col-md-7 col-lg-6 login-left">
                    <img src="@/assets/img/login-banner.png" class="img-fluid" alt="YourDoc Login" />
                  </div>
                  <div class="col-md-12 col-lg-6 login-right">
                    <div class="login-header">
                      <h3>Login <span>YourDoc</span></h3>
                    </div>
                    <p v-if="error">
                      <b-alert v-model="showDismissibleAlert" variant="danger" dismissible="">{{ error }}</b-alert>
                    </p>
                    <div class="text" v-if="info">
                      Email not confirmed yet.<br>
                      <router-link class="forgot-link" to="" @click="resendOTP">Resend confirmation code</router-link>
                    </div>
                    <form @submit.prevent="submitForm">
                      <div class="mb-3 form-focus">
                        <input type="email" class="form-control floating" v-model="email" />
                        <label class="focus-label" :class="{ 'has-input': email }">Email</label>
                      </div>
                      <div class="mb-3 form-focus position-relative">
                        <input :type="showPassword ? 'text' : 'password'" class="form-control floating" v-model="pwd" />
                        <label class="focus-label" :class="{ 'has-input': pwd }">Password</label>
                        <button type="button" class="password-toggle" @click="togglePasswordVisibility">
                          <i :class="showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"></i>
                        </button>
                      </div>
                      <div class="text-end">
                        <router-link class="forgot-link" to="forgot-password">Forgot Password ?</router-link>
                      </div>
                      <b-button :disabled="working" class="btn btn-primary w-100 btn-lg login-btn" type="submit"><span
                          class="spinner-border spinner-border-sm me-2" role="status" v-if="working"></span>
                        Login
                      </b-button>
                      <!--<div class="login-or">
                        <span class="or-line"></span>
                        <span class="span-or">or</span>
                      </div>
                      <div class="row social-login">
                        <div class="col-6">
                          <a href="javascript:;" class="btn btn-facebook w-100"
                            ><i class="fab fa-facebook-f me-1"></i> Login</a
                          >
                        </div>
                        <div class="col-6">
                          <a href="javascript:;" class="btn btn-google w-100"
                            ><i class="fab fa-google me-1"></i> Login</a
                          >
                        </div>
                      </div>-->
                      <div class="text-center dont-have">
                        Don’t have an account?
                        <router-link to="register">Register</router-link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <!-- /Login Tab Content -->
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Content -->

      <indexfooter />
    </div>
  </div>
</template>
<script>
import { signIn, resendSignUpCode } from 'aws-amplify/auth';

export default {
  data() {
    return {
      email: '',
      pwd: '',
      error: null,
      info: null,
      working: false,
      showDismissibleAlert: false,
      showPassword: false,
    };
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    async resendOTP() {
      try {
        if (!this.email) {
          this.error = "Please enter your email address";
          this.showDismissibleAlert = true;
          return;
        }
        resendSignUpCode({
          username: this.email
        });
        localStorage.setItem("confirmotpemail", this.email[0] + "***@***");
        localStorage.setItem("signupemail", this.email);
        this.$router.push("/signup-otp");
      } catch (e) {
        console.log(e);
      }
    },
    async submitForm() {
      this.working = true;
      try {
        const { isSignedIn, nextStep } = await signIn(
          { username: this.email, password: this.pwd }
        );

        if (nextStep.signInStep === "CONFIRM_SIGN_UP") {
          this.working = false;
          this.info = "You have not yet confirmed your email";
        }
      } catch (error) {
        this.working = false;
        this.showDismissibleAlert = true;
        if (error.name === "UserNotFoundException") {
          this.error = "Invalid username or password";
        } else if (error.name === "UserNotConfirmedException") {
          this.error = "You have not yet confirmed your email";

        } else {
          console.log('error signing in', error);
          this.error = error.message;

        }
      }
    }
  },
};
</script>

<style scoped>
.position-relative {
  position: relative;
}

.password-toggle {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
}

.password-toggle .fas {
  font-size: 1.2em;
  color: #6c757d;
}
</style>
