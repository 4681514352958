<template>
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <breadcrumb :title="title" :text="text" :text1="text1" />

    <div class="content">
      <div class="container">
        <div class="row">
          <div class="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
            <div class="stickysidebar">
              <profilesidebar></profilesidebar>
            </div>
          </div>
          <div class="col-md-7 col-lg-8 col-xl-9">
            <div class="row">
              <div class="col-md-12">
                <div class="appointment-tab">
                  <ul class="nav nav-tabs nav-tabs-solid nav-tabs-rounded">
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        href="#favourites"
                        @click="selectTab('favourites')"
                        data-bs-toggle="tab"
                      >Favourites</a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="#recently-visited"
                        @click="selectTab('recently-visited')"
                        data-bs-toggle="tab"
                      >Recently Visited</a>
                    </li>
                  </ul>
                </div>
                <div class="tab-content">
                  <!-- Favorites Tab -->
                  <div class="tab-pane show active" id="favourites">
                    <div v-if="selectedTab === 'favourites'">
                      <div class="col-md-7 col-lg-8 col-xl-9">
                        <div class="row row-grid">
                          <div
                            class="col-md-6 col-lg-4 col-xl-3"
                            v-for="doctor in favouriteDoctors"
                            :key="doctor.doctorid"
                          >
                            <div class="profile-widget">
                              <div class="pro-content">
                                <h3 class="title">
                                  <router-link
                                    :to="'doctor/' + doctor.doctorid"
                                  >{{ doctor.doctorname }}</router-link>
                                  <i class="fas fa-check-circle verified ms-2"></i>
                                </h3>
                                <p class="speciality">{{ doctor.specialization }}</p>
                                <ul class="available-info">
                                  <li>
                                    <i class="fas fa-building"></i>
                                    {{ doctor.clinicname }}
                                  </li>
                                  <li>
                                    <i class="fas fa-map-marker-alt"></i>
                                    {{ doctor.area }}
                                  </li>
                                </ul>
                                <div class="row row-sm">
                                  <div class="col-6">
                                    <router-link
                                      :to="'doctor/' + doctor.doctorid"
                                      class="btn view-btn"
                                    >View Profile</router-link>
                                  </div>

                                  <div class="col-6">
                                    <button @click="bookAppointment(doctor.doctorid)" class="btn book-btn">Book Now</button>
                                  </div>
                                </div>
                                <ul></ul>
                                <div class="row row-sm">
                                  <button
                                    @click="removeFromFavorites(doctor)"
                                    class="cancel-btn"
                                  >Remove</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Recently Visited Tab -->
                  <div class="tab-pane" id="recently-visited">
                    <div v-if="selectedTab === 'recently-visited'">
                      <div class="col-md-7 col-lg-8 col-xl-9">
                        <div class="row row-grid">
                          <div
                            class="col-md-6 col-lg-4 col-xl-3"
                            v-for="doctor in recentlyVisitedDoctors"
                            :key="doctor.doctorid"
                          >
                            <div class="profile-widget">
                              <div class="pro-content">
                                <h3 class="title">
                                  <router-link
                                    :to="'doctor/' + doctor.doctorid"
                                  >{{ doctor.doctorname }}</router-link>
                                  <i class="fas fa-check-circle verified ms-2"></i>
                                </h3>
                                <p class="speciality">{{ doctor.specialization }}</p>
                                <ul class="available-info">
                                  <li>
                                    <i class="fas fa-building"></i>
                                    {{ doctor.clinicname }}
                                  </li>
                                  <li>
                                    <i class="fas fa-map-marker-alt"></i>
                                    {{ doctor.area }}
                                  </li>
                                </ul>
                                <div class="row row-sm">
                                  <div class="col-6">
                                    <router-link
                                      :to="'doctor/' + doctor.doctorid"
                                      class="btn view-btn"
                                    >View Profile</router-link>
                                  </div>
                                  <div class="col-6">
                                    <button @click="bookAppointment(doctor.doctorid)" class="btn book-btn">Book Now</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <indexfooter></indexfooter>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      title: "My Favourite Doctors",
      text: "Home",
      text1: "Favorites",
      selectedTab: "favourites",
      favouriteDoctors: [],
      recentlyVisitedDoctors: [],
      patientid: null
    };
  },
  methods: {
    async fetchFavouriteDoctors() {
      const PatientId = sessionStorage.getItem("userdbid");
      this.patientid = PatientId;
      try {
        const response = await axios.get(
          `/patients/${this.patientid}/favourites`
        );
        if (response.data && response.data.doctors) {
          this.favouriteDoctors = response.data.doctors;
        } else {
          console.error("Favourite doctors not found in the response.");
        }
      } catch (error) {
        console.error("Error fetching favourite doctors:", error);
      }
    },
    async fetchRecentlyVisitedDoctors() {
      const PatientId = sessionStorage.getItem("userdbid");
      this.patientid = PatientId;
      try {
        const response = await axios.get(`/patients/${this.patientid}/doctors`);
        if (response.data && response.data.doctors) {
          this.recentlyVisitedDoctors = response.data.doctors;
          console.log("response:", this.recentlyVisitedDoctors);
        } else {
          console.error("Most visited doctors not found in the response.");
        }
      } catch (error) {
        console.error("Error fetching most visited doctors:", error);
      }
    },
    selectTab(tab) {
      if (this.selectedTab !== tab) {
        this.selectedTab = tab;
        if (tab === "favourites") {
          this.fetchFavouriteDoctors();
        } else if (tab === "recently-visited") {
          this.fetchRecentlyVisitedDoctors();
        }
      }
    },
    async removeFromFavorites(doctor) {
      try {
        const response = await axios.post(
          `/patients/${this.patientid}/favourites/${doctor.doctorid}`
        );
        if (response.status === 200) {
          const index = this.favouriteDoctors.findIndex(
            doc => doc.doctorid === doctor.doctorid
          );
          if (index !== -1) {
            this.favouriteDoctors.splice(index, 1);
          }
          console.log("Doctor removed from favorites successfully.");
        } else {
          console.error("Failed to remove doctor from favorites.");
        }
      } catch (error) {
        console.error("Error removing doctor from favorites:", error);
      }
    },
    bookAppointment(doctorid) {
      this.$store.dispatch("updateAppointmentDraft", { doctorid: doctorid });
      this.$router.push({ path: `/booking/${doctorid}` });
    }
  },
  beforeMount() {
    this.fetchFavouriteDoctors();
  }
};
</script>
<style>
.cancel-btn {
  color: red;
  padding: 4px 10px;
  text-align: center;
  display: inline-block;
  border: red;
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
}
.cancel-btn:hover {
  background-color: red;
  color: white;
}
.nav-link {
  background-color: #d3d9df;
  border-color: #ced4da;
  transition: background-color 0.3s;
  margin: 5px 0;
}

.nav-link:hover {
  background-color: #0056b3;
}
</style>
