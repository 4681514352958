<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <breadcrumb-doctor :title="title" :text="text" :text1="text1" />

    <!-- Page Content -->
    <div class="content">
      <div class="container">
        <div class="row">
          <div class="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar dct-dashbd-lft">
            <router-link to="/doctor-dashboard">
              <i class="fa-solid fa-arrow-left-long"></i> Back to Dashboard
            </router-link>
            <div class="stickysidebar">
              <profilewidget :patientid="patientid" />

              <!-- Last Booking -->
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title">Last Booking</h4>
                </div>
                <ul class="list-group list-group-flush">
                  <div class="notify-block align-items-center d-flex">
                    <div class="me-3 flex-shrink-0">
                      <img
                        alt="Image placeholder"
                        src="@/assets/img/doctors/doctor-thumb-02.jpg"
                        class="avatar rounded-circle"
                      />
                    </div>
                    <div class="media-body flex-grow-1">
                      <h5 class="d-block mb-0">{{ doctordetails.doctorname }}</h5>
                      <span class="d-block text-sm text-muted">{{ doctordetails.specialization }}</span>
                      <span
                        class="d-block text-sm text-muted"
                        v-if="booking.appointmentdate"
                      >{{ formatDate(booking.appointmentdate) }}</span>
                      <div v-else>
                        <p>No booking found.</p>
                      </div>
                    </div>
                  </div>
                </ul>
              </div>
              <!-- /Last Booking -->
            </div>
          </div>

          <div class="col-md-7 col-lg-8 col-xl-9 dct-appoinment">
            <div class="card">
              <div class="card-body pt-0">
                <div class="user-tabs">
                  <ul class="nav nav-tabs nav-tabs-bottom nav-justified flex-wrap">
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        href="#pat_appointments"
                        data-bs-toggle="tab"
                      >Appointments</a>
                    </li>
                    <!--li class="nav-item">
                      <a class="nav-link" href="#pres" data-bs-toggle="tab"
                        ><span>Prescription</span></a
                      >
                    </li-->
                    <li class="nav-item">
                      <a class="nav-link" href="#medical" data-bs-toggle="tab"
                        ><span class="med-records">Medical Records</span></a
                      >
                    </li>
                    <!--li class="nav-item">
                      <a class="nav-link" href="#billing" data-bs-toggle="tab"
                        ><span>Billing</span></a
                      >
                    </-->
                  </ul>
                </div>
                <div class="tab-content">
                  <!-- Appointment Tab -->
                  <dappointment :patientid="patientid"></dappointment>
                  <!-- /Appointment Tab -->

                  <!-- Prescription Tab -->
                  <dprescription></dprescription>
                  <!-- /Prescription Tab -->

                  <!-- Medical Records Tab -->
                  <dmedicalrecords :patientId="patientid" :doctorId="doctorid" ref="dMedicalRecords"></dmedicalrecords>
                  <!-- /Medical Records Tab -->

                  <!-- Billing Tab -->
                  <dbilling></dbilling>
                  <!-- Billing Tab -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->
    <doctorfooter></doctorfooter>
  </div>
  <!-- /Main Wrapper -->
     <div class="modal fade custom-modal custom-medicalrecord-modal" id="add_medical_records" style="display: none;" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Medical Records</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <form id="medical_records_form" @submit.prevent="submitForm" enctype="multipart/form-data">
          <div class="modal-body">
            <b-alert v-model="showDismissibleAlert" variant="danger" dismissible>{{ error }}</b-alert>
            <div class="mb-3">
              <label class="mb-2">Test Name</label>
              <input v-model="testname" type="text" name="testname" class="form-control" placeholder="Enter Test Name" required>
            </div>
            <div class="mb-3">
              <label class="mb-2">Ordered By</label>
              <input v-model="orderedby" type="text" name="orderedby" class="form-control" placeholder="Enter Ordered By Name" required>
            </div>
            <div class="mb-3">
              <label class="mb-2">Test Date</label>
              <datepicker v-model="testdate" class="form-control datetimepicker" placeholder="Select Test Date" :editable="true" :clearable="false" :input-format="dateFormat"></datepicker>
            </div>
            <div class="mb-3">
              <label class="mb-2">Upload Report</label>
              <input type="file" name="report_file" class="form-control" @change="handleFileUpload" ref="fileInput" required>
            </div>
            <div class="text-center mt-4">
              <div class="submit-section text-center">
                <button type="submit" class="btn btn-primary submit-btn">Submit</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- /Add Medical Records Modal -->
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      dateFormat: "dd-MM-yyyy",
      testname: null,
      orderedby: null,
      testdate: new Date(),
      report_file: null,
      showDismissibleAlert: false,
      error: null,
      title: "Profile",
      text: "Home",
      text1: "Profile",
      doctorid: null,
      patientid:null,
      booking: {
        appointmentdate: "",
        appointmenttime: ""
      },

      doctordetails: {
        doctorname: "",
        specialization: ""
      }
    };
  },
  beforeMount() {
    this.patientid = this.$route.params.patientid;
    this.fetchLastBooking();
  },
  methods: {
    async fetchLastBooking() {
      const patientid = this.$route.params.patientid;
      console.log("Selected Patient ID:", patientid);
      this.doctorid = sessionStorage.getItem("userdbid");

      try {
        const response = await axios.get(
          `/patients/${this.$route.params.patientid}/lastappointment/${this.doctorid}`
        );
        console.log("Selected Patient ID2:", patientid);
        if (response.data !== null) {
          this.booking = response.data;
          this.fetchDoctorDetails();
        } else {
          console.log("No data found");
        }
      } catch (error) {
        console.log("Selected Patient ID3:", patientid);
        console.error("Error fetching last appointment details:", error);
      }
    },
    async fetchDoctorDetails() {
      try {
        let docDetails = sessionStorage.getItem(
          `doctorDetails${this.doctorid}`
        );
        if (!docDetails) {
          const response = await axios.get(`/doctors/${this.doctorid}`);
          docDetails = response.data;
          sessionStorage.setItem(
            `doctorDetails${this.doctorid}`,
            JSON.stringify(docDetails)
          );
        } else {
          docDetails = JSON.parse(docDetails);
        }
        this.doctordetails = docDetails;
      } catch (error) {
        console.error("Error fetching doctor details:", error);
      }
    },
    formatDate(date) {
      const parsedDate = new Date(date);
      const day = parsedDate.getDate();
      const month = parsedDate.getMonth() + 1;
      const year = parsedDate.getFullYear();

      const formattedDate = `${day}/${month}/${year}`;

      return formattedDate;
    },
    handleFileUpload(event) {
      this.report_file = event.target.files[0];
    },
    submitForm() {
      const formattedDate = this.testdate.toISOString().slice(0, 10);
      const formData = new FormData();
      formData.append("patientid", this.patientid);
      formData.append("appointmentid", 0);
      formData.append("test_name", this.testname);
      formData.append("order_by_doctor", this.orderedby);
      formData.append("test_date", formattedDate);
      formData.append("account_type", "D");
      formData.append("report_file", this.report_file);

      let testFilePath = `reports/patient/${
        this.patientid
      }/${new Date().getFullYear()}/${this.$refs.fileInput.files[0].name}`;
      formData.append("test_file_path", testFilePath);

      const headers = {
        "Content-Type": "multipart/form-data"
      };
      axios
        .post(`/patients/${this.doctorid}/report`, formData, { headers })
        .then(response => {
          console.log("Report created successfully:", response.data);
          if (response.status === 200) {
            console.log("Report created successfully:", response.data);
            Swal.fire({
              icon: "success",
              title: "Medical Records Added Successfully",
              showConfirmButton: false,
              timer: 1500
            });
            this.$refs.dMedicalRecords.fetchMedicalRecords();
            const closeButton = document.querySelector(
              "#add_medical_records .btn-close"
            );
            if (closeButton) {
              closeButton.click();
              this.error = "";
              this.showDismissibleAlert = false;
              this.selectedPatient = null;
              this.testname = null;
              this.testdate = null;
              this.orderedby = null;
              this.report_file = null;
            }
          }
        })
        .catch(error => {
          console.error("Error creating report:", error);
          if (
            error.response &&
            error.response.status === 400 &&
            error.response.data &&
            error.response.data.detail
          ) {
            this.error = error.response.data.detail;
            this.showDismissibleAlert = true;
          } else {
            this.error =
              "An unexpected error occurred. Please try again later.";
            this.showDismissibleAlert = true;
          }
        });
    }
  }
};
</script>
