<template>
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <breadcrumb-doctor :title="title" :text="text" :text1="text1" />
    <div class="content">
      <div class="container">
        <div class="row">
          <span style="display: flex; justify-content: flex-end;">
            <label class="custom_check">
              <input
                type="checkbox"
                id="active-appointments"
                v-model="isActive"
                @change="toggleActiveStatus"
                checked
              />
              <span class="checkmark"></span>
              Include Cancelled
            </label>
          </span>
          <doctorsidebar></doctorsidebar>

          <div class="col-md-7 col-lg-8 col-xl-9">
            <div class="row">
              <div class="col-sm-12">
                <div class="card">
                  <div class="card-body">
                    <div class="col-md-12 col-lg-12 col-xl-12">
                      <div>
                        <b-alert v-model="showError" dismissible variant="danger">{{ error }} </b-alert>
                      </div>
                      <form @submit.prevent="submitForm">
                        <div class="row align-items-center inner-banner" style="margin-left: 1px; margin-right: 1px;">
                          <div class="search-box-one aos">
                            <div class="search-input search-map-lineapphistory">
                              <i class="feather-phone bficon" style="margin-left: 8px;"></i>
                              <div class="form-group mb-0" style="margin-left: 8px;">
                                <input v-model="patient.contactNumber" type="text" class="form-control"
                                  placeholder="Contact number" maxlength="12"
                                  :class="{ 'is-invalid': phoneTouched && !validatePhoneNumber() }"
                                  @blur="phoneTouched = true" />
                              </div>
                            </div>
                            <div class="search-input search-map-lineapphistory">
                              <i class="feather-mail" style="margin-left: 8px;"></i>
                              <div class="form-group mb-0" style="margin-left: 8px;">
                                <input v-model="patient.email" type="text" class="form-control"
                                  placeholder="Patient email" maxlength="45"
                                  :class="{ 'is-invalid': emailTouched && !validateEmail() }"
                                  @blur="emailTouched = true" />
                              </div>
                            </div>
                            <div class="search-input search-map-lineapphistory">
                              <i class="feather-user" style="margin-left: 8px;"></i>
                              <div class="form-group mb-0" style="margin-left: 8px;">
                                <input v-model="patient.name" type="text" class="form-control"
                                  placeholder="Patient name"
                                  :class="{ 'is-invalid': firstnameTouched && !validatefirstname() }"
                                  @blur="firstnameTouched = true" maxlength="45" />
                              </div>
                            </div>
                            <div class="search-input search-map-lineapphistory">
                              <i class="feather-calendar" style="margin-left: 8px;"></i>
                              <div class="form-group mb-0" style="margin-left: 8px;">
                                <datepicker v-model="appointmentdate" placeholder="Appt date"
                                  class="form-control datetimepicker" :editable="false" :clearable="false"
                                  :input-format="dateFormat" />
                              </div>
                            </div>
                            <div class="search-input">
                              <i class="feather-list" style="margin-left: 8px;"></i>
                              <div class="form-group mb-0" style="margin-left: 35px;">
                                <select v-model="selectedClinic" class="form-control custom-select"
                                  style="color: #339966; font-size: 13px;">
                                  <option v-for="schedule in schedules" :value="schedule.clinicid"
                                    style="background-color: #f8f9fa; color: #339966; padding: 10px;">{{
                                      schedule.clinicname }}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="form-search-btnx col-12 text-center"
                          style="margin-top: 20px; margin-bottom: 35px;">
                          <b-button class="btnxr" @click="resetForm">Reset</b-button>
                          <b-button class="btn" type="submit" style="margin-left: 50px;">Search</b-button>
                        </div>
                      </form>
                      <div v-if="searchPerformed" class="col-md-16 col-lg-17 col-xl-11 mx-auto">
                        <div class="tab-pane show active" id="today-appointments">
                          <div class="card card-table mb-0">
                            <div class="card-body">
                              <div class="table-responsive">
                                <table class="table table-hover table-center mb-0">
                                  <thead>
                                    <tr>
                                      <th>Patient Information</th>
                                      <th>Appt Date & Time</th>
                                      <th>Clinic Name</th>
                                      <th>Reason</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="appointment in pastAppointmentfilter " :key="appointment.appointmentid">
                                      <td>
                                        <h2 class="table-avatar">
                                          <router-link :to="'patient-profile/' + patient.patientid"
                                            class="avatar avatar-sm me-2">
                                            <img class="avatar-img rounded-circle" :src="getPatientImage(appointment)
                                              " alt="User Image" />
                                          </router-link>
                                          <router-link :to="'patient-profile/' + appointment.patientid">
                                            {{ appointment.firstname }} {{ appointment.lastname }}
                                            <span>{{ appointment.email }}</span>
                                          </router-link>
                                        </h2>
                                      </td>
                                      <td>{{ formatDate(appointment.appointmentdate) }} {{
                                        formatTime(appointment.appointmenttime) }}</td>
                                      <td>
                                        <h2><router-link :to="'patient-profile/' + appointment.patientid">{{
                                          appointment.clinicname }}<span>{{ appointment.locationname
                                              }}</span></router-link>
                                        </h2>
                                      </td>
                                      <td>{{ appointment.reason }}</td>
                                      <td>
                                        <div class="appointment-action">
                                          <a href="javascript:void(0)" class="btn btn-sm bg-info-light"
                                            data-bs-toggle="modal" @click="viewAppointmentDetails(appointment)"
                                            data-bs-target="#appt_details">
                                            <i class="far fa-eye"></i> View
                                          </a>
                                          <a v-if="isPastAppointment(appointment)" href="javascript:void(0);"
                                            class="btn btn-sm bg-secondary-light">
                                            <i class="fas fa-history"></i> Past
                                          </a>
                                          <a v-else-if="appointment.appointmentstatus === 'E'"
                                            href="javascript:void(0);" class="btn btn-sm bg-danger-light"
                                            @click="cancelAppointment(appointment)">
                                            <i class="fas fa-times"></i> Cancel
                                          </a>
                                          <span v-else>
                                            <span class="badge bg-danger text-white ms-2">Cancelled</span>
                                          </span>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade custom-modal" id="appt_details">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Appointment Details</h5>
          <b-button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></b-button>
        </div>
        <div class="modal-body">
          <ul class="info-details">
            <li>
              <div class="details-header">
                <div class="row">
                  <div class="col-md-6">
            <li>
              <span class="title">Appointment ID:</span>
              <span class="text">{{ selectedAppointment.appointmentid }}</span>
            </li>
            <li>
              <span class="title">Appointment Date & Time:</span>
              <span class="text">{{ formatDate(selectedAppointment.appointmentdate) }} {{
                formatTime(selectedAppointment.appointmenttime) }}</span>
            </li>
        </div>
        <div class="col-md-6">
          <div class="text-end">
            <b-button type="button"
              :class="{ 'btn bg-success-light btn-sm': appointmentStatus === 'Upcoming', 'btn bg-danger-light btn-sm': appointmentStatus !== 'Upcoming' }"
              id="topup_status">{{ appointmentStatus }}</b-button>
          </div>
        </div>
      </div>
    </div>
    </li>
    <li>
      <span class="title">Status:</span>
      <span class="text">{{ appointmentStatus }}</span>
    </li>
    <li>
      <span class="title">Confirm Date:</span>
      <span class="text">{{ formatDate(selectedAppointment.createddate) }}</span>
    </li>
    </ul>
  </div>
  </div>
  </div>
  </div>
  <doctorfooter />
</template>

<script>
import axios from "axios";
import appointment from "@/assets/json/doctor/appointment.json";
import "sweetalert2/dist/sweetalert2.css";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default {
  data() {
    return {
      patient: {
        contactNumber: '',
        email: '',
        name: '',
      },
      dateFormatx: 'yyyy-MM-dd',
      doctorid: null,
      patientid: null,
      searchResults: [],
      schedules: [],
      showError: false,
      error: null,
      firstnameTouched: false,
      lastnameTouched: false,
      emailTouched: false,
      phoneTouched: false,
      searchPerformed: null,
      title: "Appointments History",
      text: "Home",
      text1: "Appointments History",
      appointment: appointment,
      upcomingAppointments: [],
      pastAppointmentfilter: [],
      selectedAppointment: {},
      isActive: true,
      selectedClinic: '',
      appointmentdate: null,
    };
  },
  computed: {
    appointmentStatus() {
      const currentDate = new Date();
      const appointmentDate = new Date(
        `${this.selectedAppointment.appointmentdate}T${this.selectedAppointment.appointmenttime}`
      );

      if (this.selectedAppointment.appointmentstatus === "C") {
        return "Cancelled";
      } else if (appointmentDate < currentDate) {
        return "Past";
      } else {
        return "Upcoming";
      }
    }
  },
  mounted() {
    this.fetchScheduleData();
  },
  methods: {
    resetForm() {
      this.patient.contactNumber = '';
      this.patient.email = '';
      this.patient.name = '';
      this.appointmentdate = null;
      this.phoneTouched = false;
      this.emailTouched = false;
      this.firstnameTouched = false;
      if (this.schedules.length > 0) {
        this.selectedClinic = this.schedules[0].clinicid;
      }
      this.searchPerformed = false;
    },
    validatefirstname() {
      const firstnameRegex = /^([A-Za-z\s]*)$/
      return firstnameRegex.test(this.patient.name);
    },
    validateEmail() {
      const emailRegex = /^$|^([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.[a-zA-Z][a-zA-Z0-9-]{0,45}[a-zA-Z])$/;
      return emailRegex.test(this.patient.email);
    },
    validatePhoneNumber() {
      const phoneNumberRegex = /^(\s*|\d{10,12})$/;
      return phoneNumberRegex.test(this.patient.contactNumber);
    },
    focusInput() {
      this.$refs.dateInput.focus();
    },
    toggleActiveStatus() {
      this.isActive = !this.isActive;
      this.toggle();
    },
    toggle() {
      this.isActive = !this.isActive;
      this.filterPastAppointments();
    },
    async fetchScheduleData() {
      const doctorId = sessionStorage.getItem("userdbid");
      this.doctorid = doctorId;
      try {
        const response = await axios.get(`/doctors/${doctorId}/scheduletiming`);
        const uniqueSchedules = new Set();
        this.schedules = response.data.filter(schedule => {
          const clinicId = schedule.clinicid;
          if (!uniqueSchedules.has(clinicId)) {
            uniqueSchedules.add(clinicId);
            return true;
          }
          return false;
        });
        if (this.schedules.length > 0) {
          this.selectedClinic = this.schedules[0].clinicid;
        }
      } catch (error) {
        this.error = error.message;
        console.error("Error fetching schedule data:", error);
      }
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric"
      });
    },
    formatDatex(date) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    async searchPatientAppointments() {
      const doctorId = sessionStorage.getItem("userdbid")
      let datestring
      if (this.appointmentdate != null) {
        datestring = new Date(this.appointmentdate).toLocaleDateString('en-CA').substring(0, 10);
      }
      try {
        const response = await axios.get(`/clinic/${this.selectedClinic}/pastappointments/${doctorId}`, {
          params: {
            name: this.patient.name,
            contactnumber: this.patient.contactNumber,
            email: this.patient.email,
            date: datestring
          }
        });
        console.log("Response from API:", response.data);
        if (response.data && response.data.appointmentlist) {
          this.pastAppointmentfilter = response.data.appointmentlist;
          return true;
        } else {
          this.pastAppointmentfilter = [];
          return false;

        }
      } catch (error) {
        console.error("Error searching patient appointments:", error);
      }
    },

    filterPastAppointments() {
      this.pastAppointments = this.upcomingAppointments.filter(appointment => {
        return this.isPastAppointment(appointment);
      });
      if (!this.isActive) {
        this.pastAppointmentfilter = this.pastAppointments.filter(
          appointment => {
            return appointment.appointmentstatus === "E";
          }
        );
      } else {
        this.pastAppointmentfilter = [...this.pastAppointments];
      }
      console.log("Filtered appointments:", this.filteredAppointments);
    },
    isPastAppointment(appointment) {
      const currentDateTime = new Date();
      const appointmentDateTime = new Date(
        `${appointment.appointmentdate}T${appointment.appointmenttime}`
      );
      return appointmentDateTime < currentDateTime;
    },
    formatTime(time) {
      return new Date(`2022-01-01T${time}`).toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true
      });
    },
    getPatientImage(appointment) {
      return (
        appointment.patientimage || require("@/assets/img/patients/patient.jpg")
      );
    },
    fetchUpcomingAppointments() {
      const doctorId = sessionStorage.getItem("userdbid");
      axios
        .get(`/doctors/${doctorId}/appointments`)
        .then(response => {
          this.upcomingAppointments = response.data.all_appointments;
          this.filterPastAppointments();
        })
        .catch(error => {
          console.error("Error fetching upcoming appointments", error);
        });
    },
    viewAppointmentDetails(appointment) {
      this.selectedAppointment = appointment;
    },
    cancelAppointment(appointment) {
      const {
        appointmentid,
        appointmentby,
        appointmentdate,
        appointmenttime
      } = appointment;
      Swal.fire({
        title: "Are you sure?",
        text: `Do you want to cancel the appointment on ${this.formatDate(
          appointmentdate
        )} at ${this.formatTime(appointmenttime)}?`,
        input: "text",
        inputLabel: "Reason for cancellation",
        inputPlaceholder: "Enter reason here...",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, cancel it!"
      }).then(result => {
        if (result.isConfirmed) {
          const cancelReason = result.value;
          const cancelApiUrl = `/patients/${appointmentby}/appointments/${appointmentid}`;
          axios
            .put(cancelApiUrl, { cancelreason: cancelReason })
            .then(response => {
              console.log("Appointment cancelled successfully", response.data);
              this.fetchUpcomingAppointments();
            })
            .catch(error => {
              console.error("Error cancelling appointment", error);
            });
        }
      });
    },
    async submitForm() {
      this.showError = false;
      let invalids = [];
      if (!this.patient.contactNumber && !this.patient.email && !this.patient.name && !this.appointmentdate) {
        this.error = "Please enter contact number or email or  appt date or, name";
        this.showError = true;
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      }
      if (this.firstnameTouched && !this.validatefirstname()) {
        invalids.push("valid first name");
      }
      if (this.emailTouched && !this.validateEmail()) {
        invalids.push(" valid email");
      }
      if (this.phoneTouched && !this.validatePhoneNumber()) {
        invalids.push("valid Phone number");
      }
      if (invalids.length > 0) {
        this.error = "Please enter " + invalids.join(", ");
        this.showError = true;
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      }
      await this.searchPatientAppointments();
      if (this.pastAppointmentfilter.length === 0) {
        this.error = "No records found";
        this.showError = true;
        if (window.innerWidth === screen.width && window.innerHeight === screen.height) {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
      }
      else {
        this.searchPerformed = true;
        if (window.innerWidth > 767) {
          window.scrollTo({ top: 200, behavior: "smooth" });
        }
      }
    },
  },
  beforeMount() {
    this.fetchUpcomingAppointments();
  },
  name: "appointments"
};
</script>
<style scoped>
.modal-content .btn {
  cursor: auto;
}
</style>
