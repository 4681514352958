<template>
  <div id="pat_appointments" class="tab-pane fade show active">
    <div class="card card-table mb-0">
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-hover table-center mb-0">
            <thead>
              <tr>
                <th>Patient Name</th>
                <th>Doctor</th>
                <th>Appt Date</th>
                <th>Booking Date</th>
                <th>Clinic Name</th>
                <!-- <th>Amount</th> -->
                <!-- <th>Follow Up</th> -->
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in futureAppointments" :key="item.appointment_id">
                <td>
                  <h2 class="table-avatar">
                    <span class="avatar avatar-sm me-2">
                      <img src="@/assets/img/patients/patient.jpg" alt="User Image" />
                    </span>
                    {{item.patient_name}}
                  </h2>
                </td>
                <td>
                  <h2 class="table-avatar">
                    <router-link :to="'doctor/' + item.doctor_id" class="avatar avatar-sm me-2">
                      <img
                        class="avatar-img rounded-circle"
                        :src="//item.doctor_image ? require('@/assets/img/doctors/'+item.doctor_image) : 
                        require('@/assets/img/doctors/doctor-thumb-01.jpg')"
                        alt="Doctor Image"
                      />
                    </router-link>
                    <router-link :to="'doctor/' + item.doctor_id">
                      {{ item.doctor_name }}
                      <span>{{ item.speciality }}</span>
                    </router-link>
                  </h2>
                </td>
                <td>
                  {{ formatDate(item.date_and_time) }}
                  <span
                    class="d-block text-info"
                  >{{ formatTime(item.date_and_time) }}</span>
                </td>
                <td>{{ formatDate(item.createddate) }}</td>
                <td>{{ item.clinic_name }}</td>
                <!-- <td>{{ item.amount }}</td> -->
                <!-- <td>{{ item.FollowUp }}</td> -->
                <td>
                  <span
                    :class="getStatusAndClass(item.appointmentstatus).class"
                  >{{ getStatusAndClass(item.appointmentstatus).status }}</span>
                </td>
                <td class="text-end">
                  <div class="table-action">
                    <!-- <a href="javascript:void(0);" class="btn btn-sm bg-primary-light ms-3">
                      <i class="fas fa-print"></i> Print
                    </a>
                    <a href="javascript:void(0);" class="btn btn-sm bg-info-light ms-1">
                      <i class="far fa-eye"></i> View
                    </a> -->
                    <a
                      v-if="item.appointmentstatus === 'E'"
                      href="javascript:void(0);"
                      class="btn btn-sm bg-danger-light me-2"
                      @click="cancelAppointment(item)"
                    >
                      <i class="fas fa-times"></i> Cancel
                    </a>
                    <a
                    href="javascript:void(0)"
                    class="btn btn-sm bg-info-light"
                    data-bs-toggle="modal"
                    data-bs-target="#appointment_medical_records_modal"
                    @click="addMedicalRecords(item.appointment_id, item.patientid, item.doctor_id)"
                    >
                    <i class="fas fa-file-medical"></i> Add Medical Record
                  </a>
                  <a href="javascript:void(0);" 
                       class="btn btn-sm bg-info-light ms-1" 
                       data-bs-toggle="modal"
                       data-bs-target="#view_medical_records_modal"
                       @click="viewMedicalRecords(item.patientid,item.appointment_id,item.doctor_id)">
                      <i class="far fa-eye"></i> View Records
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";
import patientappointment from "@/assets/json/patientdashboard/patientappointment.json";
export default {
  props: {
    futureAppointments: {
      type: Array,
      default: () => []
    },
    fetchAppointments: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      // patientappointment: patientappointment,
    };
  },
  methods: {
    formatedTime(time) {
      return new Date(`2022-01-01T${time}`).toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true
      });
    },

    formatDate(dateTimeString) {
      const date = new Date(dateTimeString);
      const options = { day: "numeric", month: "short", year: "numeric" };
      return date.toLocaleDateString("en-US", options);
    },
    formatTime(dateTimeString) {
      const date = new Date(dateTimeString);
      const options = { hour: "2-digit", minute: "2-digit", hour12: false };
      return date.toLocaleTimeString("en-US", options);
    },
    getStatusAndClass(appointmentStatus) {
      let status = "";
      let cssClass = "";

      if (appointmentStatus === "E") {
        status = "Confirmed";
        cssClass = "badge rounded-pill bg-success-light";
      } else if (appointmentStatus === "C") {
        status = "Cancelled";
        cssClass = "badge rounded-pill bg-danger-light";
      }

      return {
        status,
        class: cssClass
      };
    },
    cancelAppointment(appointment) {
      const appointmentby = sessionStorage.getItem("userdbid");

      const { appointment_id, date_and_time } = appointment;

      const [appointmentDate, appointmentTime] = date_and_time.split("T");

      Swal.fire({
        title: "Are you sure?",
        text: `Do you want to cancel the appointment on ${this.formatDate(
          appointmentDate
        )} at ${this.formatedTime(appointmentTime)}?`,
        input: "text",
        showCancelButton: false,
        inputLabel: "Reason for cancellation",
        inputPlaceholder: "Enter reason here...",
        icon: "warning",
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Confirm Cancel",
        customClass: {
          popup: 'swal-popup-custom', 
          closeButton: 'swal-close-button-custom',
        },
        showCloseButton: true,  
        closeButtonHtml: '&times;'
      }).then(result => {
        if (result.isConfirmed) {
          const cancelReason = result.value;

          const cancelApiUrl = `/patients/${appointmentby}/appointments/${appointment_id}`;
          axios
            .put(cancelApiUrl, { cancelreason: cancelReason })
            .then(response => {
              console.log("Appointment cancelled successfully", response.data);
              this.fetchAppointments();
            })

            .catch(error => {
              console.error("Error cancelling appointment", error);
            });
        }
      });
    },
    addMedicalRecords(appointmentId, patientId, doctorId ) {
    this.$emit('add-medical-records', { appointmentId, patientId, doctorId });
  },
  viewMedicalRecords(patientId, appointmentId, doctorId) {
      this.$emit('view-medical-records', { patientId, appointmentId, doctorId });
    }
  }
};
</script>

<style scoped>
  .swal-popup-custom {
    position: relative;
  }

  .swal-close-button-custom {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
    color: #d33;
  }
</style>
