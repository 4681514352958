<template>
    <div class="main-wrapper">
        <breadcrumb-doctor :title="title" :text="text" :text1="text1" />
        <div class="content">
            <div class="container">
                <div class="row">
                    <doctorsidebar @doctor-image="updateDoctorImage"></doctorsidebar>
                    <div class="col-md-7 col-lg-8 col-xl-9">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="card">
                                    <div class="card-body">
                                        <searchby-doc :doctor-image="doctorImage"></searchby-doc>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <doctorfooter />
    </div>
</template>
<script>
import axios from "axios";
import SearchByDoc from '@/views/frontend/pages/doctors/appointmentbydoctor/searchby-doc.vue';
import RegisterByDoc from '@/views/frontend/pages/doctors/appointmentbydoctor/registerby-doc.vue';
export default {
    components: {
        'searchby-doc': SearchByDoc,
        'registerby-doc': RegisterByDoc,
    },
    data() {
        return {
            title: "Add Appointment",
            text: "Home",
            text1: "Add Appointment",
            doctorId: null,
            doctorDetails: {},
            doctorImage:"",
            error: "",
        };
    },
    methods: {
        async fetchDoctorDetails(doctorId) {
            try {
                const response = await axios.get(`doctors/${doctorId}`);
                this.doctorDetails = response.data;
                this.setSelectedDurationFromDoctorDetails();
            } catch (error) {
                console.error("Error fetching doctor details:", error);
            }
        },
        updateDoctorImage(image) {
        this.doctorImage = image;
        console.log("this is image", this.doctorImage)
        },
    },
    beforeMount() {
        const doctorId = sessionStorage.getItem("userdbid");
        this.fetchDoctorDetails(doctorId);
        this.doctorId = doctorId;
    }
};
</script>