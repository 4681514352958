<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    
    <layoutheader v-if="!isModalOpen"></layoutheader>
    <breadcrumb-doctor :title="title" :text="text" :text1="text1" />
    <!-- Page Content -->
    <div class="content">
      <div class="container">
        <div class="row">
          <doctorsidebar :doctorDetails="doctorDetails"></doctorsidebar>

          <div class="col-md-7 col-lg-8 col-xl-9">
            <div class="row">
              <div class="col-md-12">
                <div class="card dash-card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-12 col-lg-4">
                        <div class="dash-widget dct-border-rht">
                          <div class="circle-bar circle-bar2">
                            <div class="circle-graph2">
                              <circle-progress :percent="65" />
                              <img src="@/assets/img/icon-02.png" class="img-fluid" alt="Patient" />
                            </div>
                          </div>
                          <div class="dash-widget-info">
                            <h6>Appointments Today</h6>
                            <h3>{{statDetails.todayAppointments}}</h3>
                            <p class="text-muted">{{formattedDate}}</p>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-12 col-lg-4">
                        <div class="dash-widget dct-border-rht">
                          <div class="circle-bar circle-bar3">
                            <div class="circle-graph3">
                              <circle-progress :percent="50" />
                              <img src="@/assets/img/icon-03.png" class="img-fluid" alt="Patient" />
                            </div>
                          </div>
                          <div class="dash-widget-info">
                            <h6>Upcomming Appointments</h6>
                            <h3>{{statDetails.upcomingAppointment}}</h3>
                            <p class="text-muted">{{formattedDate}}</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 col-lg-4">
                        <div class="dash-widget dct-border-rht">
                          <div class="circle-bar circle-bar1">
                            <div class="circle-graph1">
                              <circle-progress :percent="75" />
                              <img src="@/assets/img/icon-01.png" class="img-fluid" alt="patient" />
                            </div>
                          </div>
                          <div class="dash-widget-info">
                            <h6>Total Patients</h6>
                            <h3>{{statDetails.totalPatients}}</h3>
                            <p class="text-muted">Till Today</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <h4 class="mb-4">Patient Appointments</h4>
                <div class="d-flex justify-content-between mb-3">
                   <router-link to="/addappointment" class="btn btn-primary">
                     Add Appointment
                  </router-link>
                  <div>
                    <a
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="PDF"
                      @click="exportAppointments('pdf')"
                    >
                    <p class="download-text">Download</p>
                      <img src="@/assets/img/pdf.svg" alt="Export to PDF" class="export-icon" />
                      
                    </a>
                    <a
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Excel"
                      @click="exportAppointments('excel')"
                    >
                    
                      <img src="@/assets/img/excel.svg" alt="Export to Excel" class="export-icon" />
            
                    </a>
                  </div>
                </div>

                <div class="appointment-tab">
                  <!-- Appointment Tab -->
                  <ul class="nav nav-tabs nav-tabs-solid nav-tabs-rounded">
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        href="#today-appointments"
                        data-bs-toggle="tab"
                        @click="selectTodayAppointments"
                      >Today <span class="badge custom-badge ms-1">{{ todayCount }}</span></a>
                    </li>
                    <li class="nav-item" v-for="(day, index) in nextFiveDays" :key="index">
                      <a
                        class="nav-link"
                        href="#specific-appointments"
                        @click="selectSpecificDay(day)"
                        data-bs-toggle="tab"
                      >{{ formatShortDate(day) }} <span class="badge custom-badge ms-1">{{ nextFiveDaysCount[index] }}</span></a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="#upcoming-appointments"
                        @click="selectUpcomingAppointments"
                        data-bs-toggle="tab"
                      >All Upcoming  <span class="badge custom-badge ms-1">{{ upcomingCount }}</span></a>
                    </li>
                  </ul>
                  <!-- /Appointment Tab -->

                  <div class="tab-content">
                    <!-- Upcoming Appointment Tab -->
                    <div class="tab-pane" id="upcoming-appointments">
                      <div class="card card-table mb-0">
                        <div class="card-body">
                          <div class="table-responsive">
                            <table class="table table-hover table-center mb-0">
                              <thead>
                                <tr></tr>
                                <tr>
                                  <th>Patient Name</th>
                                  <th>Appt Date</th>
                                  <th>Clinic Name</th>
                                  <th>Reason</th>
                                  <!-- <th>Type</th> -->
                                  <!-- <th class="text-center">Paid Amount</th> -->
                                  <th style="text-align: right;">
                                    <label class="custom_check" style="text-align: right;">
                                      <input
                                        type="checkbox"
                                        id="active-appointments"
                                        v-model="isActive"
                                        @change="toggleActiveStatus"
                                        checked
                                      />
                                      <span class="checkmark"></span>
                                      Include Cancelled
                                    </label>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="appointment in upcomingAppointmentsfilter"
                                  :key="appointment.id"
                                >
                                  <td>
                                    <h2 class="table-avatar">
                                      <router-link
                                        :to="'patient-profile/' + appointment.patientid"
                                        class="avatar avatar-sm me-2"
                                      >
                                        <img
                                          class="avatar-img rounded-circle"
                                          :src="getPatientImage(appointment)"
                                          alt="User Image"
                                        />
                                      </router-link>
                                      <router-link :to="'patient-profile/' + appointment.patientid">
                                        {{ appointment.firstname }} {{ appointment.lastname }}
                                        <span>{{ appointment.patientid }}</span>
                                      </router-link>
                                    </h2>
                                  </td>
                                  <td>
                                    {{ formatedDate(appointment.appointmentdate) }}
                                    <span
                                      class="d-block text-info"
                                    >
                                      {{
                                      formatTime(appointment.appointmenttime)
                                      }}
                                    </span>
                                  </td>
                                  <td>{{ appointment.clinicname }}</td>
                                  <td>{{ appointment.reason }}</td>
                                  <!-- <td>{{ item.Type }}</td> -->
                                  <!-- <td class="text-center">{{ item.PaidAmount }}</td> -->
                                  <td class="text-end">
                                    <div class="table-action">
                                    <!-- Add Prescription Button -->
<button 
  v-if="appointment.prescriptionStatus === 'exists'" 
  @click="viewPrescription(appointment.prescripId, appointment.patientid, appointment.appointmentid, appointment.firstname, appointment.lastname)">
  View Prescription
</button>

<button 
  v-else 
  @click="addPrescription(appointment.patientid, appointment.appointmentid, appointment.firstname, appointment.lastname)">
  Add Prescription
</button>

                                      <!-- <a
                                        href="javascript:void(0);"
                                        class="btn btn-sm bg-success-light me-2"
                                      >
                                        <i class="fas fa-check"></i> Accept
                                      </a>-->
                                      <a
                                        v-if="appointment.appointmentstatus === 'E'"
                                        href="javascript:void(0);"
                                        class="btn btn-sm bg-danger-light"
                                        @click="cancelAppointment(appointment)"
                                      >
                                        <i class="fas fa-times"></i> Cancel
                                      </a>
                                      <span v-else>
                                        <span class="badge bg-danger text-white ms-2">Cancelled</span>
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- /Upcoming Appointment Tab -->
                    <!-- Today Appointment Tab -->
                    <div class="tab-pane show active" id="today-appointments">
                      <div class="card card-table mb-0">
                        <div class="card-body">
                          <div class="table-responsive">
                            <table class="table table-hover table-center mb-0">
                              <thead>
                                <tr>
                                  <th>Patient Name</th>
                                  <th>Appt Date</th>
                                  <th>Clinic Name</th>
                                  <!-- <th>Reason</th> -->
                                  <!-- <th>Type</th> -->
                                  <!-- <th class="text-center">Paid Amount</th> -->
                                  <th style="text-align: right;">
                                    <label class="custom_check" style="text-align: right;">
                                      <input
                                        type="checkbox"
                                        id="active-appointments"
                                        v-model="isActive"
                                        @change="toggleActiveStatus"
                                        checked
                                      />
                                      <span class="checkmark"></span>
                                      Include Cancelled
                                    </label>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="appointment in currentAppointmentsfilter"
                                  :key="appointment.appointmentid"
                                >
                                  <td>
                                    <h2 class="table-avatar">
                                      <router-link
                                        :to="'patient-profile/' + appointment.patientid"
                                        class="avatar avatar-sm me-2"
                                      >
                                        <img
                                          class="avatar-img rounded-circle"
                                          :src="
                                            getPatientImage(appointment)
      "
                                          alt="User Image"
                                        />
                                      </router-link>
                                      <router-link :to="'patient-profile/' + appointment.patientid">
                                        {{ appointment.firstname }} {{ appointment.lastname }}
                                        <span>{{ appointment.patientid }}</span>
                                      </router-link>
                                    </h2>
                                  </td>
                                  <td>
                                    {{ formatedDate(appointment.appointmentdate)
                                    }}
                                    <span
                                      class="d-block text-info"
                                    >
                                      {{
                                      formatTime(appointment.appointmenttime)
                                      }}
                                    </span>
                                  </td>
                                  <td>{{ appointment.clinicname }}</td>
                                  <!-- <td>{{ appointment.reason }}</td> -->
                                  <!-- <td>{{ item.Type }}</td>
                                  <td class="text-center">{{ item.PaidAmount }}</td>-->
                                  <td class="text-end">
                                    <div class="table-action">
                               <!-- Add Prescription Button -->
<button 
  v-if="appointment.prescriptionStatus === 'exists'" 
  @click="viewPrescription(appointment.prescripId, appointment.patientid, appointment.appointmentid, appointment.firstname, appointment.lastname)">
  View Prescription
</button>

<button 
  v-else 
  @click="addPrescription(appointment.patientid, appointment.appointmentid, appointment.firstname, appointment.lastname)">
  Add Prescription
</button>


                                      <!-- <a
                                        href="javascript:void(0);"
                                        class="btn btn-sm bg-success-light me-2"
                                      >
                                        <i class="fas fa-check"></i> Accept
                                      </a>-->
                                      <a
                                        v-if="appointment.appointmentstatus === 'E'"
                                        href="javascript:void(0);"
                                        class="btn btn-sm bg-danger-light"
                                        @click="cancelAppointment(appointment)"
                                      >
                                        <i class="fas fa-times"></i> Cancel
                                      </a>
                                      <span v-else>
                                        <span class="badge bg-danger text-white ms-2">Cancelled</span>
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- /Today Appointment Tab -->
                    <!-- Specific Day Appointment Tab -->
                    <div class="tab-pane" id="specific-appointments">
                      <div class="card card-table mb-0">
                        <div class="card-body">
                          <div class="table-responsive">
                            <table class="table table-hover table-center mb-0">
                              <thead>
                                <tr>
                                  <th>Patient Name</th>
                                  <th>Appt Date</th>
                                  <th>Clinic Name</th>
                                  <th>Reason</th>
                                  <th style="text-align: right;">
                                    <label class="custom_check" style="text-align: right;">
                                      <input
                                        type="checkbox"
                                        id="active-appointments"
                                        v-model="isActive"
                                        @change="toggleActiveStatus"
                                        checked
                                      />
                                      <span class="checkmark"></span>
                                      Include Cancelled
                                    </label>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="appointment in filteredAppointments"
                                  :key="appointment.id"
                                >
                                  <td>
                                    <h2 class="table-avatar">
                                      <router-link
                                        :to="'patient-profile/' + appointment.patientid"
                                        class="avatar avatar-sm me-2"
                                      >
                                        <img
                                          class="avatar-img rounded-circle"
                                          :src="getPatientImage(appointment)"
                                          alt="User Image"
                                        />
                                      </router-link>
                                      <router-link :to="'patient-profile/' + appointment.patientid">
                                        {{ appointment.firstname }} {{ appointment.lastname }}
                                        <span>{{ appointment.patientid }}</span>
                                      </router-link>
                                    </h2>
                                  </td>
                                  <td>
                                    {{ formatedDate(appointment.appointmentdate) }}
                                    <span
                                      class="d-block text-info"
                                    >{{ formatTime(appointment.appointmenttime) }}</span>
                                  </td>
                                  <td>{{ appointment.clinicname }}</td>
                                  <td>{{ appointment.reason }}</td>
                                  <td class="text-end">
                                    <div class="table-action">
<!-- Add Prescription Button -->
<button 
v-if="appointment.prescriptionStatus === 'exists'" 
  @click="viewPrescription(appointment.prescripId, appointment.patientid, appointment.appointmentid, appointment.firstname, appointment.lastname)">
  View Prescription
</button>

<button 
  v-else 
  @click="addPrescription(appointment.patientid, appointment.appointmentid, appointment.firstname, appointment.lastname)">
  Add Prescription
</button>


                                      <a
                                        v-if="appointment.appointmentstatus === 'E'"
                                        href="javascript:void(0);"
                                        class="btn btn-sm bg-danger-light"
                                        @click="cancelAppointment(appointment)"
                                      >
                                        <i class="fas fa-times"></i> Cancel
                                      </a>
                                      <span v-else>
                                        <span class="badge bg-danger text-white ms-2">Cancelled</span>
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- /Specific Day Appointment Tab -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->
  </div>
  <!-- /Main Wrapper -->
  <!-- Appointment Details Modal -->
  <div class="modal fade custom-modal" id="appt_details">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Appointment Details</h5>
          <b-button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></b-button>
        </div>
        <div class="modal-body">
          <ul class="info-details">
            <li>
              <div class="details-header">
                <div class="row">
                  <div class="col-md-6">
                    <li>
                      <span class="title">Appointment ID:</span>
                      <span class="text">{{selectedAppointment.appointmentid}}</span>
                    </li>
                    <li>
                      <span class="title">Appointment Date & Time:</span>
                      <span
                        class="text"
                      >{{ formatDate(selectedAppointment.appointmentdate) }} {{ formatTime(selectedAppointment.appointmenttime) }}</span>
                    </li>
                  </div>
                  <div class="col-md-6">
                    <div class="text-end">
                      <b-button
                        type="button"
                        :class="{'btn bg-success-light btn-sm': appointmentStatus === 'Upcoming', 'btn bg-danger-light btn-sm': appointmentStatus !== 'Upcoming'}"
                        id="topup_status"
                      >{{ appointmentStatus }}</b-button>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <span class="title">Status:</span>
              <span class="text">{{ appointmentStatus }}</span>
            </li>
            <li>
              <span class="title">Confirm Date:</span>
              <span class="text">{{formatedDate(selectedAppointment.createddate)}}</span>
            </li>
            <li>
              <span class="title">Reason:</span>
              <span class="text">{{selectedAppointment.reason}}</span>
            </li>
            <!-- <li>
              <span class="title">Paid Amount</span>
              <span class="text">$450</span>
            </li>-->
          </ul>
        </div>
      </div>
    </div>
  </div>
  <doctorfooter />
  <!-- /Appointment Details Modal -->
  <!-- Add Prescription Modal -->
<div v-if="isModalOpen" class="modal-overlay" @click.self="closeModal">
    <div class="modal-content">
      <div class="modal-header">
        <h2>Prescription</h2>   <h4>   Patient Name:  {{ patientFirstName }} {{ patientLastName }} </h4>
        <button @click="closeModal"> ✕ </button>
      </div>
      <div class="modal-body">
        <div class="medicine-list-container">
        <div class="search-bar">
          <input type="text" placeholder="Search medicines..." v-model="search" />
          <i class="fa fa-search"></i>
        </div>
        
        <draggable  v-model="filteredMedicines" class="medicine-list"  :group="{ name: 'prescription', pull: true, put: false }" item-key="medicinename">
           <template #header>
      <h3> Medicine List</h3> 
    </template>
          <template #item="{ element }">
            <div class="medicine-item">
              <div class="medicine-details">
                <p><strong>{{ element.medicinename }}</strong> ({{ element.brandname }})</p>
                <p>{{ element.dosage }}</p>
              </div>
              <!--button @click="addToPrescription(element)">+</button-->
            </div>
          </template>
        </draggable>
        </div>
        <div class="prescription-list-container">
          <draggable v-model="prescription" class="prescription-list" :group="{ name: 'prescription', pull: false, put: true }" item-key="prescripmedid"  @add="onAddToPrescription">
    <template #header>
      <h3>Prescription List</h3>
    </template>
    <template #item="{ element }">
      <div class="medicine-item">
        <div class="medicine-details">
          <div class="edit-section">
            <div v-if="!element.isEditing">
              <p><strong>{{ element.medicinename }}</strong> ({{ element.brandname }})    <span @click="toggleEdit(element)">
  <i class="fas fa-pencil-alt"></i> Edit
</span>
</p>
              <p>{{element.frequency}}, {{element.duration}} days </p>  
              
            </div>
            <div v-if="element.isEditing">
              <input v-model="element.medicinename" placeholder="Medicine Name" />
              <input v-model="element.brandname" placeholder="Brand Name" />
              <input v-model="element.dosage"    placeholder="Type, Strength (mg)" />
              <input v-model="element.frequency" placeholder="Frequency" />
              <button @click="saveEdit(element)">
                Save
              </button>
          

          <!-- Frequency Buttons -->
          <!--div class="frequency-buttons">
            <button
              v-for="time in ['Morning', 'Afternoon', 'Night']"
              :key="time"
              :class="{'active': element.frequency && element.frequency.includes(time)}"
              @click="toggleFrequency(element, time)"
            >
              {{ time }}
            </button>
          </div-->

          <!-- Meal Toggle -->
          <!--div class="meal-toggle">
            <span @click="toggleMeal(element, 'before')" :class="{ active: element.meal === 'before' }">Before Food</span> | 
            <span @click="toggleMeal(element, 'after')" :class="{ active: element.meal === 'after' }">After Food</span>
          </div-->

  <!-- duration-->
  <div class="duration-field">
    
                  <p> No. of Days:  <input
                        type="number" 
                        v-model="element.duration"
                        class="duration-input"
                        placeholder="1"     
                      /></p>
                    </div>
            </div>
          </div>
        <!-- Note Section -->
  <div>
    <span @click="toggleNoteSection(element)">
      <i class="fas fa-pencil-alt"></i> <!-- Small pencil icon -->
      {{ element.showNote ? 'Close Note' : 'Open Note' }}
    </span>
    <textarea v-if="element.showNote" v-model="element.note"></textarea>
  </div>
        </div>

        <!-- Delete Button -->
        <button class="delete" @click="removeFromPrescription(element)"> ✕ </button>
      </div>
    </template>
  </draggable>

        </div>
        </div>  
        <div class="modal-footer">
          <button @click="createPrescription">Save</button>
          <button @click="closeModal">Close</button>
        </div>
      </div>
    </div>

</template>
<script>
import axios from "axios";
import doctorupcoming from "@/assets/json/doctor/doctorupcoming.json";
import doctortoday from "@/assets/json/doctor/doctortoday.json";
import "sweetalert2/dist/sweetalert2.css";
import Swal from "sweetalert2/dist/sweetalert2.js";
import draggable from 'vuedraggable';
import CircleProgress from "vue3-circle-progress";
import "vue3-circle-progress/dist/circle-progress.css";
import { ref } from "vue";
import jsPDF from "jspdf";
import "jspdf-autotable";
import ExcelJS from "exceljs";
export default {
  components: {
    CircleProgress,
    draggable
  },
  data() {
    return {
      isModalOpen: false,
      isEditingNote: false,
      newNote: '',
      medicines: [],
      medicine: [],
      patientFirstName: '',
      patientLastName: '',
      appointmentid: null,
      precrip_id: null,
      prescription: [],
      prescription_exists: false,
      search: '',
      title: "Dashboard",
      text: "Home",
      text1: "Dashboard",
      doctorDetails: [],
      DoctorId: null,
      statDetails: [],
      formattedDate: "",
      upcomingAppointments: [],
      upcomingAppointmentsfilter: [],
      currentAppointments: [],
      currentAppointmentsfilter: [],
      selectedAppointment: {},
      filteredAppointments: [],
      selectedDate: null,
      doctorupcoming: doctorupcoming,
      doctortoday: doctortoday,
      selectedTab: "today",
      isActive: false,
      todayCount: 5,
    };
  },
  computed: {
    filteredMedicines() {
      return this.medicines.filter(item => {
        return !this.search || 
               item.medicinename.toLowerCase().includes(this.search.toLowerCase());
      });
    },
    appointmentStatus() {
      if (this.selectedAppointment.appointmentstatus === "E") {
        return "Upcoming";
      } else {
        return "Cancelled";
      }
    },
    nextFiveDays() {
      const today = new Date();
      today.setDate(today.getDate() + 1);
      const nextFiveDays = [];
      for (let i = 0; i < 6; i++) {
        const nextDate = new Date(today);
        nextDate.setDate(today.getDate() + i);
        const formattedDate = nextDate.toISOString().slice(0, 10);
        nextFiveDays.push(formattedDate);
      }
      return nextFiveDays;
    },
    todayCount() {
      return this.currentAppointmentsfilter.length;
    },
    nextFiveDaysCount() {
      return this.nextFiveDays.map(date => {
        return this.upcomingAppointmentsfilter.filter(appointment => {
          return new Date(appointment.appointmentdate).toISOString().slice(0, 10) === date;
        }).length;
      });
    },
    upcomingCount() {
      return this.upcomingAppointmentsfilter.length;
    }
  },
  watch: {
    search(newValue) {
     
      this.filteredMedicines; 
    },
  },
  methods: {
    async checkPrescriptionExists(appointmentid, patientid) {
  try {
    const response = await axios.get(`/patients/${patientid}/appointments/${appointmentid}/prescriptions`);
    
    if (response.data && response.data.length > 0) {
      // Return the first prescription's ID if any prescriptions exist
      return { prescripId: response.data[0].prescripid };
    } else {
      return null; // No prescriptions found
    }
  } catch (error) {
    console.error("Error checking prescription:", error);
    return null; // Handle error by returning null
  }
},
    async fetchMedicines() {
      try {
        const doctorId = sessionStorage.getItem("userdbid");
        this.DoctorId = doctorId;
        const response = await axios.get(`/doctors/${this.DoctorId}/medicines`);
         this.medicine = response.data.map(med => {
          const [frequencyValue, durationValue] = (med.defaultdose || "").split(",");
           const numericDuration = durationValue ? parseInt(durationValue.match(/\d+/), 10) : 5;
      return {
        medicinename: med.medicinename,
        brandname: med.brandname,
        dosage: `${med.medicinetype}, ${med.medstrength}`,
        frequency: frequencyValue || null,  
         duration: numericDuration || 5,
      };
    });

  
      } catch (error) {
        console.error("Error fetching medicines:", error);
      }
    },
    addToPrescription(element) {
      if (!this.prescriptionList.includes(element)) {
    /* const newItem = {
        ...element,
        prescripmedid: 0,
        medicinename: element.medicinename,
        brandname: element.brandname,
        dosage: element.medstrength,
        frequency: element.defaultdose, 
        duration: element.defaultdose, 
        meal: "before",
        note: element.medstrength,
        isEditing: false,
        showNote: false,
      };*/
      
      this.prescription.push(newItem);
      this.filteredMedicines = this.filteredMedicines.filter(med => med.medicinename !== medicine.medicinename);
      }
    
  },
 onAddToPrescription(event) {
    const addedMedicine = event.clone;
    const isAlreadyInPrescription = this.prescription.some(
      med => med.medicinename === addedMedicine.medicinename && med.brandname === addedMedicine.brandname
    );
    event.preventDefault();
    if (isAlreadyInPrescription) {
      console.log("This medicine is already in the prescription.");
    }
    this.$nextTick(() => {
      event.item.remove();
    });
  },

  initializePrescriptionItem(element) {
    return {
      ...element,
      frequency: element.frequency || [],
      meal: element.meal || '',
      showNote: element.showNote || false,
      note: element.note || '',
      isEditing: element.isEditing || false,
    };
  },
  handleDrop(event) {
    const droppedElement = event.item;
    const newElement = this.initializePrescriptionItem(droppedElement);
    this.prescription.push(newElement);
  },
  async addPrescription(patientid, appointmentid, firstname, lastname) {
    try {
      const response = await axios.post(`/patients/${patientid} /appointments/${appointmentid}/prescriptions`, {
        appointmentid: appointmentid,
        prescrippath: 'default-path', 
        note: '', 
        status: 'A', 
        medications: [{
          prescripmedid: 0,
          prescripid: 0,
          medicinename: "string",
          dosage: "string",
          frequency: "string",
          duration: "string",
          note: "string"
        }]
      });

      const prescripId = response.data;
      this.prescription = [];
      this.medicines = this.medicine;
      this.openModal(patientid, appointmentid, firstname, lastname, prescripId);

    } catch (error) {
      console.error("Failed to create prescription:", error);
    }
  },
  async viewPrescription(prescripId, patientid, appointmentid, firstname, lastname) {
    try {
      const response = await axios.get(`/patients/1/prescriptions/${prescripId}/medications`);

       this.prescription = response.data;
      
       this.medicines = this.medicine.filter(medicine => {
      return !this.prescription.some(prescripItem => 
        prescripItem.medicinename === medicine.medicinename && 
        prescripItem.brandname === medicine.brandname
      );
    });

      this.openModal(patientid, appointmentid, firstname, lastname, prescripId);

    } catch (error) {
      console.error("Failed to fetch prescription medications:", error);
      
    }
  },
openModal(patientid, appointmentid, firstname, lastname, prescripId) {
    this.patientFirstName = firstname;
    this.patientLastName = lastname;
    this.prescripId = prescripId; 

   
    this.isModalOpen = true;
  },
   /*async openModal(patientid, appointmentid, firstname, lastname, prescripid) {
      try {
        console.log("apptid:",appointmentid);
        console.log("appt prescripi:",prescripid);
        const response = await axios.post(`/patients/${patientid}   /appointments/${appointmentid}/prescriptions`, {
          appointmentid: appointmentid,
          prescrippath: 'default-path', // Replace with actual data or leave empty if not needed
          note: '', // Default note or replace with actual data
          status: 'A', 
          medications: [{
      prescripmedid: 0,
      prescripid: 0,
      medicinename: "string",
      dosage: "string",
      frequency: "string",
      duration: "string",
      note: "string"
    }]// Default status or replace with actual data
        });

        // Store the returned prescrip_id
        this.prescripId = response.data;

        // Open the modal after successful API call
        this.openAddPrescriptionModal();

      } catch (error) {
        console.error("Failed to create prescription:", error);
        // Handle error (e.g., show a notification to the user)
      }
    this.patientFirstName = firstname;
    this.patientLastName = lastname;
    this.isModalOpen = true;
  },*/
    closeModal() {
      this.isModalOpen = false;
    },
  async removeFromPrescription(element) {
    const index = this.prescription.indexOf(element);


    if (element.prescripmedid && element.prescripmedid !== 0) {
      try {
        await this.deleteMedicationFromServer(element.prescripmedid);
        if (index !== -1) {
          this.prescription.splice(index, 1);
        }
        this.medicines.push(element);
      } catch (error) {
        console.error("Failed to delete medication:", error);
      }
    } else {
     
      if (index !== -1) {
        this.prescription.splice(index, 1);
        this.medicines.push(element);
      }
    }
  },
  async deleteMedicationFromServer(prescripmedid) {
    try {
     const response = await axios.delete(`/patients/1/medications/${prescripmedid}`);

     } catch (error) {
      console.error("Failed to delete medications:", error);
      
    }
  },
    onDragEnd(evt) {  
      const draggedItem = this.medicines[evt.oldIndex];
      if (evt.from === evt.to) {
      } else {
        this.addToPrescription(draggedItem);
        this.filteredMedicines = this.filteredMedicines.filter(med => med.medicinename !== movedItem.medicinename);
      }
    },
    async createPrescription() {
    try {
      const newMedications = this.prescription
      .filter(med => !med.prescripmedid) 
      .map(med => {
        //const [frequencyValue, durationValue] = (med.frequency || "").split(","); // Split frequency and duration
        return {
          prescripmedid: 0, 
          medicinename: med.medicinename,
          brandname: med.brandname,
          dosage: med.dosage,
          frequency: med.frequency, 
          duration: med.duration, 
          note: med.note || "a/f food"
        };
      });

    const editedMedications = this.prescription
      .filter(med => med.prescripmedid && med.isEdited) // Check for edited medicines
      .map(med => {
        const [frequencyValue, durationValue] = (med.frequency || "").split(","); // Split frequency and duration
        return {
          prescripmedid: med.prescripmedid,
          medicinename: med.medicinename,
          brandname: med.brandname,
          dosage: med.dosage,
          frequency: med.frequency, 
          duration: med.duration, 
          note: med.note || "a/f food"
        };
      });
    const medications = [...newMedications, ...editedMedications];
    console.log("med:", medications);
   const response = await axios.post(
        `/patients/1/prescriptions/${this.prescripId}/medications`,
        medications,
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    );
    console.log("Prescription created successfully:", response.data);
      
      this.closeModal();
    } catch (error) {
      console.error("Failed to add medications:", error);
    }
  },
    resetPrescription() {
      this.prescription = [];
    },
     toggleEdit(item) {
      item.isEditing = !item.isEditing;
    },
    toggleNoteSection(element) {
    element.showNote = !element.showNote;
  },
  toggleFrequency(element, time) {
     if (!element.frequency) {
        element.frequency = [];
      }
      const index = element.frequency.indexOf(time);
      if (index !== -1) {
        element.frequency.splice(index, 1);
      } else {
        element.frequency.push(time);
      }
  },
  toggleMeal(element, mealType) {
    element.meal = mealType; 
  },
  toggleNoteSection(element) {
    element.showNote = !element.showNote;
  },
  addNote() {
    this.isEditingNote = true;
  },
  saveNote() {
    if (this.newNote.trim()) {
      this.$set(this.element, 'note', this.newNote);
      this.isEditingNote = false;
    }
  },
  editNote() {
    this.newNote = this.element.note;
    this.isEditingNote = true;
  },
  saveEdit(med) {
    med.isEditing = false;
    med.isEdited = true; // Mark this medicine as edited
  },
  toggleEdit(med) {
    med.isEditing = true;
  },
    toggleActiveStatus() {
      this.isActive = !this.isActive;
      this.toggle();
    },
    toggle() {
      this.isActive = !this.isActive;
      if (this.selectedDate) {
        this.filterAppointmentsByDate(this.selectedDate);
      }
      this.filterCurrentAppointments();
      this.filterUpcomingAppointments();
    },

    formatShortDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric"
      });
    },
    filterAppointmentsByDate(date) {
      if (!this.isActive) {
        this.filteredAppointments = this.upcomingAppointments.filter(
          appointment => {
            return (
              new Date(appointment.appointmentdate).toLocaleDateString() ===
                date.toLocaleDateString() &&
              appointment.appointmentstatus === "E"
            );
          }
        );
      } else {
        this.filteredAppointments = this.upcomingAppointments.filter(
          appointment => {
            return (
              new Date(appointment.appointmentdate).toLocaleDateString() ===
              date.toLocaleDateString()
            );
          }
        );
      }
      console.log("Filtered appointments:", this.filteredAppointments);
    },

    filterUpcomingAppointments() {
      if (!this.isActive) {
        this.upcomingAppointmentsfilter = this.upcomingAppointments.filter(
          appointment => {
            return appointment.appointmentstatus === "E";
          }
        );
      } else {
        this.upcomingAppointmentsfilter = [...this.upcomingAppointments];
      }
      console.log("Filtered appointments:", this.filteredAppointments);
    },


    filterCurrentAppointments() {
      if (!this.isActive) {
        this.currentAppointmentsfilter = this.currentAppointments.filter(
          appointment => {
            return appointment.appointmentstatus === "E";
          }
        );
        console.log(
          "Active filtered appointments:",
          this.currentAppointmentsfilter
        );
      } else {
        this.currentAppointmentsfilter = [...this.currentAppointments];
      }
      console.log(
        "Active filtered appointments show:",
        this.currentAppointmentsfilter
      );
    },

    selectSpecificDay(date) {
      this.selectedDate = new Date(date);
      this.selectedTab = "specific";
      this.filterAppointmentsByDate(this.selectedDate);
    },

    selectUpcomingAppointments() {
      this.selectedTab = "upcoming";
    },
    selectTodayAppointments() {
      this.selectedTab = "today";
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric"
      });
    },
    formatedDate(date) {
      return new Date(date).toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric"
      });
    },
    formatTime(time) {
      return new Date(`2022-01-01T${time}`).toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true
      });
    },
    getPatientImage(appointment) {
      return (
        appointment.patientimage || require("@/assets/img/patients/patient.jpg")
      );
    },
    async fetchDoctorDetails() {
      const doctorId = sessionStorage.getItem("userdbid");
      this.DoctorId = doctorId;
      console.log("doctor id is ", doctorId);
      try {
        let docDetails = sessionStorage.getItem(`doctorDetails${doctorId}`);
        if (!docDetails) {
          const response = await axios.get(`/doctors/${doctorId}`);
          docDetails = response.data;
          sessionStorage.setItem(
            `doctorDetails${doctorId}`,
            JSON.stringify(docDetails)
          );
        } else {
          docDetails = JSON.parse(docDetails);
        }

        this.doctorDetails = docDetails;

        this.fetchDoctorStatCount(doctorId);
        this.formatDate();
      } catch (error) {
        console.error("Error fetching doctor details:", error);
      }
    },
    async fetchDoctorStatCount(doctorId) {
      try {
        const response = await axios.get(`/doctors/${doctorId}/doctorstats`);
        this.statDetails = response.data;
        console.log("Additional Details:", this.statDetails);
      } catch (error) {
        console.error("Error fetching doctors count:", error);
      }
    },
    async fetchUpcomingAppointments() {
      const doctorId = sessionStorage.getItem("userdbid");

  try {
    const response = await axios.get(`/doctors/${doctorId}/appointments?filter_type=upcoming`);
    this.upcomingAppointments = response.data.upcoming_appointments;
    this.currentAppointments = response.data.current_day_appointments;
    
    const checkUpcomingPromises = this.upcomingAppointments.map(async appointment => {
      const prescriptionExists = await this.checkPrescriptionExists(appointment.appointmentid, appointment.patientid);
      
      if (prescriptionExists) {
        appointment.prescriptionStatus = 'exists';
        appointment.prescripId = prescriptionExists.prescripId; // Store the prescription ID if it exists
      } else {
        appointment.prescriptionStatus = 'new';
      }
    });
    const checkCurrentPromises = this.currentAppointments.map(async appointment => {
      const prescriptionExists = await this.checkPrescriptionExists(appointment.appointmentid, appointment.patientid);
      
      if (prescriptionExists) {
        appointment.prescriptionStatus = 'exists';
        appointment.prescripId = prescriptionExists.prescripId; 
      } else {
        appointment.prescriptionStatus = 'new';
      }
    });
    await Promise.all([...checkUpcomingPromises, ...checkCurrentPromises]);
   
 if (this.selectedDate && this.selectedTab === "specific") {
            this.filterAppointmentsByDate(this.selectedDate);
          } else if (this.selectedTab === "upcoming") {
            this.filterUpcomingAppointments();
          } else if (this.selectedTab === "today") {
            this.filterCurrentAppointments();
          }
          // this.filterCurrentAppointments();
          this.filterCurrentAppointments();
          this.filterUpcomingAppointments();
  } catch (error) {
    console.error("Failed to fetch appointments:", error);
  }
    },
    viewAppointmentDetails(appointment) {
      this.selectedAppointment = appointment;
    },
    cancelAppointment(appointment) {
      const {
        appointmentid,
        appointmentby,
        appointmentdate,
        appointmenttime
      } = appointment;

      Swal.fire({
        title: "Are you sure?",
        text: `Do you want to cancel the appointment on ${this.formatDate(
          appointmentdate
        )} at ${this.formatTime(appointmenttime)}?`,
        input: "text",
        showCancelButton: false,
        inputLabel: "Reason for cancellation",
        inputPlaceholder: "Enter reason here...",
        icon: "warning",
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Confirm Cancel",
        customClass: {
          popup: 'swal-popup-custom', 
          closeButton: 'swal-close-button-custom',
        },
        showCloseButton: true,  
        closeButtonHtml: '&times;'
      }).then(result => {
        if (result.isConfirmed) {
          const cancelReason = result.value;

          const cancelApiUrl = `/patients/${appointmentby}/appointments/${appointmentid}`;
          axios
            .put(cancelApiUrl, { cancelreason: cancelReason })
            .then(response => {
              console.log("Appointment cancelled successfully", response.data);

              this.fetchUpcomingAppointments();
              this.fetchDoctorStatCount(this.DoctorId);
            })
            .catch(error => {
              console.error("Error cancelling appointment", error);
            });
        }
      });
    },
    fetchAppointments() {
      axios
        .get(`/doctors/${this.doctorId}/appointments?filter_type=upcoming`)
        .then(response => {
          this.currentAppointments = response.data.current_day_appointments;
        })
        .catch(error => {
          console.error("Error fetching appointments:", error);
        });
    },
    exportAppointments(format) {
      if (format === "pdf") {
        if (this.selectedTab === "upcoming") {
          if (!this.isActive) {
            const upcomingAppointmentspdf = this.upcomingAppointments.filter(
              appointment => {
                return appointment.appointmentstatus === "E";
              }
            );
            this.exportToPDF(this.upcomingAppointmentspdf);
          } else {
            this.exportToPDF(this.upcomingAppointments);
          }
        } else if (this.selectedTab === "today") {
          if (!this.isActive) {
            const currentAppointmentspdf = this.currentAppointments.filter(
              appointment => {
                return appointment.appointmentstatus === "E";
              }
            );
            this.exportToPDF(this.currentAppointmentspdf);
          } else {
            this.exportToPDF(this.currentAppointments);
          }
        } else if (this.selectedTab === "specific") {
          if (!this.isActive) {
            const appointmentsForSelectedDayfilter = this.filteredAppointments.filter(
              appointment => {
                return (
                  new Date(appointment.appointmentdate).toLocaleDateString() ===
                    this.selectedDate.toLocaleDateString() &&
                  appointment.appointmentstatus === "E"
                );
              }
            );
            this.exportToPDF(appointmentsForSelectedDayfilter);
          } else {
            const appointmentsForSelectedDay = this.filteredAppointments.filter(
              appointment => {
                return (
                  new Date(appointment.appointmentdate).toLocaleDateString() ===
                  this.selectedDate.toLocaleDateString()
                );
              }
            );
            this.exportToPDF(appointmentsForSelectedDay);
          }
        }
      } else if (format === "excel") {
        if (this.selectedTab === "upcoming") {
          if (!this.isActive) {
            this.upcomingAppointmentspdf = this.upcomingAppointments.filter(
              appointment => {
                return appointment.appointmentstatus === "E";
              }
            );
            this.exportToExcel(this.upcomingAppointmentspdf);
          }
          this.exportToExcel(this.upcomingAppointments);
        } else if (this.selectedTab === "today") {
          if (!this.isActive) {
            this.currentAppointmentspdf = this.currentAppointments.filter(
              appointment => {
                return appointment.appointmentstatus === "E";
              }
            );
            this.exportToExcel(this.upcomingAppointmentspdf);
          }
          this.exportToExcel(this.currentAppointments);
        } else if (this.selectedTab === "specific") {
          if (!this.isActive) {
            const appointmentsForSelectedDayfilter = this.filteredAppointments.filter(
              appointment => {
                return (
                  new Date(appointment.appointmentdate).toLocaleDateString() ===
                    this.selectedDate.toLocaleDateString() &&
                  appointment.appointmentstatus === "E"
                );
              }
            );
            this.exportToExcel(appointmentsForSelectedDayfilter);
          } else {
            const appointmentsForSelectedDay = this.filteredAppointments.filter(
              appointment => {
                return (
                  new Date(appointment.appointmentdate).toLocaleDateString() ===
                  this.selectedDate.toLocaleDateString()
                );
              }
            );
            this.exportToExcel(appointmentsForSelectedDay);
          }
        }
      }
    },
    exportToPDF(Appointments) {
      const doc = new jsPDF();
      const appointments = Appointments.map(appointment => [
        appointment.firstname + " " + appointment.lastname,
        appointment.appointmentdate,
        this.formatTime(appointment.appointmenttime),
        appointment.clinicname
      ]);

      let heading;
      if (this.selectedTab === "today") {
        heading = "Appointments for Today";
      } else if (this.selectedTab === "upcoming") {
        heading = "Upcomming Appointments";
      } else {
        heading = "Appointments for " + this.formatDate(this.selectedDate);
      }

      const gap = 10;

      const yPos = gap;

      const textWidth =
        (doc.getStringUnitWidth(heading) * doc.internal.getFontSize()) /
        doc.internal.scaleFactor;
      const xPos = (doc.internal.pageSize.width - textWidth) / 2;

      doc.text(heading, xPos, yPos);
      doc.autoTable({
        head: [["Patient Name", "Appt Date", "Appt Time", "Clinic Name"]],
        body: appointments,
        startY: yPos + gap
      });
      doc.save("appointments.pdf");
    },
    exportToExcel(Appointments) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Appointments");
      worksheet.columns = [
        { header: "Patient Name", key: "patientName", width: 30 },
        { header: "Appt Date", key: "appointmentDate", width: 20 },
        { header: "Appt Time", key: "appointmentTime", width: 20 },
        { header: "Clinic Name", key: "appointmentClinic", width: 40 }
      ];
      Appointments.forEach(appointment => {
        worksheet.addRow({
          patientName: appointment.firstname + " " + appointment.lastname,
          appointmentDate: appointment.appointmentdate,
          appointmentTime: this.formatTime(appointment.appointmenttime),
          appointmentClinic: appointment.clinicname
        });
      });
      workbook.xlsx.writeBuffer().then(buffer => {
        const blob = new Blob([buffer], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "appointments.xlsx";
        link.click();
      });
    }
  },
  beforeMount() {
    this.fetchDoctorDetails();
    this.fetchUpcomingAppointments();
    this.fetchAppointments();
    this.fetchMedicines();
    const percent = ref(75);
    setInterval(() => {
      if (percent.value === 25) {
        percent.value = 75;
      } else {
        percent.value = 25;
      }
    }, 1000);
  },
  name: "doctor-dashboard"
};
</script>
<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  width: 90%;
  max-width: 900px;
  padding: 15px;
  max-height: 95vh;
  min-height: 95vh;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.modal-body {
  display: flex;
  gap: 20px;
  flex-grow: 1;
}

.medicine-list, .prescription-list {
  flex: 1;
  max-height: 69vh;
  min-height: 69vh; /* Fixed height */
  overflow-y: auto;  /* Scrollable when items exceed height */
  padding: 5px;
  background-color: #f4f4f4;
  border-radius: 8px;
}

.medicine-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #fff;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  position: relative;
}
.medicine-item:hover {
  background-color: #e9e9e9;
}

.modal-footer {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
.nav-link {
  background-color: #d3d9df;
  border-color: #ced4da;
  margin-right: 1rem;
  transition: background-color 0.3s;
  margin: 5px 0;
}

.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a {
    margin-right: .15rem;
}

.nav-link:hover {
  background-color: #0056b3;
}

.modal-content .btn {
  cursor: auto;
}

.custom-badge {
  background-color: #708090;
  color: white;
}
button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 10px;
  margin-left: 5px;
}
.edit-icon {
  cursor: pointer;
  color: #007bff;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
}

.frequency-buttons button {
  border: 1px solid #007bff;
  background: white;
  color: #007bff;
  margin-right: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.frequency-buttons button.active {
  background-color: green;
  color: white;
}

.meal-toggle span {
  cursor: pointer;
  color: #007bff;
  margin-right: 10px;
}

.meal-toggle span.active {
  font-weight: bold;
  color: green;
}

textarea {
  width: 100%;
  padding: 5px;
  margin-top: 10px;
}

.delete {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  background-color: red;
  color: white;
  border: none;
  padding: 5px;
  font-size: 12px;
}
.search-bar {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.search-bar input {
  flex: 1;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.search-bar i {
  margin-left: 5px;
  color: #888;
}
.medicine-list-container {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.prescription-list-container {
  display: flex;
  color: #000;
  flex-direction: column;
  width: 50%;
}
.duration-input{
  width: 35px;
}
.download-text {
  font-size: 12px;
  display: inline; 
  margin: 0; 
  margin-right: 5px;
  /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); */
  font-weight: bold;
}

/* pdf and excel icon */
.export-icon {
  width: 30px;
  height: 30px;
}
 /* swirl alert close button css*/
 .swal-popup-custom {
    position: relative;
  }

  .swal-close-button-custom {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
    color: #d33;
  }
</style>