<template>
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <breadcrumb :title="title" :text="text" :text1="text1" />

    <div class="content">
      <div class="container">
        <div class="row">
          <div class="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
            <div class="stickysidebar">
              <profilesidebar ></profilesidebar>
            </div>
          </div>
          <div class="col-md-7 col-lg-8 col-xl-9">
            <div class="card">
              <div class="card-body">
                <!-- Profile Settings Form -->
                <form @submit.prevent="updatePatientDetails">
                  <div class="row">
                    <div class="col-12 col-md-12">
                      <div class="mb-3">
                        
                          <b-alert v-if="errorvalidation" v-model="showDismissibleAlert" variant="danger" dismissible>
                            {{ errorvalidation
                            }}
                          </b-alert>
                       
                          <b-alert v-if="successMessage" v-model="showDismissibleAlert" variant="success" dismissible>
                            {{ successMessage
                            }}
                          </b-alert>
                       

                        <div class="change-avatar">
                          <div class="profile-img">
                            <img :src="profileImageUrl || defaultImageUrl" alt="User Image" />
                          </div>
                          <div class="upload-img">
                            <div class="change-photo-btn">
                              <span>
                                <i class="fa fa-upload"></i> Upload Photo
                              </span>
                              <input type="file" ref="fileInput" class="upload" @change="uploadImage" accept="image/*" />
                            </div>
                            <small
                              class="form-text text-muted"
                            >Allowed JPG, JPEG or PNG. Max size of 1MB</small>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="mb-3">
                        <label class="mb-2">First Name</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="patientDetails.firstname"
                          maxlength="25"
                          @input="validateName"
                          :class="{ 'is-invalid': nameError }"
                        />
                        <div class="invalid-feedback" v-if="nameError">{{ nameError }}</div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="mb-3">
                        <label class="mb-2">Last Name</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="patientDetails.lastname"
                          maxlength="25"
                          @input="validateLastname"
                          :class="{ 'is-invalid': lnameError }"
                        />
                        <div class="invalid-feedback" v-if="lnameError">{{ lnameError }}</div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="mb-3">
                        <label class="mb-2">Date of Birth</label>
                        <div class="cal-icon">
                          <datepicker
                            v-model="patientDetails.dateof_birth"
                            placeholder="Date"
                            class="form-control datetimepicker"
                            :editable="true"
                            :clearable="false"
                            :input-format="dateFormat"
                            @change="validateDateOfBirth"
                            :class="{ 'is-invalid': dobError }"
                          />
                          <div class="invalid-feedback" v-if="dobError">{{ dobError }}</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="mb-3">
                        <label class="mb-2">Age</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="patientDetails.age"
                          maxlength="4"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="mb-3">
                        <label class="mb-2">Email ID</label>
                        <input
                          type="email"
                          class="form-control"
                          v-model="patientDetails.email"
                          maxlength="50"
                          @input="validateEmail"
                          :class="{ 'is-invalid': emailError }"
                        />
                        <div class="invalid-feedback" v-if="emailError">{{ emailError }}</div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="mb-3">
                        <label class="mb-2">Mobile</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="patientDetails.contactnumbers"
                          maxlength="50"
                          @input="validateContact"
                          :class="{ 'is-invalid': contactError }"
                        />
                        <div class="invalid-feedback" v-if="contactError">{{ contactError }}</div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="mb-3">
                        <label class="mb-2">Address</label>
                        <textarea
                          class="form-control"
                          v-model="patientDetails.address"
                          rows="2"
                          maxlength="250"
                        ></textarea>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="mb-3">
                        <label class="mb-2">Area</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="patientDetails.area"
                          maxlength="50"
                        />
                      </div>
                    </div>

                    <div class="col-12 col-md-6">
                      <div class="mb-3">
                        <label class="mb-2">Areacode</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="patientDetails.areacode"
                          maxlength="10"
                          @input="validateAreacode"
                          :class="{ 'is-invalid': areaError }"
                        />
                        <div class="invalid-feedback" v-if="areaError">{{ areaError }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="submit-section">
                    <b-button type="submit" class="btn btn-primary submit-btn">Save Changes</b-button>
                  </div>
                </form>
                <!-- /Profile Settings Form -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <indexfooter></indexfooter>
  </div>
</template>
<script>
import { ref, onMounted } from "vue";
import axios from "axios";
import Swal from "sweetalert2";
import defaultImageUrl from "@/assets/img/patients/patient.jpg";
import eventBus from "@/eventBus";

export default {
  data() {
    return {
      title: "Profile Settings",
      text: "Home",
      text1: "Profile Settings",
      patientDetails: {
        email: "",
        contactnumbers: "",
        address: "",
        firstname: "",
        lastname: "",
        dateof_birth: null,
        area: "",
        areacode: "",
        age: "",
      },
      showDismissibleAlert: false,
      errorvalidation: "",
      nameError: "",
      lnameError: "",
      emailError: "",
      contactError: "",
      areaError: "",
      dobError: "",
      dateFormat: "dd-MM-yyyy",
      idpid: "",
      patientid: "",
      defaultImageUrl: defaultImageUrl,
      file: "",
      profileImageUrl: "",
      successMessage:""
    };
  },
  mounted() {
    this.fetchPatientDetails();
  },
  name: "profile-settings",
  methods: {
    validateName() {
      const regex = /^[a-zA-Z\s]*$/;
      if (!regex.test(this.patientDetails.firstname)) {
        this.nameError = "Name should only contain alphabetical characters.";
        return false;
      } else {
        this.nameError = "";
        return true;
      }
    },
    validateLastname() {
      const regex = /^[a-zA-Z]*$/;
      if (!regex.test(this.patientDetails.lastname)) {
        this.lnameError =
          "Last name should only contain alphabetical characters.";
        return false;
      } else {
        this.lnameError = "";
        return true;
      }
    },
    validateEmail() {
      const regex = /^\S+@\S+\.\S+$/;
      if (!regex.test(this.patientDetails.email)) {
        this.emailError = "Please enter a valid email address.";
        return false;
      } else {
        this.emailError = "";
        return true;
      }
    },
    validateContact() {
      const regex = /^[0-9,\s]*$/;
      if (!regex.test(this.patientDetails.contactnumbers)) {
        this.contactError =
          "Contact numbers should only contain numbers, spaces, and commas.";
        return false;
      } else {
        this.contactError = "";
        return true;
      }
    },
    validateAreacode() {
      const regex = /^[0-9]*$/;
      if (!regex.test(this.patientDetails.areacode)) {
        this.areaError = "Area code should only contain numbers.";
        return false;
      } else {
        this.areaError = "";
        return true;
      }
    },
    validateDateOfBirth() {
      var dob = new Date(this.patientDetails.dateof_birth);
      var currentDate = new Date();
      if (dob > currentDate) {
        this.dobError = "Enter valid date of birth.";
        return false;
      } else {
        this.dobError = "";
        return true;
      }
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    async fetchPatientDetails() {
      this.patientid = sessionStorage.getItem("userdbid");
      try {
        const response = await axios.get(`patients/${this.patientid}`);
        this.patientDetails = response.data;

        if (this.patientDetails.dateof_birth) {
          this.patientDetails.dateof_birth = new Date(
            this.patientDetails.dateof_birth
          );
        }
        this.fetchPatientProfileImage();
      } catch (error) {
        console.error("Error fetching patient details:", error);
      }
    },
    async uploadImage(event) {
      const file = event.target.files[0];
      if (!file) {
        return;
      }

      const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
        if (!allowedTypes.includes(file.type)) {
          this.errorvalidation = "Only JPG, JPEG, or PNG images are allowed.";
          this.showDismissibleAlert = true;
          return;
        }
      const maxSize = 1 * 1024 * 1024; // 1MB
      if (file.size > maxSize) {
        this.errorvalidation = "File size exceeds 1MB. Please choose a smaller file";
        this.showDismissibleAlert = true;
        window.scrollTo({ top: 0, behavior: "smooth" });
        this.successMessage = "";
        return;

        
      }
       
      
      
      const formData = new FormData();
      formData.append("image_file", file);

      try {
        const response = await axios.post(
          `patients/${this.patientid}/profile-image`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }
        );

        this.fetchPatientDetails();
        eventBus.emit('imageUploaded');
        this.successMessage = "Profile image uploaded successfully";
        this.showDismissibleAlert = true;
        window.scrollTo({ top: 0, behavior: "smooth" });
        this.errorvalidation = "";
        
        } catch (error) {

        this.errorvalidation = "Failed to upload image. Please try again.";
        this.showDismissibleAlert = true;
        window.scrollTo({ top: 0, behavior: "smooth" });
        this.successMessage = "";
      } finally {
    // Ensure to clear the file input after upload attempt
    this.clearFileInput();
  }
},

clearFileInput() {
  // Clear the file input element
  this.$refs.fileInput.value = '';
},
      
      
    
    async fetchPatientProfileImage() {
      try {
        const response = await axios.get(
          `/patients/${this.patientid}/profile-image`,
          {
            responseType: "arraybuffer"
          }
        );

        const objectURL = window.URL.createObjectURL(new Blob([response.data]));
        this.profileImageUrl = objectURL;
      } catch (error) {
        console.error("Error fetching patient profile image:", error);
      }
    },
    async updatePatientDetails() {
      try {
        if (!this.patientDetails.firstname) {
          this.errorvalidation = "The patient name cannot be empty";
          this.showDismissibleAlert = true;
          window.scrollTo({ top: 0, behavior: "smooth" });
          return;
        }
        if (!this.validateName()) {
          this.errorvalidation = "Please enter a valid Name";
          this.showDismissibleAlert = true;
          window.scrollTo({ top: 0, behavior: "smooth" });
          return;
        }
        if (!this.patientDetails.lastname || !this.validateLastname()) {
          this.errorvalidation = "Please enter a valid last name";
          this.showDismissibleAlert = true;
          window.scrollTo({ top: 0, behavior: "smooth" });
          return;
        }
        if (
          !this.patientDetails.dateof_birth ||
          !this.validateDateOfBirth(this.newlySelectedDob)
        ) {
          this.errorvalidation = "Please enter a valid date of birth";
          this.showDismissibleAlert = true;
          window.scrollTo({ top: 0, behavior: "smooth" });
          return;
        }
        if (!this.patientDetails.age) {
          this.errorvalidation = "Please enter a valid age";
          this.showDismissibleAlert = true;
          window.scrollTo({ top: 0, behavior: "smooth" });
          return;
        }
        if (!this.patientDetails.email || !this.validateEmail()) {
          this.errorvalidation = "Please enter a valid email address";
          this.showDismissibleAlert = true;
          window.scrollTo({ top: 0, behavior: "smooth" });
          return;
        }
        if (!this.patientDetails.contactnumbers || !this.validateContact()) {
          this.errorvalidation = "Please enter valid contact numbers";
          this.showDismissibleAlert = true;
          window.scrollTo({ top: 0, behavior: "smooth" });
          return;
        }
        if (!this.patientDetails.address) {
          this.errorvalidation = "Address cannot be empty";
          this.showDismissibleAlert = true;
          window.scrollTo({ top: 0, behavior: "smooth" });
          return;
        }
        if (!this.patientDetails.area) {
          this.errorvalidation = "The area name cannot be empty";
          this.showDismissibleAlert = true;
          window.scrollTo({ top: 0, behavior: "smooth" });
          return;
        }
        if (!this.patientDetails.areacode || !this.validateAreacode()) {
          this.errorvalidation = "Please enter a valid areacode";
          this.showDismissibleAlert = true;
          window.scrollTo({ top: 0, behavior: "smooth" });
          return;
        }

        const updatedPatientDetails = { ...this.patientDetails };
        updatedPatientDetails.dateof_birth = this.formatDate(
          updatedPatientDetails.dateof_birth
        );

        const response = await axios.put(
          `patients/${this.patientid}`,
          updatedPatientDetails
        );
        console.log("Patient details updated successfully:", response.data);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Patient details updated successfully!"
        });

        this.errorvalidation = "";

        await this.fetchPatientDetails();
      } catch (error) {
        console.error("Error updating patient details:", error);
        this.showDismissibleAlert = true;
        this.errorvalidation =
          "Failed to update patient details. Please check details entered.";
      }
    }
  }
};
</script>

<style scoped>
.b-alert-success {
  background-color: #4caf50; 
  color: white; 

}
.v3dp__elements[data-v-65eb861b] button.selected span {
  background-color: #20c0f3 !important;
  color: aliceblue !important;
}

.v3dp__elements[data-v-65eb861b] button:not(:disabled):hover span {
  background-color: #20c0f3 !important;
  color: aliceblue !important;
}
</style>
