<template>
  <div>
    <div class="container">
      <div>
        <b-alert v-model="showError" dismissible variant="danger">
          {{ error }} | Patient not found?
          <strong>
            <a @click="registerPatient">Register a patient</a>
          </strong>
        </b-alert>
      </div>
      <div class="row align-items-center inner-banner">
        <!-- searchbar -->
        <div class="search-box-one aos">
          <form @submit.prevent="submitForm">
            <div class="search-input search-map-line">
              <i class="feather-phone bficon"></i>
              <div class="form-group mb-0">
                <input
                  v-model="patient.contactNumber"
                  type="text"
                  class="form-control"
                  placeholder="Search by Contact number"
                  :class="{ 'is-invalid': phoneTouched && !validatePhoneNumber() }"
                  @blur="phoneTouched = true"
                  maxlength="12"
                />
              </div>
            </div>
            <div class="search-input search-map-line">
              <i class="feather-mail"></i>
              <div class="form-group mb-0">
                <input
                  v-model="patient.email"
                  type="text"
                  class="form-control"
                  placeholder="Search patient by Email"
                  :class="{ 'is-invalid': emailTouched && !validateEmail() }"
                  @blur="emailTouched = true"
                  maxlength="45"
                />
              </div>
            </div>

            <div class="search-input search-map-line">
              <i class="feather-user"></i>
              <div class="form-group mb-0">
                <input
                  v-model="patient.name"
                  type="text"
                  class="form-control"
                  placeholder="Search patient by Name"
                  :class="{ 'is-invalid': firstnameTouched && !validatefirstname() }"
                  @blur="firstnameTouched = true"
                  maxlength="45"
                />
              </div>
            </div>
            <div class="form-search-btnx col-12 text-center">
              <b-button class="btn" type="submit">Search</b-button>
            </div>
          </form>
        </div>
      </div>
      <!-- registrationform -->
      <div v-if="showRegisterForm">
        <clinic-patient-registration></clinic-patient-registration>
      </div>
      <div>
        <!-- searchresults -->
        <div class="col-md-16 col-lg-17 col-xl-11 mx-auto" style="margin-top: 30px;">
          <div class="tab-pane show active" id="today-appointments">
            <div class="card card-table mb-0">
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table table-hover table-center mb-0">
                    <tbody v-if="searchResults.length > 0 && searchPerformed === true">
                      <tr v-for="patient in searchResults" :key="patient.id" @click="bookAppointment(patient.patientid)">
                        <td>
                          <h2 class="table-avatar">
                            <div
                              @click="bookAppointment(patient.patientid)"
                              class="avatar avatar-sm me-2"
                              style="cursor: pointer;"
                            >
                              <img
                                class="avatar-img rounded-circle"
                                :src="getPatientImage(patient)"
                                alt="User Image"
                              />
                            </div>
                            <div
                              @click="bookAppointment(patient.patientid)"
                              style="cursor: pointer;"
                            >
                              {{ patient.firstname }} {{ patient.lastname }}
                              <span>{{ patient.patientid }}</span>
                            </div>
                          </h2>
                        </td>
                        <td>{{ patient.contactnumbers }}</td>
                        <td>{{ patient.email }}</td>
                        <td class="text-end">
                          <div class="table-action">
                            <div class="clinic-booking book-appoint">
                              <div class="appointment-action">
                                <a href="javascript:void(0)" class="btn btn-sm bg-info-light"
                                  @click.stop="bookAppointment(patient.patientid)" data-bs-target="#appt_details"
                                  style="padding: 6px 5px; font-size: 9px; width: 130px;">
                                  <i class="fas fa-calendar-check"></i> Book Appointment
                                </a>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="text-center dont-have" style="margin-top: 20px; margin-bottom: 20px;">
    Patient not found?
    <strong>
      <a @click="registerPatient" style="color: #3b5998;">Register the patient</a>
    </strong>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      patient: {
        contactNumber: "",
        email: "",
        name: ""
      },
      doctorid: null,
      doctorDetails: [],
      patientid: null,
      searchResults: [],
      showError: false,
      error: null,
      firstnameTouched: false,
      lastnameTouched: false,
      emailTouched: false,
      phoneTouched: false,
      showRegisterForm: null,
      searchPerformed: null
    };
  },
  methods: {
    registerPatient() {
      this.showRegisterForm = true;
      this.searchPerformed = false;
    },
    async searchPatients() {
      try {
        const response = await axios.get("patients", {
          params: {
            contactnumbers: this.patient.contactNumber,
            patientname: this.patient.name,
            email: this.patient.email
          }
        });
        if (Array.isArray(response.data)) {
          this.searchResults = response.data;
        } else {
          this.showError = true;
          this.error = response.data;
        }
      } catch (error) {
        this.showError = true;
        this.error = error.message;
      }
    },

    async bookAppointment(patientid) {
      try {
        this.$store.dispatch("updateAppointmentDraft", {
          patientid: patientid,
          doctorid: this.doctorid,
          doctorImage: this.doctorDetails.doctorImage
        });
        this.$router.push({
          path: "/bookingclinic/" + this.doctorid,
          query: { from: "ClinicDashboard" }
        });
      } catch (error) {
        console.error("Error booking appointment:", error);
      }
    },
    validatefirstname() {
      const firstnameRegex = /^([A-Za-z\s]*)$/;
      return firstnameRegex.test(this.patient.name);
    },
    validateEmail() {
      const emailRegex = /^$|^([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.[a-zA-Z][a-zA-Z0-9-]{0,45}[a-zA-Z])$/;
      return emailRegex.test(this.patient.email);
    },
    validatePhoneNumber() {
      const phoneNumberRegex = /^(\s*|\d{10,12})$/;
      return phoneNumberRegex.test(this.patient.contactNumber);
    },
    submitForm() {
      this.showError = false;
      let invalids = [];
      if (
        !this.patient.contactNumber &&
        !this.patient.email &&
        !this.patient.name
      ) {
        this.error = "Please enter contact number or email, or name";
        this.showError = true;
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      }
      if (this.firstnameTouched && !this.validatefirstname()) {
        invalids.push("valid first name");
      }
      if (this.emailTouched && !this.validateEmail()) {
        invalids.push(" valid email");
      }
      if (this.phoneTouched && !this.validatePhoneNumber()) {
        invalids.push("valid Phone number");
      }
      if (invalids.length > 0) {
        this.error = "Please enter " + invalids.join(", ");
        this.showError = true;
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      }
      if (window.innerWidth > 767) {
        window.scrollTo({ top: 170, behavior: "smooth" });
      }
      this.searchPatients();
      this.searchPerformed = true;
      this.showRegisterForm = false;
    },
    getPatientImage(patient) {
      return (
        // patient.patientimage || require("@/assets/img/patients/patient.jpg")
        require("@/assets/img/patients/patient.jpg")
      );
    }
  },
  mounted() {
    const doctorinfo = this.$store.getters.getclinicDetails;
    this.doctorDetails = doctorinfo;
    this.doctorid = doctorinfo.doctorId;
  }
};
</script>
