<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <clinicheader></clinicheader>
    <breadcrumb-clinic :title="title" :text="text" :text1="text1" />
    <!-- Page Content -->
    <div class="content">
      <div class="container">
        <div class="row">
          <clinicsidebar></clinicsidebar>

          <div class="col-md-7 col-lg-8 col-xl-9">
            <div class="row">
              <div class="col-md-12">
                <!-- Doctor Grid Section -->
                <div class="row">
                  <div class="col-xl-4 col-lg-6 col-md-6" v-for="item in doctorsearchgrid" :key="item.id">
                    <router-link @click="storeDoctorData(item.clinicid, item.doctorid, doctorProfileImages[item.doctorid] )"
                      :to="'/clinic-doctor-dashboard/'" class="doctor-link">
                      <div class="doctor-profile-widget doc-grid">
                        <div class="doc-pro-img">
                          <!-- Adjust router link -->
                          <div class="booking-doc-img"
                            style="display: flex; justify-content: center; align-items: center;">
                            <a href="#" class="booking-doc-img">
                              <img :src="doctorProfileImages[item.doctorid] || defaultImageUrl" class="img-fluid"
                                alt="Doctor Image" @error="imageLoadError" style="max-width: 100px; max-height: 100px;" />
                            </a>
                          </div>
                        </div>
                        <div class="doc-content" style="display: flex; justify-content: center; align-items: center;">
                          <div class="doc-pro-info">
                            <div class="doc-pro-name">
                              <h4>{{ item.doctorname }}<i class="fas fa-circle-check"></i></h4>
                              <p>{{ item.specialization }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </router-link>
                  </div>
                </div>
                <!-- End of Doctor Grid Section -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->
  </div>
  <!-- /Main Wrapper -->
  <clinicfooter />
</template>

<script>
import axios from "axios";
import doctorsearchgrid from "@/assets/json/searchdoctor/doctor-search-grid.json";
import CircleProgress from "vue3-circle-progress";
import "vue3-circle-progress/dist/circle-progress.css";
import defaultImageUrl from '@/assets/img/doctors/doctor-thumb-01.jpg';
import { ref } from "vue";
export default {
  components: {
    CircleProgress
  },
  data() {
    return {
      title: "Dashboard",
      text: "Clinic Dashboard",
      doctorsearchgrid: [],
      doctorProfileImages: {},
      defaultImageUrl: defaultImageUrl,
    };
  },

  methods: {
    async fetchDoctorData() {
      try {
        const clinicuserid = sessionStorage.getItem('userdbid');
        const response = await axios.get(`/clinic/${clinicuserid}`);
        this.doctorsearchgrid = response.data.doctors;
        await this.fetchAllDoctorProfileImages();
      } catch (error) {
        console.error("Error fetching doctor data:", error);
      }
    },
    async fetchDoctorProfileImage(doctorId, profile_path) {
      try {
        if (!profile_path) {
          this.doctorProfileImages[doctorId] = this.defaultImageUrl;
        } else {
          const response = await axios.get(`doctors/doctorimg/${doctorId}/download`, {
            responseType: "arraybuffer"
          });
          const objectURL = window.URL.createObjectURL(new Blob([response.data]));
          this.doctorProfileImages[doctorId] = objectURL;
        }
      } catch (error) {
        console.error("Error fetching doctor image:", error);
        this.doctorProfileImages[doctorId] = this.defaultImageUrl;
      }
    },
    async fetchAllDoctorProfileImages() {
      const promises = this.doctorsearchgrid.map(doctor =>
        this.fetchDoctorProfileImage(doctor.doctorid, doctor.doctorimage)
      );
      await Promise.all(promises);
    },
    storeDoctorData(clinicId, doctorId, doctorImage) {
      this.$store.dispatch('storeClinicDetails', { clinicId, doctorId, doctorImage  });
    },
    imageLoadError(event) {
      event.target.src = this.defaultImageUrl;
    }
  },



  beforeMount() {
    this.fetchDoctorData();
    const percent = ref(75);
    setInterval(() => {
      if (percent.value === 25) {
        percent.value = 75;
      } else {
        percent.value = 25;
      }
    }, 1000);
  },
  name: "clinic-dashboard"
};
</script>

<style scoped>
.doctor-link:hover .doc-grid {
  transform: scale(1.05);
  background-color: #f5f5f5;
}

.doc-grid {
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.nav-link {
  background-color: #d3d9df;
  border-color: #ced4da;
  transition: background-color 0.3s;
  margin: 5px 0;
}

.nav-link:hover {
  background-color: #0056b3;
}

.modal-content .btn {
  cursor: auto;
}

.export-icon {
  width: 40px;
  /* Adjust the width as needed */
  height: 40px;
  /* Adjust the height as needed */
}
</style>
