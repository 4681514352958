<template>
  <!-- Add Dependent Modal-->
  <div
    id="modal_form"
    class="modal fade custom-modal"
    tabindex="-1"
    role="dialog"
    aria-modal="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <form action="javascript:;" enctype="multipart/form-data" autocomplete="off" method="post">
          <div class="modal-header">
            <h5 class="modal-title">Add Dependent</h5>

            <b-button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></b-button>
          </div>

          <div class="modal-body">
            <div class="ml-auto">
              <b-alert v-model="showDismissibleAlert" variant="danger" dismissible>{{ error }}</b-alert>
            </div>
            <div class="mb-3">
              <label class="control-label mb-10">
                First Name
                <span class="text-danger">*</span>
              </label>
              <input
                v-model="formData.firstname"
                type="text"
                name="firstname"
                class="form-control"
                :class="{ 'is-invalid': firstnameTouched && !validateaddfirstname() }"
                @blur="firstnameTouched = true"
                required
                maxlength="45"
              />
              <div
                v-if="firstnameTouched && !validateaddfirstname()"
                class="invalid-feedback"
              >Please enter a valid First Name.</div>
            </div>
            <div class="mb-3">
              <label class="control-label mb-10">Last Name</label>
              <span class="text-danger"></span>
              <input
                v-model="formData.lastname"
                type="text"
                name="lastname"
                class="form-control"
                :class="{ 'is-invalid':lastnameTouched && !validateaddlastname() }"
                @blur="lastnameTouched = true"
                required
                maxlength="45"
              />
              <div
                v-if="lastnameTouched && !validateaddlastname()"
                class="invalid-feedback"
              >Please enter a valid last Name.</div>
            </div>
            <!-- <div class="mb-3">
              <label class="control-label mb-10">Relationship </label>
              <input type="text" name="relationship" class="form-control" />
            </div>-->
            <div class="mb-3">
              <label class="control-label mb-10">
                Gender
                <span class="text-danger">*</span>
              </label>
              <b-form-select v-model="formData.gender" :options="Gender" placeholder="Select"></b-form-select>
            </div>
            <div class="mb-3">
              <label class="control-label mb-10" for="id_dateof_birth">DOB</label>
              <datepicker
                v-model="formData.dateof_birth"
                placeholder="Date"
                class="form-control datetimepicker"
                :editable="true"
                :clearable="false"
                :input-format="dateFormat"
                :class="{ 'is-invalid': DOBTouched && !validateaddDOB() }"
                @blur="DOBTouched = true"
                required
              />
              <div
                v-if="DOBTouched && !validateaddDOB()"
                class="text-danger"
              >Please enter a valid DOB.</div>
            </div>

            <!-- <div class="mb-3">
              <label class="control-label mb-10">Photo </label>
              <input type="file" name="profile_image" class="form-control" />
            </div>-->
            <!-- <div class="mb-3">
              <label class="control-label mb-10">Email <span class="text-danger">*</span></label>
              <input v-model="formData.email" type="email" name="email" class="form-control" />
            </div>
            <div class="mb-3">
              <label class="control-label mb-10">Contact Number <span class="text-danger">*</span></label>
              <input v-model="formData.contactnumbers" type="text" name="contactnumber" class="form-control" />
            </div>-->
          </div>
          <div class="modal-footer text-center">
            <b-button type="button" class="btn btn-primary submit-btn" @click="handleSubmit">Save</b-button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- /Add Dependent Modal-->

  <!-- Edit Dependent Modal-->
  <div id="edit_form" class="modal fade custom-modal" tabindex="-1" role="dialog" aria-modal="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <form action="javascript:;" enctype="multipart/form-data" autocomplete="off" method="post">
          <div class="modal-header">
            <h5 class="modal-title">Edit member</h5>
            <b-button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></b-button>
          </div>
          <div class="modal-body">
            <div class="ml-auto">
              <b-alert v-model="showDismissibleAlert" variant="danger" dismissible>{{ error }}</b-alert>
            </div>
            <div class="mb-3">
              <label class="control-label mb-10">
                FirstName
                <span class="text-danger">*</span>
              </label>
              <input
                class="form-control"
                v-model="selectedDependent.firstname"
                type="text"
                :class="{ 'is-invalid': firstnameTouched && !validatefirstname() }"
                @blur="firstnameTouched = true"
                required
                maxlength="45"
              />
              <div
                v-if="firstnameTouched && !validatefirstname()"
                class="invalid-feedback"
              >Please enter a valid First Name.</div>
            </div>
            <div class="mb-3">
              <label class="control-label mb-10">LastName</label>
              <span class="text-danger"></span>
              <input
                class="form-control"
                v-model="selectedDependent.lastname"
                type="text"
                :class="{ 'is-invalid':lastnameTouched && !validatelastname() }"
                @blur="lastnameTouched = true"
                required
                maxlength="45"
              />
              <div
                v-if="lastnameTouched && !validatelastname()"
                class="invalid-feedback"
              >Please enter a valid last Name.</div>
            </div>
            <!-- <div class="mb-3">
              <label class="control-label mb-10">Relationship </label>
              <input type="text" name="relationship" class="form-control" value="son" />
            </div>-->
            <div class="mb-3">
              <label class="control-label mb-10">
                Gender
                <span class="text-danger">*</span>
              </label>
              <b-form-select
                v-model="selectedDependent.gender"
                :options="Gender"
                placeholder="Select"
              ></b-form-select>
            </div>
            <div class="mb-3">
              <label class="control-label mb-10">DOB</label>
              <datepicker
                v-model="selectedDependent.dateof_birth"
                placeholder="Date"
                class="form-control datetimepicker"
                :editable="true"
                :clearable="true"
                :input-format="dateFormat"
                :class="{ 'is-invalid': DOBTouched && !validateDOB() }"
                @blur="DOBTouched = true"
                required
              />
              <div v-if="DOBTouched && !validateDOB()" class="text-danger">Please enter a valid DOB.</div>
            </div>
            <!-- <div class="mb-3">
              <label class="control-label mb-10">Photo </label>
              <input type="file" name="profile_image" class="form-control" />
            </div>-->
            <!-- <div class="mb-3">
              <label class="control-label mb-10">
                Email <span class="text-danger">*</span></label
              >
              <input class="form-control" v-model="selectedDependent.email" type="text" />
            </div>-->
            <!-- <div class="mb-3">
              <label class="control-label mb-10">
                ContactNumber <span class="text-danger">*</span></label
              >
              <input class="form-control" v-model="selectedDependent.contactnumbers" type="text" />
            </div>-->
          </div>
          <div class="modal-footer text-center">
            <b-button type="button" class="btn btn-primary submit-btn" @click="handleEdit">Save</b-button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- /Edit Dependent Modal-->
</template>

<script>
import axios from "axios";
import { ref } from "vue";
import Swal from "sweetalert2";
const currentDate = ref(new Date());
export default {
  props: {
    selectedDependent: Array,
    fetchDependents: Function,
    dependent: Array
  },
  data() {
    return {
      Gender: [
        { text: "Select", value: null },
        { text: "Male", value: "M" },
        { text: "Female", value: "F" }
      ],
      startdate: currentDate,
      dateFormat: "yyyy-MM-dd",
      formData: {
        firstname: "",
        lastname: "",
        dateof_birth: null,
        gender: null
      },
      error: "",
      showDismissibleAlert: false,
      firstnameTouched: false,
      lastnameTouched: false,
      DOBTouched: false,
    };
  },
  computed: {
    displayedGender() {
      return this.selectedDependent.gender === "M" ? "Male" : "Female";
    }
  },
  methods: {
    dependentExists: false,

    async fetchDependentss() {
      const patientId = sessionStorage.getItem("userdbid");
      try {
        console.log("Patient ID:", this.patientId);
        const response = await axios.get(`/patients/${patientId}/dependents`);
        this.dependent = response.data;
        console.error("This is fetchDependents.vue:", this.dependent);
      } catch (error) {
        console.error("Error fetching dependents:", error);
      }
    },
    async checkDependentExist() {
      const dependents = this.dependent;
      const { firstname, lastname, dateof_birth } = this.formData;
      const formDataDate = new Date(dateof_birth).toLocaleDateString("en-CA");
      const exists = dependents.some(dependent => {
        const dependentDate = new Date(
          dependent.dateof_birth
        ).toLocaleDateString("en-CA");
        return (
          dependent.firstname === firstname &&
          dependent.lastname === lastname &&
          dependentDate === formDataDate
        );
      });
      this.dependentExists = exists;
    },

    async handleSubmit() {
      await this.checkDependentExist();
      try {
        if (!this.formData.firstname) {
          this.error = "Please enter first name.";
          this.showDismissibleAlert = true;
          return;
        }
        if (!this.validateaddfirstname()) {
          this.error = "Please enter a valid firstname.";
          this.showDismissibleAlert = true;
          return;
        }
        if (!this.validateaddlastname()) {
          this.error = "Please enter a valid lastname.";
          this.showDismissibleAlert = true;
          return;
        }
        if (!this.validateaddDOB()) {
          this.error = "Please enter a valid DOB.";
          this.showDismissibleAlert = true;
          return;
        }

        if (this.dependentExists) {
          this.error = "Dependent already exists";
          this.showDismissibleAlert = true;
          return;
        }

        if (!this.formData.gender) {
          this.error = "Please select gender.";
          this.showDismissibleAlert = true;
          return;
        }
        const parentId = sessionStorage.getItem("userdbid");

        console.log("Patient ID:", parentId);
        const nonFormData = {
          email: "",
          contactnumbers: "",
          area: "",
          areacode: "",
          address: "",
          age: "",
          medicalhistory: "",
          geolocation: null,
          parentid: parentId,
          idp_id: null,
          listyn: true
        };
        const formattedDateOfBirth = this.formData.dateof_birth
          ? new Date(this.formData.dateof_birth).toLocaleDateString("en-CA")
          : null;

        const postData = {
          ...this.formData,
          dateof_birth: formattedDateOfBirth,
          ...nonFormData
        };

        const response = await axios.post("/patients", postData);

        if (response.status === 200) {
          await Swal.fire({
            icon: "success",
            title: "Added dependent successfully!"
          });

          this.formData.firstname = "";
          this.formData.lastname = "";
          this.formData.dateof_birth = null;
          this.formData.gender = null;

          const closeButton = document.querySelector("#modal_form .btn-close");
          if (closeButton) {
            closeButton.click();
            this.error = "";
            this.showDismissibleAlert = false;
            this.firstnameTouched = false;
            this.lastnameTouched = false;
            this.DOBTouched = false;
          }

          await this.fetchDependents();
        }
      } catch (error) {
        this.error = "Failed to add dependent. Please try again.";
        this.showDismissibleAlert = true;
        console.error("Error sending data:", error);
      }
    },
    validateaddfirstname() {
      const firstnameRegex = /^[a-zA-Z\s]{1,25}$/;
      return firstnameRegex.test(this.formData.firstname);
    },
    validateaddlastname() {
      const lastnameRegex = /^[a-zA-Z]{1,25}$/;
      const lastname = this.formData.lastname;
      return lastname === null || lastname === '' || lastnameRegex.test(lastname);
    },
    validateaddDOB() {
      var dateof_birth = new Date(this.formData.dateof_birth);
      var currentDate = new Date();
      if (dateof_birth >= currentDate) {
        console.log("The input date is in the future.");
        return false;
      } else {
        return true;
      }
    },

    async handleEdit() {
      try {
        if (!this.selectedDependent.firstname) {
          this.error = "Please enter first name.";
          this.showDismissibleAlert = true;
          return;
        }
        if (!this.validatefirstname()) {
          this.error = "Please enter a valid firstname.";
          this.showDismissibleAlert = true;
          return;
        }
        if (!this.validatelastname()) {
          this.error = "Please enter a valid lastname.";
          this.showDismissibleAlert = true;
          return;
        }
        if (!this.validateDOB()) {
          this.error = "Please enter a valid DOB.";
          this.showDismissibleAlert = true;
          return;
        }
        if (!this.selectedDependent.gender) {
          this.error = "Please select gender.";
          this.showDismissibleAlert = true;
          return;
        }
        let formattedDateOfBirth = null;

        if (this.selectedDependent.dateof_birth) {
          const dateOfBirth = new Date(this.selectedDependent.dateof_birth);
          const year = dateOfBirth.getFullYear();
          const month = (dateOfBirth.getMonth() + 1)
            .toString()
            .padStart(2, "0");
          const day = dateOfBirth
            .getDate()
            .toString()
            .padStart(2, "0");
          formattedDateOfBirth = `${year}-${month}-${day}`;
        }

        const updatedDependent = {
          ...this.selectedDependent,
          dateof_birth: formattedDateOfBirth
        };
        const response = await axios.put(
          `/patients/${updatedDependent.patientid}`,
          updatedDependent
        );

        if (response.status === 200) {
          await Swal.fire({
            icon: "success",
            title: "Updated dependent successfully!"
          });
          const closeButton = document.querySelector("#edit_form .btn-close");
          if (closeButton) {
            closeButton.click();
            this.error = "";
            this.showDismissibleAlert = false;
            this.firstnameTouched = false;
            this.lastnameTouched = false;
            this.DOBTouched = false;
          }
          await this.fetchDependents();
        } else {
          console.error("Unexpected status code:", response.status);
        }
      } catch (error) {
        this.error = "Failed to edit dependent. Please try again.";
        this.showDismissibleAlert = true;
        console.error("Error sending data:", error);
      }
    },
    validatefirstname() {
      const firstnameRegex = /^[a-zA-Z\s]{1,25}$/;
      return firstnameRegex.test(this.selectedDependent.firstname);
    },
    validatelastname() {
      const lastnameRegex = /^[a-zA-Z]{1,25}$/;
      const lastname = this.selectedDependent.lastname;
      return lastname === null || lastname === '' || lastnameRegex.test(lastname);
    },
    validateDOB() {
      var dateof_birth = new Date(this.selectedDependent.dateof_birth);
      var currentDate = new Date();
      if (dateof_birth >= currentDate) {
        console.log("The input date is in the future.");
        return false;
      } else {
        return true;
      }
    }
  }
};
</script>
