<template>
  <!-- Footer -->
  <footer class="footer footer-one">
    <div class="footer-top aos" data-aos="fade-up">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-4">
            <div class="footer-widget footer-about">
              <div class="footer-logo">
                <img src="@/assets/img/logo.png" alt="logo" />
              </div>
              <div class="footer-about-content">
                <p>{{ $t("footer_about") }}</p>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="row">
              <div class="col-lg-3 col-md-4">
                <div class="footer-widget footer-menu">
                  <h2 class="footer-title">{{ $t("footer_start_here") }}</h2>
                  <ul>
                    <li><router-link to="/search">{{ $t("footer_search_doctors") }}</router-link></li>
                    <li><router-link to="/login">{{ $t("footer_login") }}</router-link></li>
                    <li><router-link to="/register">{{ $t("footer_register") }}</router-link></li>
                    <li><router-link to="/doctor-info">{{ $t("footer_for_doctors") }}</router-link></li>
                    <li><router-link to="/faq">{{ $t("footer_faq") }}</router-link></li>
                  </ul>
                </div>
              </div>
              <div class="col-lg-4 col-md-4">
                <div class="footer-widget footer-menu">
                  <h2 class="footer-title">{{ $t("footer_specialist") }}</h2>
                  <ul>
                    <li><router-link to="/search?speciality=">{{ $t("footer_find_doctor") }}</router-link></li>
                    <li><router-link to="/search?speciality=cardiology">{{ $t("footer_find_cardiologist") }}</router-link></li>
                    <li><router-link to="/search?speciality=pediatry">{{ $t("footer_find_pediatrist") }}</router-link></li>
                    <li><router-link to="/search?speciality=dentist">{{ $t("footer_find_dentist") }}</router-link></li>
                    <li><router-link to="/search?speciality=physiotherapy">{{ $t("footer_find_physiotherapist") }}</router-link></li>
                    <li><router-link to="/search?speciality=chiropractor">{{ $t("footer_find_chiropractor") }}</router-link></li>
                    <li><router-link to="/search?speciality=psychology">{{ $t("footer_find_psychologist") }}</router-link></li>
                    <li><router-link to="/search?speciality=dietitian">{{ $t("footer_find_dietitian") }}</router-link></li>
                    <li><router-link to="/search?speciality=osteopath">{{ $t("footer_find_osteopath") }}</router-link></li>
                    <li><router-link to="/search?speciality=dermatology">{{ $t("footer_find_dermatologist") }}</router-link></li>
                    <li><router-link to="/search?speciality=radiology">{{ $t("footer_find_radiologist") }}</router-link></li>
                  </ul>
                </div>
              </div>
              <div class="col-lg-5 col-md-4">
                <div class="footer-widget footer-contact">
                  <h2 class="footer-title">{{ $t("footer_contact_us") }}</h2>
                  <div class="footer-contact-info">
                    <div class="footer-address">
                      <p><i class="feather-map-pin"></i> {{ $t("footer_address") }}</p>
                    </div>
                    <div class="footer-address">
                      <p><i class="feather-phone-call"></i> {{ $t("footer_phone") }}</p>
                    </div>
                    <div class="footer-address mb-0">
                      <p><i class="feather-mail"></i> {{ $t("footer_email") }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-7">
            <div class="footer-widget">
              <h2 class="footer-title">{{ $t("footer_newsletter") }}</h2>
              <div class="subscribe-form">
                <form action="javascript:;">
                  <input type="email" class="form-control" :placeholder="$t('footer_enter_email')" />
                  <b-button type="submit" class="btn">{{ $t("footer_submit") }}</b-button>
                </form>
              </div>
              <div class="social-icon">
                <ul>
                  <li>
                    <a href="javascript:;" target="_blank"><i class="fab fa-facebook"></i></a>
                  </li>
                  <li>
                    <a href="javascript:;" target="_blank"><i class="fab fa-instagram"></i></a>
                  </li>
                  <li>
                    <a href="javascript:;" target="_blank"><i class="fab fa-x-twitter"></i></a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/thegiglabs" target="_blank"><i class="fab fa-linkedin-in"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="container">
        <!-- Copyright -->
        <div class="copyright">
          <div class="row">
            <div class="col-md-6 col-lg-6">
              <div class="copyright-text">
                <p class="mb-0">
                  {{ $t("footer_copyright") }} © {{ new Date().getFullYear() }}
                  <a href="https://thegiglabs.com" target="_blank">{{ $t("footer_giglabs") }}</a>
                  {{ $t("footer_all_rights_reserved") }}
                </p>
              </div>
            </div>
            <div class="col-md-6 col-lg-6">
              <!-- Copyright Menu -->
              <div class="copyright-menu">
                <ul class="policy-menu">
                  <li><router-link to="/privacy-policy">{{ $t("footer_privacy_policy") }}</router-link></li>
                  <li><router-link to="/terms-condition">{{ $t("footer_terms_conditions") }}</router-link></li>
                </ul>
              </div>
              <!-- /Copyright Menu -->
            </div>
          </div>
        </div>
        <!-- /Copyright -->
      </div>
    </div>
  </footer>
  <!-- /Footer -->
</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";
export default {
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
};
</script>
