<template>
  <div class="tab-pane fade" id="pat_prescriptions">
    <div class="card card-table mb-0">
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-hover table-center mb-0">
            <thead>
              <tr>
                <th>Patient Name</th>
                <th>Date</th>
                <th>Clinic Name</th>
                <th>Created by</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in pastAppointments" :key="item.appointmnet_id">
                <td>
                  <h2 class="table-avatar">
                    <span class="avatar avatar-sm me-2">
                      <img src="@/assets/img/patients/patient.jpg" alt="User Image" />
                    </span>
                    {{item.patient_name}}
                  </h2>
                </td>
                <td>{{ formatDate(item.date_and_time) }}</td>
                <td>{{ item.clinic_name }}</td>
                <td>
                  <h2 class="table-avatar">
                    <router-link :to="'doctor/' + item.doctor_id" class="avatar avatar-sm me-2">
                      <img
                        class="avatar-img rounded-circle"
                        :src="//item.doctor_image ? require('@/assets/img/doctors/'+item.doctor_image) :
                         require('@/assets/img/doctors/doctor-thumb-01.jpg')"
                        alt="Doctor Image"
                      />
                    </router-link>
                    <router-link :to="'doctor/' + item.doctor_id">
                      {{ item.doctor_name }}
                      <span>{{ item.speciality }}</span>
                    </router-link>
                  </h2>
                </td>
                <td class="text-end">
                  <div class="table-action">
                    <!-- <a href="javascript:void(0);" class="btn btn-sm bg-primary-light ms-1">
                      <i class="fas fa-print"></i> Print
                    </a> -->
                     <a
                    href="javascript:void(0)"
                    class="btn btn-sm bg-info-light"
                    data-bs-toggle="modal"
                    data-bs-target="#appointment_medical_records_modal"
                    @click="addMedicalRecords(item.appointment_id, item.patientid,item.doctor_id)"
                  >
                    <i class="fas fa-file-medical"></i> Add Medical Record
                  </a>
                    <a href="javascript:void(0);" 
                       class="btn btn-sm bg-info-light ms-1" 
                       data-bs-toggle="modal"
                       data-bs-target="#view_medical_records_modal"
                       @click="viewMedicalRecords(item.patientid,item.appointment_id,item.doctor_id)">
                      <i class="far fa-eye"></i> View Records
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import prescription from "@/assets/json/patientdashboard/prescription.json";
export default {
  props: {
    pastAppointments: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      // prescription: prescription,
    };
  },
  methods: {
    formatDate(dateTimeString) {
      const options = { year: "numeric", month: "short", day: "numeric" };
      const formattedDate = new Date(dateTimeString).toLocaleDateString(
        "en-US",
        options
      );
      return formattedDate;
    },
    addMedicalRecords(appointmentId, patientId, doctorId ) {
    this.$emit('add-medical-records', { appointmentId, patientId, doctorId });
  },
  viewMedicalRecords(patientId, appointmentId, doctorId ) {
      this.$emit('view-medical-records', { patientId, appointmentId, doctorId });
    }
  }
};
</script>
