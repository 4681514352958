<template>
  <div class="main-wrapper">
    <clinicheader></clinicheader>
    <breadcrumb-clinic :title="title" :text="text" :text1="text1" />

    <div class="content content-space">

      <div class="container">
        <div class="back-link">
          <router-link to @click="$router.go(-1)">
            <i class="fa-solid fa-arrow-left-long"></i> Back
          </router-link>
        </div>

        <div class="row">
          <div class="col-lg-8 col-md-12">

            <div class="card booking-card">
              <div class="card-body booking-card-body">
                <div class="booking-doctor-details">
                  <div class="booking-doctor-left">
                    <div class="booking-doctor-img">
                      <router-link :to="{
                        name: 'doctor-profile',
                        params: { id: doctorDetails.doctorid },
                      }">
                        <img :src="doctorDetails.doctorimage
                          ? require('@/assets/img/doctors/' +
                            doctorDetails.doctorimage)
                          : require('@/assets/img/doctors/doctor-thumb-01.jpg')
                          " :alt="doctorDetails.doctorname" />
                      </router-link>
                    </div>
                    <div class="booking-doctor-info">
                      <h4>
                        <router-link :to="{
                          name: 'doctor-profile',
                          params: { id: doctorDetails.doctorid },
                        }">{{ doctorDetails.doctorname }}</router-link>
                      </h4>
                      <p>
                        {{ doctorDetails.qualifications }},
                        {{ doctorDetails.specialization }}
                      </p>
                    </div>
                  </div>
                  <div class="filter-collapse">
                    <ul>
                      <li v-for="clinicname in clinics" :key="clinicname">
                        <label class="custom_check d-inline-flex">
                          &nbsp;
                          <input type="checkbox" checked v-model="selectedClinics" :value="clinicname" disabled/>
                          <span class="checkmarkx" :style="{ backgroundColor: getColor(clinicname) }"></span>
                          {{ clinicname }}
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="card booking-card">
              <div class="card-body time-slot-card-body">
                <!-- startofchoosedate -->
                <div class="booking-date choose-date-book">
                  <div class="col-md-4">
                    &nbsp;
                    <b-button type="button" class="btn" @click="prevDay">
                      <i class="feather-arrow-left-circle"></i>Prev
                    </b-button>&nbsp;
                    <b-button type="button" class="btn-primary" @click="nextDay">
                      Next
                      <i class="feather-arrow-right-circle"></i>
                    </b-button>
                  </div>
                  <div class="booking-range" style="margin-left: auto;">
                    <div class="bookingrange btn" ref="bookingRange">

                      <img src="@/assets/img/icons/today-icon.svg" alt="calendar-mage" />
                      <span></span>
                      <i class="fas fa-chevron-down"></i>
                    </div>
                  </div>
                </div>
                <!-- endofchoosedate -->
                <div class="booking-date-slider">
                  <ul class="date-slider slick">
                    <Carousel :key="selectedIndex" :wrap-around="false" :settings="settings" :breakpoints="breakpoints"
                      v-model="selectedIndex">
                      <Slide v-for="item in dateRangeData" :key="item.Day">
                        <div class="call-view">
                          <div class="row">
                            <h4>{{ item.Day }}</h4>
                            <p>{{ item.Month }}</p>
                          </div>
                          <div class="row">
                            <div class="time-slot time-slot-blk">
                              <div class="time-slot-list">
                                <ul>
                                  <li v-for="[timeSlot, clinic] in doctorSlots[item.FullDate]" :key="timeSlot">
                                    <a v-if="
                                      timeSlot &&
                                      timeSlot.length == 5 &&
                                      selectedClinics.has(clinic)
                                    " :class="{
                                      timing: true,
                                      active:
                                        timeSlot + item.Day ===
                                        activeTimeSlot,
                                    }" href="javascript:void(0);" @click="
                                      selectTimeSlot(
                                        clinic,
                                        timeSlot,
                                        item.FullDate
                                      )
                                      " @mouseover="
                                        handleTimeSlotHover(timeSlot + item.Day)
                                        " @mouseleave="clearActiveTimeSlot" :style="{
                                          color: activeTimeSlot === timeSlot + item.Day ? 'white' : '#303f9f'
                                          , backgroundColor: getColor(clinic)
                                        }">
                                      <span>
                                        <i class="feather-clock"></i>
                                        {{ formatTime(timeSlot) }}
                                      </span>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Slide>
                      <template #addons>
                        <div class="owl-nav">
                          <Navigation />
                        </div>
                      </template>
                    </Carousel>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-12">
            <!-- <div class="booking-header">
              <h4 class="booking-title">Booking Summary</h4>
            </div> -->
            <!-- <div>
              <div class="booking-header">
                <h4 class="booking-title">Clinics</h4>
              </div>
              <div class="card booking-card">
                <div class="filter-collapse">
                  <ul>
                    <li v-for="clinicname in clinics" :key="clinicname">
                      <label class="custom_check d-inline-flex">
                        &nbsp;
                        <input type="checkbox" checked v-model="selectedClinics" :value="clinicname" />
                        <span class="checkmarkx" :style="{ backgroundColor: getColor(clinicname) }"></span>
                        {{ clinicname }}
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="card booking-card">
                <div>
                  <div class="clinic-timing"
                    style="margin-left: 20px; margin-right: 20px; margin-top: 20px; margin-bottom: 20px;">
                    <template
                      v-for="day in ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']">
                      <div v-if="hasTimings(fullDayName(day))" :key="day">
                        <p class="timings">
                          <span class="day">{{ day }}</span> :<br>
                          <span class="time">{{ getTimings(fullDayName(day)) }}</span>
                        </p>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <clinicfooter ></clinicfooter>
  </div>
</template>
<script>
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
// import Booking from "@/assets/json/booking/booking-2.json";
import "vue3-carousel/dist/carousel.css";
import { onMounted, defineComponent, ref } from "vue";
import { getCurrentInstance } from "vue";
import "daterangepicker/daterangepicker.css";
import "daterangepicker/daterangepicker.js";
import moment from "moment";
import axios from "axios";
import DateRangePicker from "daterangepicker";

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

const generateDateRangeData = (startDate, endDate) => {
  const dateRangeData = [];
  const currentDate = moment(startDate);
  const lastDate = moment(endDate);
  while (currentDate.isSameOrBefore(lastDate, "day")) {
    dateRangeData.push({
      Day: currentDate.format("dddd"),
      Month: currentDate.format("MMM D"),
      FullDate: currentDate.format("YYYY-MM-DD")
    });
    currentDate.add(1, "days");
  }
  return dateRangeData;
};

export default defineComponent({
  props: {
    doctor: {
      type: Object,
      default: {}
    },
    doctortimmings: {
      type: Array,
    }
  },
  data() {
    return {
      clinicColors: ['#e3fafd', '#ccffcc', '#ffff99', '#ffffcc', '#ffccff'],
      clinicColorMap: {},
      title: "Booking",
      text: "Home",
      text1: "Booking",
      showMorningTimeSlot: false,
      showAfternoonTimeSlot: false,
      showEveningTimeSlot: false,
      startDate: null,
      endDate: null,
      dateRangeData: [],
      timeslots: [],
      selectedIndex: 0,
      initialSlotsToShow: 5,
      doctorId: null,
      patientId: null,
      showAllSlots: false,
      selectedClinic: null,
      selectedTimeSlot: null,
      activeTimeSlot: null,
      doctorSlots: [],
      clinics: [],
      selectedClinics: [],
      doctoridx: 0,
      doctortimmings: [],
      doctorinfo: this.$store.getters.getclinicDetails,
      settings: {
        itemsToShow: 1,
        snapAlign: "center"
      },
      breakpoints: {
        575: {
          itemsToShow: 2,
          snapAlign: "center"
        },
        767: {
          itemsToShow: 3,
          snapAlign: "center"
        },
        991: {
          itemsToShow: 4,
          snapAlign: "center"
        },
        1024: {
          itemsToShow: 4,
          snapAlign: "start"
        }
      }
    };
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation
  },
  methods: {
    fullDayName(abbreviatedDay) {
      const daysMap = {
        Monday: "Monday",
        Tuesday: "Tuesday",
        Wednesday: "Wednesday",
        Thursday: "Thursday",
        Friday: "Friday",
        Saturday: "Saturday",
        Sunday: "Sunday"
      };
      return daysMap[abbreviatedDay];
    },
    hasTimings(day) {
      return this.doctortimmings.some(timing => timing.weekday === day);
    },
    getTimings(day) {
      const timings = this.doctortimmings.filter(
        timing => {
          return timing.weekday === day && this.selectedClinics.has(timing.clinicname);
        }
      );
      return timings
        .map(timing => {
          const startTime = this.convertToAMPM(timing.starttime);
          const endTime = this.convertToAMPM(timing.endtime);
          return `${timing.clinicname}: ${startTime} - ${endTime}`;
        })
        .join(", ");
    },
    convertToAMPM(timeString) {
      const [hours, minutes, seconds] = timeString.split(":").map(Number);
      const ampm = hours >= 12 ? "PM" : "AM";
      const hour12 = hours % 12 || 12;
      return `${hour12}:${minutes.toString().padStart(2, "0")} ${ampm}`;
    },
    getColor(clinic) {
      if (!this.clinicColorMap[clinic]) {
        this.clinicColorMap[clinic] = this.clinicColors[Object.keys(this.clinicColorMap).length % this.clinicColors.length];


        this.$router.push({
          path: "/patient-details/" + doctorId
        });
      }
    },
    async fetchDoctorData() {
      const doctorIdx = this.$route.params.id;
      this.doctoridx = doctorIdx;
      console.log(doctorIdx);
      try {
        let docDetails = sessionStorage.getItem(`doctorDetails${doctorIdx}`);
        if (!docDetails) {
          const response = await axios.get(`doctors/${doctorIdx}`);
          docDetails = response.data;

          sessionStorage.setItem(
            `doctorDetails${doctorIdx}`,
            JSON.stringify(docDetails)
          );
        } else {
          docDetails = JSON.parse(docDetails);
        }
        this.doctor = docDetails;
      } catch (error) {
        console.error("Error fetching doctor data:", error);
        if (error.response) {
          console.error("Response data:", error.response.data);
          console.error("Response status:", error.response.status);
          console.error("Response headers:", error.response.headers);
        } else if (error.request) {
          console.error("Request data:", error.request);
        } else {
          console.error("Error message:", error.message);
        }
      }
    },
    async fetchScheduleData() {
      try {
        const response = await axios.get(`/doctors/${this.doctoridx}/scheduletiming`);
        this.doctortimmings = response.data;
        console.log("Timmings are: ", this.doctortimmings);

        const clinicId = this.doctorinfo.clinicId;
        this.doctortimmings = this.doctortimmings.filter(timing => timing.clinicid === clinicId);
        console.log("Timmings after filter: ", this.doctortimmings);
      } catch (error) {
        console.error("Error fetching schedule data:", error);
      }
    },
    getColor(clinic) {
      if (!this.clinicColorMap[clinic]) {
        this.clinicColorMap[clinic] = this.clinicColors[Object.keys(this.clinicColorMap).length % this.clinicColors.length];
      }
      return this.clinicColorMap[clinic];
    },
    nextDay() {
      if (this.dateRangeData.length > this.selectedIndex + 1)
        this.selectedIndex++;
    },
    prevDay() {
      if (this.selectedIndex > 0) this.selectedIndex--;
    },
    formatTime(time) {
      if (!time) return "";
      return new Date(`2022-01-01T${time}`).toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true
      });
    },
    handleTimeSlotHover(timeSlot) {
      this.activeTimeSlot = timeSlot;
    },
    clearActiveTimeSlot() {
      this.activeTimeSlot = null;
    },
    selectTimeSlot(clinic, timeSlot, fullDate) {
      this.selectedDate = fullDate;
      this.selectedTimeSlot = timeSlot;

      const doctorId = this.$route.params.id;
      const patientId = this.$route.params.parentId;

      const selectedClinic = this.timeslots.find(
        slot => slot.clinic === clinic
      );
      const clinicId = this.doctorinfo.clinicId;
      // const clinicId = selectedClinic ? selectedClinic.clinicid : null;
      console.log("this is clinic id test", clinicId);

      if (this.selectedDate && this.selectedTimeSlot) {
        this.$store.dispatch("updateAppointmentDraft", {
          day: this.selectedDate,
          time: this.selectedTimeSlot,
          clinic: clinicId,
          clinicname: clinic
        });
        const from = this.$route.query.from;

        if (from === 'ClinicDashboard') {
          this.$router.push({ path: "/booking-confirm/" });
        }
        else {
          this.$router.push({
            path: "/patient-details/" + doctorId

          });
        }
      }
    }
  },
  beforeMount() {
    this.fetchDoctorData();
    this.fetchScheduleData();
  },
  setup() {
    const vm = getCurrentInstance().appContext.config.globalProperties;
    let dateRangePicker;
    let dateRangeData = ref([]);
    const timeslots = ref([]);
    const doctorDetails = ref({});
    const doctorSlots = ref(new Map());
    const clinics = ref(new Set());
    const selectedClinics = ref(new Set());
    const apiCalled = ref(false);
    //underconst
    const clinicId = ref(null);
    async function fetchClinicId() {
      try {
        const doctorinfo = vm.$store.getters.getclinicDetails;
        clinicId.value = doctorinfo.clinicId;
      } catch (error) {
        console.error("Error fetching clinicId:", error);
      }
    }
    onMounted(fetchClinicId);
    async function initializePage(startDate, endDate) {
      try {
        // const response = await axios.get(`/doctors/${vm.doctorId}/slots`, {
        await fetchClinicId();
        console.log("this fetch clinicid", clinicId.value);
        const response = await axios.get(`/clinic/${vm.doctorId}/slots/${clinicId.value}`, {
          params: {
            starttime: startDate,
            endtime: endDate
          }
        });
        const firstAvailableDate = response.data.timeslots[0].day;
        vm.startDate = moment(firstAvailableDate).format("YYYY-MM-DD");
        vm.endDate = moment(firstAvailableDate)
          .add(30, "days")
          .format("YYYY-MM-DD");
        if (!apiCalled.value) {
          let docDetails = sessionStorage.getItem(
            `doctorDetails${vm.doctorId}`
          );
          if (!docDetails) {
            const doctorDetailsResponse = await axios.get(
              `/doctors/${vm.doctorId}`
            );
            docDetails = doctorDetailsResponse.data;
            sessionStorage.setItem(
              `doctorDetails${vm.doctorId}`,
              JSON.stringify(docDetails)
            );
          } else {
            docDetails = JSON.parse(docDetails);
          }

          doctorDetails.value = docDetails;

          apiCalled.value = true;
        }

        timeslots.value = response.data.timeslots;

        console.log("timeslot this ", timeslots.value);
        dateRangeData.value = generateDateRangeData(vm.startDate, vm.endDate);
        dateRangeData.value.forEach(obj => {
          const validSlots = filteredSlotsByDate(obj.FullDate);
          doctorSlots.value[obj.FullDate] = validSlots ? validSlots : [];
        });
      } catch (error) {
        console.error("Error fetching doctor slots:", error);
      }
    }
    function filteredSlotsByDate(selectedFullDate) {
      const currentDate = moment();
      let validTimeSlots = [];
      timeslots.value.forEach(slot => {
        if (moment(slot.day).isSame(selectedFullDate, "day")) {
          clinics.value.add(slot.clinic);
          if (moment(slot.day).isSame(currentDate, "day")) {
            validTimeSlots.push(
              ...slot.times
                .filter(timeSlot => {
                  if (timeSlot && timeSlot != "") {
                    const slotTime = moment(timeSlot, "HH:mm");
                    if (slotTime.isValid() && slotTime.isAfter(moment())) {
                      return true;
                    } else {
                      return false;
                    }
                  } else {
                    return false;
                  }
                })
                .map(timeSlot => [timeSlot, slot.clinic])
            );
          }
          else {
            validTimeSlots.push(
              ...slot.times.map(timeSlot => [timeSlot, slot.clinic])
            );
          }
        }
      });
      validTimeSlots.sort(function (a, b) {
        if (a[0] < b[0]) return -1;
        else if (a[0] > b[0]) return 1;
        else return 0;
      });
      // console.log("Valid time slots:", validTimeSlots);
      return validTimeSlots;
    }
    onMounted(() => {
      const { params } = vm.$route;
      if (params && params.id) {
        vm.doctorId = params.id;
        // window.scrollTo(0,200);
        if (window.innerWidth > 767) {
          window.scrollTo({ top: 200, behavior: "smooth" });
        }
      }

      if (document.querySelectorAll(".bookingrange").length > 0) {
        var start = moment();
        var end = moment().add(30, "days");

        function booking_range(start, end) {
          document.querySelector(".bookingrange span").innerHTML =
            start.format("MMMM D, YYYY") + " - " + end.format("MMMM D, YYYY");
        }

        vm.startDate = start.format("YYYY-MM-DD");
        vm.endDate = end.format("YYYY-MM-DD");

        console.log("Fetching doctor slots...");
        initializePage(vm.startDate, vm.endDate);

        dateRangePicker = new DateRangePicker(
          document.querySelector(".bookingrange"),
          {
            startDate: start,
            endDate: end,
            ranges: {
              "From today": [moment(), moment().add(30, "days")]
            }
          },
          (startDate, endDate) => {
            vm.startDate = startDate.format("YYYY-MM-DD");
            vm.endDate = endDate.format("YYYY-MM-DD");
            booking_range(startDate, endDate);
            vm.dateRangeData = generateDateRangeData(startDate, endDate);
            console.log("Fetching doctor slots...");
            initializePage(vm.startDate, vm.endDate);
          }
        );
        vm.doctorSlots = doctorSlots.value;
        vm.clinics = clinics.value;
        selectedClinics.value = clinics.value;
        vm.selectedClinics = clinics.value;
        booking_range(start, end);

        document
          .querySelector(".bookingrange")
          .addEventListener("click", function () {
            const selectedRange = dateRangePicker.chosenLabel;
            console.log("Selected Range:", selectedRange);
          });
      }
    });

    return {
      dateRangeData,
      timeslots,
      doctorDetails,
      doctorSlots,
      clinics,
      selectedClinics
    };
  }
});
</script>
<style scoped>
.day {
  font-size: 10px;
}

.time {
  font-size: 10px;
  color: #808080;
}

.day.bold {
  font-weight: bold;
}
</style>
