<template>
  <div class="account-page">
    <div class="main-wrapper">
      <layoutheader></layoutheader>

      <!-- Page Content -->
      <div class="content top-space">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-8 offset-md-2">
              <!-- Account Content -->
              <div class="account-content">
                <div class="row align-items-center justify-content-center">
                  <div class="col-md-7 col-lg-6 login-left">
                    <img
                      src="@/assets/img/login-banner.png"
                      class="img-fluid"
                      alt="Login Banner"
                    />
                  </div>
                  <div class="col-md-12 col-lg-6 login-right" v-if="!confirmemail">
                    <div class="login-header">
                      <h3>Forgot Password?</h3>
                      <p class="small text-muted">
                        Enter your email to get a password reset link
                      </p>
                    </div>
                    <p v-if="error">
                      {{ error }}
                    </p>
                    <!-- Forgot Password Form -->
                    <form @submit.prevent="submitForm">
                      <div class="mb-3 form-focus">
                        <input type="email" class="form-control floating" v-model="email"/>
                        <label class="focus-label" :class="{ 'has-input': email }">Email</label>
                      </div>
                      <div class="text-end">
                        <router-link class="forgot-link" to="/login"
                          >Remember your password?</router-link
                        >
                      </div>
                      <b-button
                        class="btn btn-primary w-100 btn-lg login-btn"
                        type="submit"
                        >Reset Password</b-button
                      >
                    </form>
                    <!-- /Forgot Password Form -->
                  </div>
                  <div class="col-md-12 col-lg-6 login-right" v-else-if="!resetsuccess">
                    <div class="login-header">
                      <h3>Reset Password</h3>
                      <p class="small text-muted">
                        A verification code is sent by email to {{ maskedemail }}
                      </p>
                    </div>
                    <p v-if="error">
                      {{ error }}
                    </p>
                    <!-- Change Password Form -->
                    <form @submit.prevent="handleConfirmResetPassword">
                      <div class="mb-3 form-focus">
                        <input type="text" class="form-control floating" v-model="confrm_code" required />
                        <label class="focus-label" :class="{ 'has-input': confrm_code }">Verification Code</label>
                      </div>
                      <div class="mb-3 form-focus position-relative">
                        <input :type="showNewPassword ? 'text' : 'password'" class="form-control floating" v-model="newpwd" required />
                        <label class="focus-label" :class="{ 'has-input': newpwd }">New Password</label>
                        <button type="button" class="password-toggle" @click="toggleNewPasswordVisibility">
                          <i :class="showNewPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"></i>
                        </button>
                      </div>
                      <div class="mb-3 form-focus">
                        <input :type="showPassword ? 'text' : 'password'" class="form-control floating" v-model="confirmpwd" required/>
                        <label class="focus-label" :class="{ 'has-input': confirmpwd }">Confirm Password</label>
                        <button type="button" class="password-toggle" @click="togglePasswordVisibility">
                          <i :class="showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"></i>
                        </button>
                      </div>
                      <div class="text-end">
                        <router-link class="forgot-link" to="" @click="confirmemail=false"
                          >Change email?</router-link
                        >
                      </div>
                      <div class="submit-section">
                        <b-button type="submit" class="btn btn-primary submit-btn">
                          Save Changes
                        </b-button>
                      </div>
                    </form>
                    <!-- /Change Password Form -->
                  </div>
                  <div class="col-md-12 col-lg-6 login-right" v-else>
                    <div class="login-header">
                      <h3>Password reset successfully</h3>
                      <p class="small text-muted">
                        Please login with your email and the new password
                      </p>
                    </div>
                    <b-button
                      class="btn btn-primary w-100 btn-lg login-btn"
                      type="button" @click="gologin"
                    >Login
                    </b-button>
                  </div>
                </div>
              </div>
              <!-- /Account Content -->
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Content -->
    </div>
  </div>
  <indexfooter></indexfooter>
</template>
<script>
import { resetPassword, confirmResetPassword } from 'aws-amplify/auth';
export default {
  data() {
    return {
      error: null,
      email: '',
      confirmemail: false,
      maskedemail: '',
      confrm_code: '',
      newpwd: '',
      confirmpwd: '',
      successPass: false,
      successPass1: false,
      resetsuccess: false,
      showPassword: false,
      showNewPassword: false,
    };
  },
  methods: {
    min8: function(value) {
      if (value && value.length >= 8) {
        return true;
      } else {
        this.error = 'Password should have atleast 8 characters.';
        return false;
      }
    },
    matchingPasswords: function() {
      if (this.newpwd === this.confirmpwd) {
        return true;
      } else {
        this.error = 'Passwords do not match.';
        return false;
      }
    },
    async submitForm() {
      try {
        const output = await resetPassword({ username: this.email  });
        this.handleResetPasswordNextSteps(output);
      } catch (error) {
        console.log(error);
        this.error = error.message;
      }
    },
    handleResetPasswordNextSteps(output) {
      const { nextStep } = output;
      switch (nextStep.resetPasswordStep) {
        case 'CONFIRM_RESET_PASSWORD_WITH_CODE':
          const codeDeliveryDetails = nextStep.codeDeliveryDetails;
          
          this.maskedemail = codeDeliveryDetails.destination;
          this.confirmemail = true;

          // Collect the confirmation code from the user and pass to confirmResetPassword.
          break;
        case 'DONE':
          console.log('Successfully reset password.');
          this.error = 'Successfully reset password.';
          break;
      }
    },
    async handleConfirmResetPassword() {
      if (!this.min8(this.newpwd)) return;
      if (!this.matchingPasswords()) return;
      try {
        const res = await confirmResetPassword({ username: this.email, 
          confirmationCode: this.confrm_code,
          newPassword: this.newpwd });
        this.resetsuccess = true;
        //this.$router.push("/login");
      } catch (error) {
        console.log(error);
        this.error = error.message;
      }
    },
    gologin() {
      this.$router.push("/login")
    },
    toggleNewPasswordVisibility() {
      this.showNewPassword = !this.showNewPassword;
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    }
  },
  computed: {
    passRule: function() {
      if (this.newpwd === '') {
        // field is empty
        this.successPass = false;
        return '';
      } else if (this.min8(this.newpwd) === true) {
        // password ok
        this.successPass = true;
        return 'mdi-check';
      } else {
        // password wrong
        this.successPass = false;
        return 'mdi-close';
      }
    },
    passRule1: function() {
      if (this.confirmpwd === '') {
        // field is empty
        this.successPass1 = false;
        return '';
      } else if (this.min8(this.confirmpwd) === true && this.matchingPasswords() === true) {
        // password ok
        this.successPass1 = true;
        return 'mdi-check';
      } else {
        // password wrong
        this.successPass1 = false;
        return 'mdi-close';
      }
    },
  },
};
</script>

<style scoped>
.position-relative {
  position: relative;
}

.password-toggle {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
}

.password-toggle .fas {
  font-size: 1.2em;
  color: #6c757d;
}
</style>
