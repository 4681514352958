<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <breadcrumb-doctor :title="title" :text="text" :text1="text1" />
    <!-- Page Content -->
    <div class="content">
      <div class="container">
        <div class="row">
          <doctorsidebar :doctorDetails="doctorDetails"></doctorsidebar>

          <div class="col-md-7 col-lg-8 col-xl-9">


            <div class="row">
              <div class="col-md-12">
                <div class="appointment-tab">
                  <!-- Appointment Tab -->

                  <!-- <ul class="nav nav-tabs nav-tabs-solid nav-tabs-rounded">
                    <h3 style="margin-right:auto; margin-left:8px">REPORT</h3>

                  </ul> -->
                  <!-- /Appointment Tab -->
                  <ul class="nav nav-tabs nav-tabs-solid extra nav-tabs-rounded">

                    <!-- clarance -->
                    <li class="nav-item nav-item" style="margin-left: 10px;">
                      <a class="nav-link active" href="#choose-appointments" @click="selectChooseAppointments"
                        data-bs-toggle="tab">
                        <div class="inline-container">
                          <div>
                            <div id="daterange" @click="focusInput">
                              {{ startdatex }}
                            </div>
                          </div>
                          <div style="margin-left: 5px; margin-right: 5px;">to</div>
                          <div>
                            <div id="daterangeok" @click="focusInputok">
                              {{ enddatex }}
                            </div>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li class="nav-item nav-item" style="margin-left: 10px;">
                      <a class="nav-link" href="#choose-appointments" @click="selectChooseAppointments"
                        data-bs-toggle="tab">
                        <div class="inline-container">
                          <div>
                            <select v-model="selectedYear" class="custom-select" @change="timeframechange()">
                              <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
                            </select>
                          </div>
                          <i class="fas fa-chevron-down" style="margin-left:5px"></i>
                          <select v-model="selectedMonth" class="custom-select" @change="timeframechange()">
                            <option v-for="(month, index) in months" :key="index" :value="index + 1">
                              {{ month }}
                            </option>
                          </select>
                          <i class="fas fa-chevron-down"></i>
                          <button @click="generateReport()" class="generate-button">Generate</button>
                        </div>
                      </a>
                    </li>
                    <div class="export-buttons">
                      <p class="download-text">Include cancelled</p>
                      <li class="nav-item" style="margin-left:auto; margin-right:10px;">
                        <label class="custom_check" style="text-align: right;">
                          <input type="checkbox" id="active-appointments" v-model="isActive"
                            @change="toggleActiveStatus" checked />
                          <span class="checkmark"></span>
                        </label>
                      </li>
                      <p class="download-text">Download report</p>
                      <li class="nav-item nav-item" style="margin-right:10px;">
                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="PDF"
                          @click="exportAppointments('pdf')">
                          <img src="@/assets/img/pdf.svg" alt="Export to PDF" class="export-iconx" />
                        </a>
                      </li>
                      <li class="nav-item nav-item" style="margin-right:20px;">
                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="Excel"
                          @click="exportAppointments('excel')">
                          <img src="@/assets/img/excel.svg" alt="Export to Excel" class="export-iconx" />
                        </a>
                      </li>
                    </div>

                    <!-- <label for="start">Start date:</label>
                    <input type="date" id="start" name="start" v-model="dateRange.start">
                    <label for="end">End date:</label>
                    <input type="date" id="end" name="end" v-model="dateRange.end"> -->

                    <!-- <li class="nav-item nav-item" style="margin-left: 10px">
                      <a class="nav-link active" href="#weekly-appointments" data-bs-toggle="tab"
                        @click="selectWeeklyAppointments">Weekly</a>
                    </li>
                    <li class="nav-item nav-item" style="margin-left: 10px;">
                      <a class="nav-link" href="#upcoming-appointments" data-bs-toggle="tab"
                        @click="selectUpcomingAppointments">Monthly</a>
                    </li> -->
                    <!-- <li class="nav-item" v-for="(day, index) in nextFiveDays" :key="index">
                      <a class="nav-link" href="#specific-appointments" @click="selectSpecificDay(day)"
                        data-bs-toggle="tab">{{ formatShortDate(day) }}</a>
                    </li> -->
                    <!-- <li class="nav-item" style="margin-left: 10px">
                      <a class="nav-link" href="#upcoming-appointments" @click="selectUpcomingAppointments"
                        data-bs-toggle="tab">Upcoming</a>
                    </li> -->
                    <!-- <li class="nav-item" style="margin-left:auto">
                      <label class="custom_check" style="text-align: right;">
                        <input type="checkbox" id="active-appointments" v-model="isActive" @change="toggleActiveStatus"
                          checked />
                        <span class="checkmark"></span>
                        Include Cancelled
                      </label>
                    </li> -->
                  </ul>
                  <div class="tab-content">
                    <!-- monthly -->
                    <!-- Upcoming Appointment Tab -->
                    <div class="tab-pane" id="upcoming-appointments">
                      <div class="card card-table mb-0">
                        <div class="card-body">
                          <div class="table-responsive">
                            <table class="table table-hover table-center mb-0">
                              <tbody>
                                <tr v-for="appointment in upcomingAppointmentsfilter" :key="appointment.id">
                                  <!-- <td>
                                    {{ formatedDate(appointment.appointmentdate) }}
                                    <span class="d-block text-info">
                                      {{
                                        formatTime(appointment.appointmenttime)
                                      }}
                                    </span>
                                  </td> -->
                                  <td>
                                    <h2 class="table-avatar">
                                      <router-link :to="'patient-profile/' + appointment.patientid"
                                        class="avatar avatar-sm me-2">
                                        <img class="avatar-img rounded-circle" :src="getPatientImage(appointment)"
                                          alt="User Image" />
                                      </router-link>
                                      <router-link :to="'patient-profile/' + appointment.patientid">
                                        Doctor Name
                                        <span>{{ doctorDetails.doctorname }}</span>
                                      </router-link>
                                    </h2>
                                  </td>

                                  <td>
                                    <h2 class="table-avatar">
                                      <router-link :to="'patient-profile/' + appointment.patientid">
                                        Appt date
                                        <span>
                                          {{ formatedDate(appointment.appointmentdate) }}
                                          <span class="d-inline text-info">{{ formatTime(appointment.appointmenttime)
                                            }}</span>
                                        </span>
                                      </router-link>
                                    </h2>
                                  </td>

                                  <td class="text-end">
                                    <div class="table-action">
                                      <a href="javascript:void(0)" class="btn btn-sm bg-info-light"
                                        data-bs-toggle="modal" @click="viewAppointmentDetails(appointment)"
                                        data-bs-target="#appt_details" style="margin-right:10px">
                                        <i class="far fa-eye"></i> View
                                      </a>
                                      <a v-if="appointment.appointmentstatus === 'E'" href="javascript:void(0);"
                                        class="btn btn-sm bg-danger-light" @click="cancelAppointment(appointment)">
                                        <i class="fas fa-times"></i> Cancel
                                      </a>
                                      <span v-else>
                                        <span class="badge bg-danger text-white ms-2">Cancelled</span>
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- /Upcoming Appointment Tab -->
                    <!-- Today Appointment Tab -->
                    <div class="tab-pane show active" id="choose-appointments">
                      <div class="card card-table mb-0">
                        <div class="card-body">
                          <div class="table-responsive">
                            <table class="table table-hover table-center mb-0">
                              <!-- <thead>
                                <tr>
                                  <th>Doctor Name</th>
                                  <th style="text-align: left">Appt Date</th> -->
                              <!-- <th>Type</th> -->
                              <!-- <th class="text-center">Paid Amount</th> -->
                              <!-- <th style="text-align: right;">
                                    <label class="custom_check" style="text-align: right;">
                                      <input type="checkbox" id="active-appointments" v-model="isActive"
                                        @change="toggleActiveStatus" checked />
                                      <span class="checkmark"></span>
                                      Include Cancelled
                                    </label>
                                  </th>
                                </tr>
                              </thead> -->
                              <tbody>
                                <tr v-for="appointment in chooseAppointmentsfilter" :key="appointment.appointmentid">
                                  <td>
                                    <h2 class="table-avatar">
                                      <router-link :to="'patient-profile/' + appointment.patientid"
                                        class="avatar avatar-sm me-2">
                                        <img class="avatar-img rounded-circle" :src="getPatientImage(appointment)
                                          " alt="User Image" />
                                      </router-link>
                                      <router-link :to="'patient-profile/' + appointment.patientid">
                                        Doctor name
                                        <span>{{ doctorDetails.doctorname }}</span>
                                      </router-link>
                                    </h2>
                                  </td>
                                  <td>
                                    <h2 class="table-avatar">
                                      <router-link :to="'patient-profile/' + appointment.patientid">
                                        Appt date
                                        <span>
                                          {{ formatedDate(appointment.appointmentdate) }}
                                          <span class="d-inline text-info">{{ formatTime(appointment.appointmenttime)
                                            }}</span>
                                        </span>
                                      </router-link>
                                    </h2>
                                  </td>
                                  <!-- <td>{{ item.Type }}</td>
                                  <td class="text-center">{{ item.PaidAmount }}</td>-->
                                  <td class="text-end">
                                    <div class="table-action">
                                      <a href="javascript:void(0)" class="btn btn-sm bg-info-light"
                                        data-bs-toggle="modal" @click="viewAppointmentDetails(appointment)"
                                        data-bs-target="#appt_details" style="margin-right:10px">
                                        <i class="far fa-eye"></i> View
                                      </a>

                                      <!-- <a
                                        href="javascript:void(0);"
                                        class="btn btn-sm bg-success-light me-2"
                                      >
                                        <i class="fas fa-check"></i> Accept
                                      </a>-->
                                      <a v-if="appointment.appointmentstatus === 'E'" href="javascript:void(0);"
                                        class="btn btn-sm bg-danger-light" @click="cancelAppointment(appointment)">
                                        <i class="fas fa-times"></i> Cancel
                                      </a>
                                      <span v-else>
                                        <span class="badge bg-danger text-white ms-2">Cancelled</span>
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- weekly Day Appointment Tab -->
                    <!-- /Specific Day Appointment Tab -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->
  </div>
  <!-- /Main Wrapper -->
  <!-- Appointment Details Modal -->
  <div class="modal fade custom-modal" id="appt_details">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Appointment Details</h5>
          <b-button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></b-button>
        </div>
        <div class="modal-body">
          <ul class="info-details">
            <li>
              <div class="details-header">
                <div class="row">
                  <div class="col-md-6">
            <li>
              <span class="title">Appointment ID:</span>
              <span class="text">{{ selectedAppointment.appointmentid }}</span>
            </li>
            <li>
              <span class="title">Appointment Date & Time:</span>
              <span class="text">{{ formatDate(selectedAppointment.appointmentdate) }} {{
                formatTime(selectedAppointment.appointmenttime) }}</span>
            </li>
        </div>
        <div class="col-md-6">
          <div class="text-end">
            <b-button type="button"
              :class="{ 'btn bg-success-light btn-sm': appointmentStatus === 'Upcoming', 'btn bg-danger-light btn-sm': appointmentStatus !== 'Upcoming' }"
              id="topup_status">{{ appointmentStatus }}</b-button>
          </div>
        </div>
      </div>
    </div>
    </li>
    <li>
      <span class="title">Status:</span>
      <span class="text">{{ appointmentStatus }}</span>
    </li>
    <li>
      <span class="title">Confirm Date:</span>
      <span class="text">{{ formatedDate(selectedAppointment.createddate) }}</span>
    </li>
    <!-- <li>
              <span class="title">Paid Amount</span>
              <span class="text">$450</span>
            </li>-->
    </ul>
  </div>
  </div>
  </div>
  </div>
  <doctorfooter />
  <!-- /Appointment Details Modal -->
</template>
<script>
import axios from "axios";
import { format, addMonths, getYear, getMonth } from "date-fns";
import doctorupcoming from "@/assets/json/doctor/doctorupcoming.json";
import doctortoday from "@/assets/json/doctor/doctortoday.json";
import "sweetalert2/dist/sweetalert2.css";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "daterangepicker/daterangepicker.css";
import "daterangepicker/daterangepicker.js";
import DateRangePicker from "daterangepicker";
import CircleProgress from "vue3-circle-progress";
import "vue3-circle-progress/dist/circle-progress.css";
import { ref } from "vue";
import jsPDF from "jspdf";
import "jspdf-autotable";
import ExcelJS from "exceljs";
import jQuery from "jquery";
const $ = jQuery;

export default {
  components: {
    CircleProgress,
    DateRangePicker,
  },
  // clarance
  data() {
    const currentDate = new Date();
    // const startdate = currentDate.toISOString().split('T')[0]; 
    // const enddate = new Date(currentDate.setDate(currentDate.getDate() + 30)).toISOString().split('T')[0];
    // console.log("this is startdatex",startdatex);
    // const enddatex = new Date(currentDate.setDate(currentDate.getDate() + 7)).toISOString().split('T')[0];
    // const startdatex = new Date(this.datetime);
    // const enddatex = new Date(currentDate.setDate(currentDate.getDate() + 7)).toISOString().split('T')[0];
    const startdatex = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), 1)).toISOString().split('T')[0];
    const enddatex = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)).toISOString().split('T')[0];
    return {
      dateRange: {
        start: '',
        end: ''
      },
      timeframechanged: false,
      selectedMonth: new Date().getMonth() + 1,
      selectedYear: new Date().getFullYear().toString(),
      months: [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ],
      years: [new Date().getFullYear() - 1, new Date().getFullYear(), new Date().getFullYear() + 1],
      title: "Dashboard",
      text: "Home",
      text1: "Dashboard",
      doctorDetails: [],
      DoctorId: null,
      statDetails: [],
      formattedDate: "",
      upcomingAppointments: [],
      upcomingAppointmentsfilter: [],
      weeklyAppointmentsfilter: [],
      currentAppointments: [],
      chooseAppointments: [],
      chooseAppointmentsfilter: [],
      currentAppointmentsfilter: [],
      selectedAppointment: {},
      filteredAppointments: [],
      selectedDate: null,
      doctorupcoming: doctorupcoming,
      doctortoday: doctortoday,
      selectedTab: "choose",
      isActive: true,
      dummyStartTime: '2023-05-01', // Set your dummy start time
      dummyEndTime: '2023-05-15',
      startDate: null,
      endDate: null,
      startdatex: startdatex,
      enddatex: enddatex,
      startdate: 'null',
      enddate: 'null',
      datetime: null,
      currentDate: currentDate,

    };
  },
  computed: {
    appointmentStatus() {
      if (this.selectedAppointment.appointmentstatus === "E") {
        return "Upcoming";
      } else {
        return "Cancelled";
      }
    },
  },
  methods: {
    async generateReport() {
      try {
        console.log("this.selectedYear:", this.selectedYear);
        console.log("this.selectedMonth:", this.selectedMonth);
        this.loading = true;
        this.startdatex = format(
          new Date(this.selectedYear, this.selectedMonth - 1, 1),
          "yyyy-MM-dd"
        );
        this.enddatex = format(
          new Date(this.selectedYear, this.selectedMonth, 0),
          "yyyy-MM-dd"
        );
        console.log("generate report start date:", this.startdatex);
        console.log("generate report end date:", this.enddatex);
        await this.fetchAllAppointments(this.startdatex, this.enddatex);
      }
      catch (error) {
        console.error(error);
      }
    },
    handleDateUpdate({ from, to }) {
      // Handle the updated date range here
      console.log('Selected date range:', from, to);
    },
    timeframechange() {
      this.timeframechanged = true;
    },
    // getStartDate() {
    //   // Define how you get the start date here
    //   const startUltra=new Date().toISOString().split('T')[0];
    //   return startUltra;
    // },
    // getEndDate() {
    //   // Define how you get the end date here
    //   return new Date().toISOString().split('T')[0];
    // },

    initDateRangePicker() {
      const vm = this;
      $(function () {
        $("#daterange").daterangepicker({
          singleDatePicker: true,
          showDropdowns: true,
          autoUpdateInput: false,
          locale: {
            format: "YYYY-MM-DD",
          },
          startDate: vm.startdatex,
        }, function (start) {
          vm.startdatex = start.format("YYYY-MM-DD");
          vm.fetchAllAppointments();
        });
      });
    },
    initDateRangePickerok() {
      const vm = this;
      $(function () {
        $("#daterangeok").daterangepicker({
          singleDatePicker: true,
          showDropdowns: true,
          autoUpdateInput: false,
          locale: {
            format: "YYYY-MM-DD",
          },
          endDate: vm.enddatex,

        }, function (end) {
          vm.enddatex = end.format("YYYY-MM-DD");
          vm.fetchAllAppointments();
        });
      });
    },
    async fetchDatetime() {
      try {
        const response = await axios.get('/doctors/datetime');
        this.datetime = response.data[1];
        this.currentDate = new Date(this.datetime);
        console.log();
      } catch (error) {
        console.error('Error fetching datetime:', error);
      }
    },

    selectChooseAppointments() {
      if (this.dateRange.start && this.dateRange.end) {
        this.startDate = this.dateRange.start;
        this.endDate = this.dateRange.end;
      }
    },
    formatDate(date) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return date.toLocaleDateString(undefined, options);
    },
    toggleActiveStatus() {
      this.isActive = !this.isActive;
      this.toggle();
    },
    toggle() {
      this.isActive = !this.isActive;
      if (this.selectedDate) {
        this.filterAppointmentsByDate(this.selectedDate);
      }
      this.filterChooseAppointments();
      this.filterCurrentAppointments();
      this.filterUpcomingAppointments();
    },

    formatShortDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric"
      });
    },
    filterAppointmentsByDate() {
      if (!this.isActive) {
        //   this.upcomingAppointmentsfilter = this.allAppointments.filter(
        //     appointment => {
        //       return appointment.appointmentstatus === "E";
        //     }
        //   );
        this.filteredAppointments = [...this.upcomingAppointments];
        console.log("this is upcomingmonthlyfilerone", this.upcomingAppointmentsfilter);
      } else {
        this.filteredAppointments = [...this.allAppointments];
        console.log("this is upcomingmonthlyfilertwo", this.upcomingAppointmentsfilter);
      }
      console.log("Filtered appointments:", this.filteredAppointments);
    },

    filterWeeklyAppointments() {
      if (!this.isActive) {
        //   this.upcomingAppointmentsfilter = this.allAppointments.filter(
        //     appointment => {
        //       return appointment.appointmentstatus === "E";
        //     }
        //   );
        this.weeklyAppointmentsfilter = [...this.allAppointments];
        console.log("this is upcomingmonthlyfilerone", this.upcomingAppointmentsfilter);
      } else {
        this.weeklyAppointmentsfilter = [...this.allAppointments];
        console.log("this is upcomingmonthlyfilertwo", this.upcomingAppointmentsfilter);
      }
      console.log("Filtered appointments:", this.filteredAppointments);
    },

    filterUpcomingAppointments() {
      if (!this.isActive) {
        //   this.upcomingAppointmentsfilter = this.allAppointments.filter(
        //     appointment => {
        //       return appointment.appointmentstatus === "E";
        //     }
        //   );
        this.upcomingAppointmentsfilter = [...this.allAppointments];
        console.log("this is upcomingmonthlyfilerone", this.upcomingAppointmentsfilter);
      } else {
        this.upcomingAppointmentsfilter = [...this.allAppointments];
        console.log("this is upcomingmonthlyfilertwo", this.upcomingAppointmentsfilter);
      }
    },

    filterCurrentAppointments() {
      if (!this.isActive) {
        this.currentAppointmentsfilter = this.currentAppointments.filter(
          appointment => {
            return appointment.appointmentstatus === "E";
          }
        );
        console.log(
          "Active filtered appointments:",
          this.currentAppointmentsfilter
        );
      } else {
        this.currentAppointmentsfilter = [...this.currentAppointments];
      }
      console.log(
        "Active filtered appointments show:",
        this.currentAppointmentsfilter
      );
    },

    filterChooseAppointments() {
      if (!this.isActive) {
        this.chooseAppointmentsfilter = this.allAppointments.filter(appointment => {
          return appointment.appointmentstatus === "E";
        });
        // this.chooseAppointmentsfilter = [...this.allAppointments];
      } else {
        this.chooseAppointmentsfilter = [...this.allAppointments];
      }
    },

    selectSpecificDay(date) {
      this.selectedDate = new Date(date);
      this.selectedTab = "specific";
      this.filterAppointmentsByDate(this.selectedDate);
    },

    // selectUpcomingAppointments() {
    //   this.selectedTab = "upcoming";
    //   this.fetchAllAppointments();
    // },

    // selectWeeklyAppointments() {
    //   this.selectedTab = "weekly";
    //   this.fetchAllAppointments();
    // },
    selectChooseAppointments() {
      this.selectedTab = "choose";
      this.fetchAllAppointments();
    },
    selectTodayAppointments() {
      this.selectedTab = "today";
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric"
      });
    },
    formatedDate(date) {
      return new Date(date).toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric"
      });
    },
    formatTime(time) {
      if (!time || typeof time !== 'string') {
        console.error('Invalid time provided:', time);
        return 'Invalid Time';
      }
      const formattedTime = time.padStart(5, '0');
      return new Date(`2022-01-01T${formattedTime}`).toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true
      });
    },
    getPatientImage(appointment) {
      return (
        appointment.patientimage || require("@/assets/img/patients/patient.jpg")
      );
    },
    async fetchDoctorDetails() {
      const doctorId = sessionStorage.getItem("userdbid");
      this.DoctorId = doctorId;
      console.log("doctor id is ", doctorId);
      try {
        let docDetails = sessionStorage.getItem(`doctorDetails${doctorId}`);
        if (!docDetails) {
          const response = await axios.get(`/doctors/${doctorId}`);
          docDetails = response.data;
          sessionStorage.setItem(
            `doctorDetails${doctorId}`,
            JSON.stringify(docDetails)
          );
        } else {
          docDetails = JSON.parse(docDetails);
        }

        this.doctorDetails = docDetails;

        this.fetchDoctorStatCount(doctorId);
        this.formatDate();
      } catch (error) {
        console.error("Error fetching doctor details:", error);
      }
    },
    async fetchDoctorStatCount(doctorId) {
      try {
        const response = await axios.get(`/doctors/${doctorId}/doctorstats`);
        this.statDetails = response.data;
        console.log("Additional Details:", this.statDetails);
      } catch (error) {
        console.error("Error fetching doctors count:", error);
      }
    },
    fetchUpcomingAppointments() {
      const doctorId = 3;
      sessionStorage.getItem("userdbid");
      axios
        .get(`/doctors/${doctorId}/appointments?filter_type=upcoming`)
        .then(response => {
          this.upcomingAppointments = response.data.upcoming_appointments;
          this.currentAppointments = response.data.current_day_appointments;

          if (this.selectedDate && this.selectedTab === "specific") {
            this.filterAppointmentsByDate(this.selectedDate);
          } else if (this.selectedTab === "upcoming") {
            this.filterUpcomingAppointments();
          } else if (this.selectedTab === "today") {
            this.filterCurrentAppointments();
          }
          else if (this.selectedTab === "choose") {
            this.filterChooseAppointments();
          }
          // this.filterCurrentAppointments();
          this.filterCurrentAppointments();
          this.filterUpcomingAppointments();
          this.filterWeeklyAppointments();
          this.filterChooseAppointments();
        })
        .catch(error => {
          console.error("Error fetching upcoming appointments", error);
        });
    },
    // clarance
    async fetchAllAppointments() {
      if (!this.datetime) {
        await this.fetchDatetime();
      }
      const doctorId = sessionStorage.getItem("userdbid");
      // if (this.selectedTab === "weekly") {
      //   const firstDayOfWeek = new Date(currentDate);
      //   firstDayOfWeek.setDate(currentDate.getDate() - currentDate.getDay());
      //   this.startDate = firstDayOfWeek.toISOString().split('T')[0];
      //   const lastDayOfWeek = new Date(firstDayOfWeek);
      //   lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);
      //   this.endDate = lastDayOfWeek.toISOString().split('T')[0];
      // }

      // if (this.selectedTab === "upcoming") {
      //   const firstDayOfMonth = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), 1));
      //   this.startDate = firstDayOfMonth.toISOString().split('T')[0];
      //   const firstDayOfNextMonth = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
      //   const lastDayOfMonth = new Date(firstDayOfNextMonth.getTime() - 1);
      //   this.endDate = lastDayOfMonth.toISOString().split('T')[0];
      // }

      if (this.selectedTab === "choose") {
        this.startDate = this.startdatex;
        this.endDate = this.enddatex;

      }


      this.startdate = String(this.startDate);
      this.enddate = String(this.endDate);

      try {
        const response = await axios.get(`/doctors/${doctorId}/appointments`, {
          params: {
            filter_type: 'all',
            startDate: this.startdate,
            endDate: this.enddate,
          },
        });
        this.allAppointments = response.data.all_appointments;
        if (this.selectedDate && this.selectedTab === "specific") {
          this.filterAppointmentsByDate(this.selectedDate);
        }
        else if (this.selectedTab === "weekly") {
          this.filterWeeklyAppointments();
        }
        else if (this.selectedTab === "upcoming") {
          this.filterUpcomingAppointments();
        } else if (this.selectedTab === "today") {
          this.filterCurrentAppointments();
        } else if (this.selectedTab === "choose") {
          this.filterChooseAppointments();
        }
        this.filterCurrentAppointments();
        this.filterUpcomingAppointments();
        this.filterWeeklyAppointments();
        this.filterChooseAppointments();
      } catch (error) {
        console.error("Error fetching all appointments", error);
      }
    },

    viewAppointmentDetails(appointment) {
      this.selectedAppointment = appointment;
    },
    cancelAppointment(appointment) {
      const {
        appointmentid,
        appointmentby,
        appointmentdate,
        appointmenttime
      } = appointment;

      Swal.fire({
        title: "Are you sure?",
        text: `Do you want to cancel the appointment on ${this.formatDate(
          appointmentdate
        )} at ${this.formatTime(appointmenttime)}?`,
        input: "text",
        inputLabel: "Reason for cancellation",
        inputPlaceholder: "Enter reason here...",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, cancel it!"
      }).then(result => {
        if (result.isConfirmed) {
          const cancelReason = result.value;

          const cancelApiUrl = `/patients/${appointmentby}/appointments/${appointmentid}`;
          axios
            .put(cancelApiUrl, { cancelreason: cancelReason })
            .then(response => {
              console.log("Appointment cancelled successfully", response.data);

              this.fetchUpcomingAppointments();
              this.fetchDoctorStatCount(this.DoctorId);
            })
            .catch(error => {
              console.error("Error cancelling appointment", error);
            });
        }
      });
    },
    fetchAppointments() {
      axios
        .get(`/doctors/${this.doctorId}/appointments`)
        .then(response => {
          this.currentAppointments = response.data.current_day_appointments;
        })
        .catch(error => {
          console.error("Error fetching appointments:", error);
        });
    },
    exportAppointments(format) {
      if (format === "pdf") {
        if (this.selectedTab === "choose") {
          if (!this.isActive) {
            const upcomingAppointmentspdf = this.allAppointments.filter(
              appointment => {
                return appointment.appointmentstatus === "E";
              }
            );
            this.exportToPDF(this.upcomingAppointmentspdf);
          } else {
            this.exportToPDF(this.allAppointments);
          }
        } else if (this.selectedTab === "today") {
          if (!this.isActive) {
            const currentAppointmentspdf = this.currentAppointments.filter(
              appointment => {
                return appointment.appointmentstatus === "E";
              }
            );
            this.exportToPDF(this.currentAppointmentspdf);
          } else {
            this.exportToPDF(this.currentAppointments);
          }
        } else if (this.selectedTab === "specific") {
          if (!this.isActive) {
            const appointmentsForSelectedDayfilter = this.filteredAppointments.filter(
              appointment => {
                return (
                  new Date(appointment.appointmentdate).toLocaleDateString() ===
                  this.selectedDate.toLocaleDateString() &&
                  appointment.appointmentstatus === "E"
                );
              }
            );
            this.exportToPDF(appointmentsForSelectedDayfilter);
          } else {
            const appointmentsForSelectedDay = this.filteredAppointments.filter(
              appointment => {
                return (
                  new Date(appointment.appointmentdate).toLocaleDateString() ===
                  this.selectedDate.toLocaleDateString()
                );
              }
            );
            this.exportToPDF(appointmentsForSelectedDay);
          }
        }
      } else if (format === "excel") {
        if (this.selectedTab === "choose") {
          if (!this.isActive) {
            this.upcomingAppointmentspdf = this.allAppointments.filter(
              appointment => {
                return appointment.appointmentstatus === "E";
              }
            );
            this.exportToExcel(this.allAppointments);
          }
          this.exportToExcel(this.allAppointments);
        } else if (this.selectedTab === "today") {
          if (!this.isActive) {
            this.currentAppointmentspdf = this.currentAppointments.filter(
              appointment => {
                return appointment.appointmentstatus === "E";
              }
            );
            this.exportToExcel(this.upcomingAppointmentspdf);
          }
          this.exportToExcel(this.currentAppointments);
        } else if (this.selectedTab === "specific") {
          if (!this.isActive) {
            const appointmentsForSelectedDayfilter = this.filteredAppointments.filter(
              appointment => {
                return (
                  new Date(appointment.appointmentdate).toLocaleDateString() ===
                  this.selectedDate.toLocaleDateString() &&
                  appointment.appointmentstatus === "E"
                );
              }
            );
            this.exportToExcel(appointmentsForSelectedDayfilter);
          } else {
            const appointmentsForSelectedDay = this.filteredAppointments.filter(
              appointment => {
                return (
                  new Date(appointment.appointmentdate).toLocaleDateString() ===
                  this.selectedDate.toLocaleDateString()
                );
              }
            );
            this.exportToExcel(appointmentsForSelectedDay);
          }
        }
      }
    },
    exportToPDF(Appointments) {
      const doc = new jsPDF();
      const doctorName = this.doctorDetails.doctorname;

      const formattedDoctorName = `Doctor name: ${doctorName}`;
      // console.log("docDetails.doctorname",docDetails.doctorname);
      const appointments = Appointments.map(appointment => [
        appointment.firstname + " " + appointment.lastname,
        appointment.appointmentdate,
        this.formatTime(appointment.appointmenttime),
        appointment.clinicname
      ]);

      let heading;
      if (this.selectedTab === "choose") {
        heading = "Appointments";
      } else if (this.selectedTab === "upcoming") {
        heading = "Upcomming Appointments";
      } else {
        heading = "Appointments"
      }

      const gap = 10;
      const gapx = 25;
      const yPos = gap;
      const textWidth =
        (doc.getStringUnitWidth(heading) * doc.internal.getFontSize()) /
        doc.internal.scaleFactor;
      const xPos = (doc.internal.pageSize.width - textWidth) / 2;
      doc.text(heading, xPos, yPos);
      const doctorNameFontSize = 10;
      const headingFontSize = 16;

      doc.setFontSize(doctorNameFontSize);
      doc.text(formattedDoctorName, 15, gapx);

      doc.autoTable({
        head: [["Patient Name", "Appt Date", "Appt Time", "Clinic Name"]],
        body: appointments,
        // startY: yPos + gap
        startY: gap + gap + 10
      });
      doc.save("appointments.pdf");
    },
    exportToExcel(Appointments) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Appointments");
      worksheet.columns = [
        { header: "Patient Name", key: "patientName", width: 30 },
        { header: "Appt Date", key: "appointmentDate", width: 20 },
        { header: "Appt Time", key: "appointmentTime", width: 20 },
        { header: "Clinic Name", key: "appointmentClinic", width: 40 }
      ];
      Appointments.forEach(appointment => {
        worksheet.addRow({
          patientName: appointment.firstname + " " + appointment.lastname,
          appointmentDate: appointment.appointmentdate,
          appointmentTime: this.formatTime(appointment.appointmenttime),
          appointmentClinic: appointment.clinicname
        });
      });
      workbook.xlsx.writeBuffer().then(buffer => {
        const blob = new Blob([buffer], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "appointments.xlsx";
        link.click();
      });
    }
  },
  async created() {
    await this.fetchDatetime();
    await this.fetchAllAppointments();
  },
  async mounted() {
    this.initDateRangePicker();
    this.initDateRangePickerok();
    await this.fetchDatetime();
    await this.fetchAllAppointments();

  },

  async beforeMount() {
    this.fetchDoctorDetails();
    this.fetchUpcomingAppointments();
    await this.fetchDatetime();
    await this.fetchAllAppointments();
    this.fetchAppointments();
    const percent = ref(75);
    setInterval(() => {
      if (percent.value === 25) {
        percent.value = 75;
      } else {
        percent.value = 25;
      }
    }, 1000);
  },
  name: "doctor-dashboard"
};
</script>
<style scoped>
.nav-link {
  background-color: #d3d9df;
  border-color: #ced4da;
  transition: background-color 0.3s;
  margin: 5px 0;
}

.text-input {
  display: none;
}

.inline-container {
  display: flex;
}

@media (max-width: 768px) {
  .export-iconx {
    margin-left: 1px;
    width: 15px;
    height: 35px;
  }
}

.containerx {
  display: flex;
  justify-content: center;
  align-items: center;
}

.export-buttons {
  margin-left: auto;
  display: flex;
  align-items: center;
}

@media (max-width: 767px) {
  .export-buttons {
    margin-top: 10px;
  }
}

.export-item {
  margin-right: 10px;
}

.custom-select {
  background: none;
  border: none;
  padding: 0;
  font-size: 8px;
  text-align: center;
  text-align-last: center;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  cursor: pointer;
  display: inline-block;
}

@media (max-width: 767px) {
  .custom-select {
    font-size: 10px;
  }
}

.custom-select option {
  color: #008000;
  background-color: #f0f0f0;
  padding: 5px;
}

.generate-button {
  background: none;
  border: none;
  color: white;
  padding: 0;
  font-size: 8px;
  margin-left: 10px;
  cursor: pointer;
}

@media (max-width: 767px) {
  .generate-button {
    font-size: 10px;
  }
}

.generate-button:hover {
  color: grey;
}

@media (max-width: 768px) {
  .export-item {
    margin-right: 2px;
  }
}

.nav-link:hover {
  background-color: #0056b3;
}

.modal-content .btn {
  cursor: auto;
}

.export-icon {
  width: 40px;
  height: 40px;
}

.export-iconx {
  width: 30px;
  height: 40px;
}

.flex-containerx {
  display: flex;
  align-items: center;
  justify-content: center;
}

.download-text {
  font-size: 12px;
  display: inline;
  margin: 0;
  margin-right: 10px;
  font-weight: bold;
}
</style>
