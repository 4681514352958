<script setup>
import { toRefs } from 'vue';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-vue';
// `route` `user` and `signOut` all are reactive.
const { route, user, signOut } = toRefs(useAuthenticator());
</script>
<template>
  <!-- Header -->
  <header class="header header-fixed" v-if="isHomeOneRoute" :class="{ 'header-one': true, 'header-space': isScrolled }">
    <div class="container">
      <nav class="navbar navbar-expand-lg header-nav">
        <div class="navbar-header">
          <a id="mobile_btn" href="javascript:void(0);" @click="toggleSidebar">
            <span class="bar-icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </a>
          <router-link to="/" class="navbar-brand logo">
            <img src="@/assets/img/logo.png" class="img-fluid" alt="Logo" />
          </router-link>

          <div class="language-switcher" v-if="isHomePage">
            <select v-model="currentLanguage" @change="changeLanguage" class="form-select">
              <option value="en">English</option>
              <option value="kn">ಕನ್ನಡ</option>
              <option value="hi">हिंदी</option>
              <option value="ma">മലയാളം</option>
            </select>
          </div>
        </div>

        <div class="main-menu-wrapper">
          <div class="menu-header">
            <router-link to="/" class="menu-logo">
              <img src="@/assets/img/logo.png" class="img-fluid" alt="Logo" />
            </router-link>

            <a id="menu_close" class="menu-close" href="javascript:void(0);" @click="closeSidebar">
              <i class="fas fa-times"></i>
            </a>


          </div>
          <mainnav :user="user"></mainnav>
        </div>
        <ul class="nav header-navbar-rht" v-if="isHomeAccRoute && user">

          <!-- Notifications -->
          <li class="nav-item dropdown noti-nav me-3 pe-0">
            <a href="javascript:;" class="dropdown-toggle nav-link p-0" data-bs-toggle="dropdown">
              <i class="fa-solid fa-bell"></i> <span class="badge" v-if="notificationCount">{{ notificationCount }}</span>
            </a>
            <div class="dropdown-menu notifications dropdown-menu-end">
              <div class="topnav-dropdown-header">
                <span class="notification-title">Notifications</span>
              </div>
              <div class="noti-content">
                <ul class="notification-list" v-if="notifications">
                  <!-- Use v-for to loop through notifications and display them -->
                  <li v-for="(notification, index) in notifications" :key="index" class="notification-message">
                    <a href="javascript:;">
                      <div class="notify-block d-flex">
                        <div class="media-body">
                          <h6>
                            {{ notification.title }} <span class="notification-time">{{ notification.time }}</span>
                          </h6>
                          <p class="noti-details">{{ notification.description }}</p>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <!-- /Notifications -->

          <!-- User Menu -->
          <li class="nav-item dropdown has-arrow logged-item">
            <a href="javascript:;" class="dropdown-toggle nav-link" data-bs-toggle="dropdown">
              <span class="user-img">
                <img class="rounded-circle" :src="profileImageUrl || defaultImageUrl" width="31"
                  alt="{{ given_name }}" />
              </span>
            </a>
            <div class="dropdown-menu dropdown-menu-end">
              <div class="user-header">
                <div class="avatar avatar-sm">
                  <!-- <img src="@/assets/img/doctors/doctor-thumb-01.jpg" alt="User Image"
                    class="avatar-img rounded-circle" /> -->
                  <img :src="profileImageUrl || defaultImageUrl" alt="User Image" class="avatar-img rounded-circle" />
                </div>
                <div class="user-text">
                  <p class="text-muted mb-0">Dr.{{ given_name }}</p>
                </div>
              </div>
              <router-link class="dropdown-item" to="/doctor-dashboard">Dashboard</router-link>
              <router-link class="dropdown-item" to="/doctor-profile-settings">Profile Settings</router-link>
              <router-link class="dropdown-item" to="" @click="signOut">Logout</router-link>
            </div>
          </li>
          <!-- /User Menu -->
        </ul>
        <ul class="nav header-navbar-rht" v-if="isHomeBillRoute">
          <!-- User Menu -->
          <li class="nav-item dropdown has-arrow logged-item">
            <a href="javascript:;" class="dropdown-toggle nav-link" data-bs-toggle="dropdown">
              <span class="user-img">
                <img class="rounded-circle" src="@/assets/img/patients/patient.jpg" width="31" alt="{{ given_name }}" />
              </span>
            </a>
            <div class="dropdown-menu dropdown-menu-end">
              <div class="user-header">
                <div class="avatar avatar-sm">
                  <img src="@/assets/img/patients/patient.jpg" alt="User Image" class="avatar-img rounded-circle" />
                </div>
                <div class="user-text">
                  <h6>Dr.{{ given_name }}</h6>

                </div>
              </div>
              <router-link class="dropdown-item" to="/doctor-dashboard">Dashboard</router-link>
              <router-link class="dropdown-item" to="/doctor-profile-settings">Profile Settings</router-link>
              <router-link class="dropdown-item" to="" @click="signOut">Logout</router-link>
            </div>
          </li>
          <!-- /User Menu -->
        </ul>
        <ul class="nav header-navbar-rht" v-if="isHomeprescriptionRoute">
          <!-- User Menu -->
          <li class="nav-item dropdown has-arrow logged-item">
            <a href="javascript:;" class="dropdown-toggle nav-link" data-bs-toggle="dropdown">
              <span class="user-img">
                <img class="rounded-circle" src="@/assets/img/doctors/doctor-thumb-01.jpg" width="31"
                  alt="{{ given_name }}" />
              </span>
            </a>
            <div class="dropdown-menu dropdown-menu-end">
              <div class="user-header">
                <div class="avatar avatar-sm">
                  <img src="@/assets/img/doctors/doctor-thumb-01.jpg" alt="User Image"
                    class="avatar-img rounded-circle" />
                </div>
                <div class="user-text">
                  <h6>{{ given_name }}</h6>
                  <p class="text-muted mb-0">Doctor</p>
                </div>
              </div>
              <router-link class="dropdown-item" to="/doctor-dashboard">Dashboard</router-link>
              <router-link class="dropdown-item" to="/doctor-profile-settings">Profile Settings</router-link>
              <router-link class="dropdown-item" to="" @click="signOut">Logout</router-link>
            </div>
          </li>
          <!-- /User Menu -->
        </ul>
        <ul class="nav header-navbar-rht" v-if="isHomepatientRoute && user">


          <!-- Notifications -->
          <li class="nav-item dropdown noti-nav me-3 pe-0">
            <a href="javascript:;" class="dropdown-toggle nav-link p-0" data-bs-toggle="dropdown">
              <i class="fa-solid fa-bell"></i> <span class="badge" v-if="notificationCount">{{ notificationCount
                }}</span>
            </a>
            <div class="dropdown-menu notifications dropdown-menu-end">
              <div class="topnav-dropdown-header">
                <span class="notification-title">Notifications</span>
              </div>
              <div class="noti-content">
                <ul class="notification-list" v-if="notifications">
                  <!-- Use v-for to loop through notifications and display them -->
                  <li v-for="(notification, index) in notifications" :key="index" class="notification-message">
                    <a href="javascript:;">
                      <div class="notify-block d-flex">
                        <div class="media-body">
                          <h6>
                            {{ notification.title }} <span class="notification-time">{{ notification.time }}</span>
                          </h6>
                          <p class="noti-details">{{ notification.description }}</p>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <!-- /Notifications -->



          <!-- User Menu -->
          <li class="nav-item dropdown has-arrow logged-item">
            <a href="javacript:;" class="dropdown-toggle nav-link" data-bs-toggle="dropdown">
              <span class="user-img">
                <img class="rounded-circle" src="@/assets/img/patients/patient.jpg" width="31" alt="{{given_name}}" />
              </span>
            </a>
            <div class="dropdown-menu dropdown-menu-end">
              <div class="user-header">
                <div class="avatar avatar-sm">
                  <img src="@/assets/img/patients/patient.jpg" alt="User Image" class="avatar-img rounded-circle" />
                </div>
                <div class="user-text">
                  <h6>{{ given_name }}</h6>

                </div>
              </div>
              <router-link class="dropdown-item" to="/patient-dashboard">Dashboard</router-link>
              <router-link class="dropdown-item" to="/profile-settings">Profile Settings</router-link>
              <div class="dropdown-item" @click="signOut">Logout</div>
            </div>
          </li>
          <!-- /User Menu -->
        </ul>
      </nav>
      <div class="sidebar-overlay" :class="{ opened: isSidebarOpen }" @click="closeSidebar"></div>
    </div>
  </header>
  <header class="header" v-else-if="isHomePharmacyRoute">
    <div class="container">
      <nav class="navbar navbar-expand-lg header-nav">
        <div class="navbar-header">
          <a id="mobile_btn" href="javascript:void(0);" @click="toggleSidebar">
            <span class="bar-icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </a>
          <router-link to="/" class="navbar-brand logo">
            <img src="@/assets/img/logo.png" class="img-fluid" alt="Logo" />
          </router-link>
        </div>
        <div class="main-menu-wrapper">
          <div class="menu-header">
            <router-link to="/" class="menu-logo">
              <img src="@/assets/img/logo.png" class="img-fluid" alt="Logo" />
            </router-link>
            <a id="menu_close" class="menu-close" href="javascript:void(0);" @click="closeSidebar">
              <i class="fas fa-times"></i>
            </a>
          </div>
          <mainnav></mainnav>
        </div>
        <ul class="nav header-navbar-rht">
          <li class="nav-item contact-item">
            <div class="header-contact-img">
              <i class="far fa-hospital"></i>
            </div>
            <div class="header-contact-detail">
              <p class="contact-header">Contact</p>
              <p class="contact-info-header">+1 315 369 5943</p>
            </div>
          </li>
          <li class="nav-item">
            <router-link class="nav-link header-login" to="login">login / Signup
            </router-link>
          </li>
        </ul>
      </nav>
      <div class="sidebar-overlay" :class="{ opened: isSidebarOpen }" @click="closeSidebar"></div>
    </div>
  </header>
  <header class="header header-fixed" v-else v-bind:class="$route.meta.headerClass">
    <div class="container">
      <nav class="navbar navbar-expand-lg header-nav">
        <div class="navbar-header">
          <a id="mobile_btn" href="javascript:void(0);" @click="toggleSidebar">
            <span class="bar-icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </a>
          <router-link to="/" class="navbar-brand logo" v-if="isHomeFive1Route">
            <img src="@/assets/img/logo-white.svg" class="img-fluid" alt="Logo" />
          </router-link>
          <router-link to="/" class="navbar-brand logo" v-else>
            <img src="@/assets/img/logo.png" class="img-fluid" alt="Logo" />
          </router-link>
        </div>
        <div class="main-menu-wrapper">
          <div class="menu-header">
            <router-link to="/" class="menu-logo">
              <img src="@/assets/img/logo.png" class="img-fluid" alt="Logo" />
            </router-link>
            <a id="menu_close" class="menu-close" href="javascript:void(0);" @click="closeSidebar">
              <i class="fas fa-times"></i>
            </a>
          </div>
          <mainnav></mainnav>
        </div>
      </nav>
      <div class="sidebar-overlay" :class="{ opened: isSidebarOpen }" @click="closeSidebar"></div>
    </div>
  </header>
  <!-- /Header -->
</template>
<script>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { fetchAuthSession, fetchUserAttributes, getCurrentUser } from 'aws-amplify/auth';
const notifications = ref([]);
import defaultImageUrl from "@/assets/img/doctors/doctor-thumb-01.jpg";
import eventBus from "@/eventBus";

export default {
  data() {
    return {
      // isHomeSeven1Route: false,
      isScrolled: false,
      isSidebarOpen: false,
      isVisible: false,
      email: sessionStorage.getItem("useremail"),
      family_name: '',
      gender: '',
      given_name: sessionStorage.getItem("usergivenname"),
      usertype: sessionStorage.getItem('usertype'),
      phone_number: '',
      notifications: [],
      notificationCount: null,
      idpid: '',
      patientid: sessionStorage.getItem('userdbid'),
      currentLanguage: 'en',
      defaultImageUrl: defaultImageUrl,
      profileImageUrl: ""
    };
  },




  mounted() {
    // Add a scroll event listener when the component is mounted
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeMount() {
    this.currentAuthenticatedUser();
  },
  beforeUnmount() {
    // Remove the scroll event listener when the component is about to be unmounted
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    changeLanguage() {
      this.$i18n.locale = this.currentLanguage;
    },
    async fetchPatientProfileImage() {
      const doctorid = sessionStorage.getItem("userdbid");
      try {
        const response = await axios.get(
          `doctors/doctorimg/${doctorid}/download`,
          {
            responseType: "arraybuffer"
          }
        );

        const objectURL = window.URL.createObjectURL(new Blob([response.data]));
        this.profileImageUrl = objectURL;
      } catch (error) {
        console.error("Error fetching patient profile image:", error);
      }
    },
    async getPatientDetails(dbid) {
      try {
        console.log('Fetching Patient with dbid:', dbid);
        const response = await axios.get(`/patients/${dbid}`);
        const patient = response.data;
        this.given_name = patient.firstname;
        this.email = patient.email;
        this.phone_number = patient.contactnumbers;
        this.family_name = patient.lastname;
        this.gender = patient.gender;
      } catch (error) {
        console.error('Error fetching db ID:', error);
      }
    },
    async getDoctorDetails(dbid) {
      try {
        console.log('Fetching Doctor with dbid:', dbid);
        let docDetails = sessionStorage.getItem(`doctorDetails${dbid}`);
        if (!docDetails) {
          const response = await axios.get(`/doctors/${dbid}`);
          docDetails = response.data;
          sessionStorage.setItem(`doctorDetails${this.dbid}`, JSON.stringify(docDetails));
        } else {
          docDetails = JSON.parse(docDetails);
        }
        const doctor = docDetails;
        this.given_name = doctor.name;
        this.email = doctor.email;
        this.gender = patient.gender;
      } catch (error) {
        console.error('Error fetching db ID:', error);
      }
    },
    async fetchPatientDBId(idpid) {
      try {
        console.log('Fetching db ID with idpid:', idpid);
        const response = await axios.get(`/auth/identifier/${idpid}`);

        console.log('Response from get_patient_id API:', response);

        if (response.data && response.data.dbid) {
          const dbid = response.data.dbid;
          sessionStorage.setItem('userdbid', dbid);
          this.usertype = response.data.profile;
          sessionStorage.setItem('usertype', this.usertype);
          if (this.usertype === "Patient") {
            this.getPatientDetails(dbid);
          } else {
            this.getDoctorDetails(dbid);
          }
          return dbid;
        } else {
          console.error('DB ID not found in the response.');
        }

      } catch (error) {
        console.error('Error fetching db ID:', error);
      }
      return null;
    },
    async currentAuthenticatedUser() {
      let dbid = sessionStorage.getItem('userdbid');
      if (!dbid) {
        try {
          const session = await fetchAuthSession();
          store.commit('setAuthUser', session);
          const userSub = session.userSub;
          console.log(`The userId: ${userSub}`);
          sessionStorage.setItem("useridpid", userSub);
          const dbid = await this.fetchPatientDBId(userSub);
          this.fetchNotifications();
          return true;
        } catch (err) {

          return false;
        }
      } else {
        this.fetchNotifications();
        return true;
      }
    },
    async fetchNotifications() {
      let dbid = sessionStorage.getItem('userdbid');
      let usertype = sessionStorage.getItem('usertype');
      if (dbid && usertype === "Patient") {
        this.patientid = dbid;
        try {
          let notificationDetails = sessionStorage.getItem(`patientnotif${this.patientid}`);
          if (notificationDetails) {
            notificationDetails = JSON.parse(notificationDetails);
            const created = new Date(notificationDetails.created);
            const currentDate = new Date();
            //refetch notifications every 30 mins
            if ((currentDate - created) > 1000 * 60 * 30)
              notificationDetails = null;
          }
          if (!notificationDetails) {
            const response = await axios.get(`patients/${this.patientid}/notifications`);
            const notificationData = response.data;
            notificationDetails = {
              data: notificationData,
              created: new Date()
            }
            sessionStorage.setItem(`patientnotif${this.patientid}`, JSON.stringify(notificationDetails));
          }

          this.notifications = notificationDetails.data || [];
          if (this.notifications.length > 0)
            this.notificationCount = this.notifications.length;
        } catch (error) {
          console.error('Error fetching notifications:', error);
        }
      }

    },
    submitForm() {
      this.$router.push("/doctor-search-list");
    },
    handleScroll() {
      // Get the scroll position
      const scroll = window.scrollY;

      // Update the isScrolled data property based on the scroll position
      this.isScrolled = scroll > 35;
    },
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
      document.documentElement.classList.toggle("menu-opened");
    },
    closeSidebar() {
      this.isSidebarOpen = false;
      document.documentElement.classList.remove("menu-opened");
    },
    toggleElement() {
      this.isVisible = !this.isVisible;
    },
    signOut() {
      Auth.signOut()
        .then(data => {
          this.$router.push("/");
        }).catch(err => console.log(err))
    },

    // Function to fetch notifications from the API

  },
  async mounted() {
    await this.fetchPatientProfileImage();
    this.$emit('doctor-image', this.profileImageUrl);
    eventBus.on('dimageUploaded', this.fetchPatientProfileImage);

  },
  computed: {
    getNotificationsCount() {
      return Array.isArray(notifications.value) ? notifications.value.length : 0;
    },
    isHomePage() {

      return this.$route.path === '/';
    },
    isHomePharmacyRoute() {
      return this.$route.path === "/pharmacy-index";
    },
    isHomeOneRoute() {
      return (
        this.$route.path === "/" ||
        this.$route.path === "/about-us" ||
        this.$route.path === "/accounts" ||
        this.$route.path === "/add-billing" ||
        this.$route.path === "/add-prescription" ||
        this.$route.path === "/appointments" ||
        this.$route.path === "/available-timings" ||
        this.$route.path === "/blank-page" ||
        this.$route.path === "/blog-details" ||
        this.$route.path === "/blog-grid" ||
        this.$route.path === "/blog-list" ||
        this.$route.path.startsWith("/booking-2") ||
        this.$route.path === "/booking-success" ||
        this.$route.path.startsWith("/booking") ||
        this.$route.path.startsWith("/booking-success-one") ||
        this.$route.path.startsWith("/booking-confirm") ||
        this.$route.path === "/cart" ||
        this.$route.path === "/change-password" ||
        this.$route.path === "/chat-doctor" ||
        this.$route.path === "/chat" ||
        this.$route.path === "/checkout" ||
        this.$route.path === "/coming-soon" ||
        this.$route.path === "/components" ||
        this.$route.path === "/contact-us" ||
        this.$route.path === "/dependent" ||
        this.$route.path === "/doctor-add-blog" ||
        this.$route.path === "/doctor-blog" ||
        this.$route.path === "/doctor-change-password" ||
        this.$route.path === "/doctor-dashboard" ||
        this.$route.path === "/doctor-pending-blog" ||
        this.$route.path === "/doctor-profile-settings" ||
        this.$route.path.startsWith("/doctor") ||
        this.$route.path.startsWith("/patient-details") ||
        this.$route.path === "/doctor-register" ||
        this.$route.path === "/doctor-search-grid" ||
        this.$route.path === "/doctor-signup" ||
        this.$route.path === "/edit-billing" ||
        this.$route.path === "/edit-blog" ||
        this.$route.path === "/edit-prescription" ||
        this.$route.path === "/error-404" ||
        this.$route.path === "/error-500" ||
        this.$route.path === "/faq" ||
        this.$route.path === "/favourites" ||
        this.$route.path === "/forgot-password" ||
        this.$route.path === "/forgot-password2" ||
        this.$route.path === "/invoice-view" ||
        this.$route.path === "/invoices" ||
        this.$route.path === "/login-email-otp" ||
        this.$route.path === "/login-email" ||
        this.$route.path === "/login-phone-otp" ||
        this.$route.path === "/login" ||
        this.$route.path === "/maintenance" ||
        this.$route.path === "/map-grid" ||
        this.$route.path === "/map-list" ||
        this.$route.path === "/medical-details" ||
        this.$route.path === "/medical-records" ||
        this.$route.path === "/mobile-otp" ||
        this.$route.path === "/my-patients" ||
        this.$route.path === "/orders-list" ||
        this.$route.path === "/patient-accounts" ||
        this.$route.path === "/patient-dashboard" ||
        this.$route.path === "/patient-profile" ||
        this.$route.path === "/patient-signup" ||
        this.$route.path === "/payment-success" ||
        this.$route.path === "/pharmacy-details" ||
        this.$route.path === "/pharmacy-register" ||
        this.$route.path === "/pricing" ||
        this.$route.path === "/privacy-policy" ||
        this.$route.path === "/product-all" ||
        this.$route.path === "/product-checkout" ||
        this.$route.path === "/product-description" ||
        this.$route.path === "/profile-settings" ||
        this.$route.path === "/register" ||
        this.$route.path === "/reviews" ||
        this.$route.path === "/schedule-timings" ||
        this.$route.path === "/search-2" ||
        this.$route.path === "/search" ||
        this.$route.path === "/signup-success" ||
        this.$route.path === "/social-media" ||
        this.$route.path === "/terms-condition" ||
        this.$route.path === "/video-call" ||
        this.$route.path === "/voice-call" ||
        this.$route.path === "/pharmacy-search" ||
        this.$route.path === "/email-otp" ||
        this.$route.path === "/login-phone" ||
        this.$route.path === "/doctor-info"
      );
    },
    isHomeprescriptionRoute() {
      return (
        this.$route.path === "/add-prescription" ||
        this.$route.path === "/edit-blog" ||
        this.$route.path === "/edit-prescription"
      );
    },
    isHomeBillRoute() {
      return this.$route.path === "/add-billing" || this.$route.path === "/edit-billing";
    },
    isHomeAccRoute() {
      return (
        this.$route.path === "/accounts" ||
        this.$route.path === "/appointments" ||
        this.$route.path === "/available-timings" ||
        this.$route.path === "/chat-doctor" ||
        this.$route.path === "/doctor-add-blog" ||
        this.$route.path === "/doctor-blog" ||
        this.$route.path === "/doctor-change-password" ||
        this.$route.path === "/doctor-dashboard" ||
        this.$route.path === "/doctor-pending-blog" ||
        this.$route.path === "/doctor-profile-settings" ||
        this.$route.path === "/invoice-view" ||
        this.$route.path === "/invoices" ||
        this.$route.path === "/my-patients" ||
        this.$route.path === "/patient-profile" ||
        this.$route.path === "/reviews" ||
        this.$route.path === "/schedule-timings" ||
        this.$route.path === "/social-media" ||
        this.$route.path === "/video-call"
      );
    },
    isHomepatientRoute() {
      return (
        this.$route.path === "/" ||
        this.$route.path.startsWith("/booking") ||
        this.$route.path.startsWith("/booking-success-one") ||
        this.$route.path.startsWith("/booking-confirm") ||
        this.$route.path === "/booking" ||
        this.$route.path === "/cart" ||
        this.$route.path === "/change-password" ||
        this.$route.path === "/chat" ||
        this.$route.path === "/checkout" ||
        this.$route.path === "/dependent" ||
        this.$route.path === "/doctor" ||
        this.$route.path.startsWith("/patient-details") ||
        this.$route.path === "/favourites" ||
        this.$route.path === "/medical-details" ||
        this.$route.path === "/medical-records" ||
        this.$route.path === "/orders-list" ||
        this.$route.path === "/patient-accounts" ||
        this.$route.path === "/patient-dashboard" ||
        this.$route.path === "/search-2" ||
        this.$route.path === "/payment-success" ||
        this.$route.path === "/pharmacy-details" ||
        this.$route.path === "/product-all" ||
        this.$route.path === "/product-checkout" ||
        this.$route.path === "/product-description" ||
        this.$route.path === "/profile-settings" ||
        this.$route.path === "/voice-call" ||
        this.$route.path === "/pharmacy-search"
      );
    },
    isHomeFiveRoute() {
      return this.$route.path === "/index-5";
    },
    isHomeFive1Route() {
      return this.$route.path === "/index-5";
    },
    isHomeSevenRoute() {
      return this.$route.path === "/index-7" || this.$route.path === "/index-8";
    },
    isHomeSeven1RouteComputed() {
      return this.$route.path === "/index-7";
    },
    isHomeSeven2Route() {
      return (
        this.$route.path === "/index-7" ||
        this.$route.path === "/index-8" ||
        this.$route.path === "/index-9" ||
        this.$route.path === "/index-10"
      );
    },
    isHomeEightRoute() {
      return this.$route.path === "/index-8";
    },
    isHomeNineRoute() {
      return this.$route.path === "/index-9";
    },
    isHomeNine1Route() {
      return this.$route.path === "/index-9";
    },
    isHomeTenRoute() {
      return this.$route.path === "/index-10" || this.$route.path === "/index-11";
    },
    isHomeTen1Route() {
      return this.$route.path === "/index-10";
    },
    isHomeElevenRoute() {
      return this.$route.path === "/index-11";
    },
  },

};

</script>

<style scoped>
.navbar .nav-link:hover {
  color: inherit;
  background-color: transparent !important;
}

.language-switcher {
  position: relative;
  display: inline-block;
  margin-left: 10px;
}

.language-switcher select {
  padding: 5px 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  color: #333;
  font-size: 14px;
  outline: none;
}

.language-switcher select:focus {
  border-color: #aaa;
}

.language-switcher::before {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
}
</style>