/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_cognito_identity_pool_id": "ap-south-1:02ab3a75-7fb9-4ff1-ba4e-7475173e540b",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_MH2odF7I6",
    "aws_user_pools_web_client_id": "2gj3j66t0md4icir4i8escfhur",
    "oauth": {
        "domain": "docui-staging.auth.ap-south-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://yourdoc.in/",
        "redirectSignOut": "https://yourdoc.in/",
        "responseType": "token"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "FAMILY_NAME",
        "GENDER",
        "GIVEN_NAME",
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
