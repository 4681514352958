<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <!-- Page Content -->
    <div class="content top-space">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-8 offset-md-2">
            <!-- Register Content -->
            <div class="account-content">
              <div class="row align-items-center justify-content-center">
                <div class="col-md-7 col-lg-6 login-left">
                  <img
                    src="@/assets/img/login-banner.png"
                    class="img-fluid"
                    alt="YourDoc Register"
                  />
                </div>
                <div class="col-md-12 col-lg-6 login-right">
                  <div class="login-header">
                    <h3>
                      Patient Register
                      <!--<router-link to="doctor-register">Are you a Doctor?</router-link>-->
                    </h3>
                  </div>

                  <!-- Register Form -->
                  <form @submit.prevent="submitForm">
                    <p v-if="errors.length">
                      <b>Please correct the following error(s):</b>
                      <ul>
                        <li v-for="error in errors">{{ error }}</li>
                      </ul>
                    </p>
                    <div class="text" v-if="info">
                      Your email is already registered.<br>
                      <router-link class="forgot-link" to="login">Login</router-link> / 
                      <router-link class="forgot-link" to="forgot-password">Reset Password</router-link>
                    </div>
                    <div class="mb-3 form-focus">
                      <vue-tel-input
                       class="form-control floating"
                        mode='national'
                        @on-input="updateInput"
                        @focus="onFocus"
                        @blur="phoneTouched = true"
                        :defaultCountry='defaultCountry'
                        :autoFormat="autoFormat"
                        :only-countries="onlyCountries"
                        :inputOptions="inputOptions"
                        :dropdownOptions="dropdownOptions"
                        ref="phonenumber"
                                             
                      />
                      <label class="focus-label" :class="{ 'has-input': isFocused || phone_number }">
                        Mobile Number*
                      </label>
                      <!-- <div
                      v-if="phoneTouched && !validPhone()"
                      class="invalid-feedback"
                    >Please enter a valid phone number.</div> -->
                    </div>
                    <div class="mb-3 form-focus">
                      <b-form-input type="text" 
                      v-model="email" 
                      class="form-control floating" 
                      :class="{ 'is-invalid': emailTouched && !validEmail() }" 
                      @blur="emailTouched = true" 
                      label="Email" 
                      maxlength="45"/>
                      <label class="focus-label" :class="{ 'has-input': email }">Email</label>
                      
                    <div
                      v-if="emailTouched && !validEmail()"
                      class="invalid-feedback"
                    >Please enter a valid email address.</div>

                    </div>
                    <div class="mb-3 form-focus">
                      <b-form-input 
                      type="text" 
                      class="form-control floating " 
                      v-model="given_name" 
                      :class="{ 'is-invalid': firstnameTouched && !validfirstname() }"
                      @blur="firstnameTouched = true"
                      maxlength="45"
                      required
                      />
                      <label class="focus-label" :class="{ 'has-input': given_name }">First Name*</label>
                      <div
                      v-if="firstnameTouched && !validfirstname()"
                      class="invalid-feedback"
                    >Please enter a valid First Name.</div>
                  </div>
                    <div class="mb-3 form-focus">
                      <b-form-input 
                      type="text" 
                      v-model="family_name"
                      :class="{ 'is-invalid': lastnameTouched && !validlastname() }"
                      @blur="lastnameTouched = true"
                      maxlength="45"
                      
                      />
                     
                      <label class="focus-label" :class="{ 'has-input': family_name }">Last Name</label>
                      <div
                      v-if="lastnameTouched && !validlastname()"
                      class="invalid-feedback"
                    >Please enter a valid last Name.</div>
                    </div>
                    <div class="mb-3 form-focus">
                      <select
                        class="form-select form-control floating"
                        v-model="gender"
                        required
                      >
                        <!-- <option disabled value="">Select Gender</option> -->
                        <option value="M">Male</option>
                        <option value="F">Female</option>
                        <option value="O">Other</option>
                      </select>
                      <label class="focus-label" :class="{ 'has-input': gender }">Gender*</label>
                      <div v-if="genderTouched && !gender" class="invalid-feedback">
                        Please select a gender.
                      </div>
                    </div>
                    <div class="mb-3 form-focus position-relative">
                      <input 
                      :type="showPassword ? 'text' : 'password'"
                     
                      class="form-control floating" 
                      v-model="password" 
                      required 
                      :class="{ 'is-invalid': passwordTouched && !passwordRules(pwd) }"
                      @blur="passwordTouched=true"
                      maxlength="25"
                      />
                      <div
                      v-if="passwordTouched && !passwordRules(pwd)"
                      class="invalid-feedback"
                    >Please enter a valid password.</div>

                      <label class="focus-label" :class="{ 'has-input': password }">Create Password</label>
                      <button type="button" class="password-toggle" @click="togglePasswordVisibility">
                          <i :class="showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"></i>
                        </button>

                    
                      
                    </div>
                    <div class="text-end">
                      <router-link class="forgot-link" to="login"
                        >Already have an account?</router-link
                      >
                    </div>
                    <b-button
                      class="btn btn-primary w-100 btn-lg login-btn"
                      type="submit"
                    ><span
                      class="spinner-border spinner-border-sm me-2"
                      role="status" v-if="working"
                    ></span
                    >
                      Signup
                    </b-button>
                    <div class="mb-3 form-check-box terms-check-box">
                      <div class="form-group-flex">
                        <label class="custom_check">
                          By signing up, I agree with YourDoc's
                          <a target="_blank" href="/terms-condition">Terms of Service</a> and
                          <a target="_blank" href="/privacy-policy">Privacy Policy.</a>
                        </label>
                      </div>
                    </div>
                    <!--<div class="login-or">
                      <span class="or-line"></span>
                      <span class="span-or">or</span>
                    </div>
                    <div class="row social-login">
                      <div class="col-6">
                        <a href="javascript:void(0)" class="btn btn-facebook w-100"
                          ><i class="fab fa-facebook-f me-1"></i> Login</a
                        >
                      </div>
                      <div class="col-6">
                        <a href="javascript:void(0)" class="btn btn-google w-100"
                          ><i class="fab fa-google me-1"></i> Login</a
                        >
                      </div>
                    </div>-->
                  </form>
                  <!-- /Register Form -->
                </div>
              </div>
            </div>
            <!-- /Register Content -->
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->
    <doctorfooter />
  </div>
  <!-- /Main Wrapper -->
</template>
<script>
import axios from "axios";


export default {
  data() {
    return {
      errors: [],
      isFocused: true,
      info: null,
      working: false,
      valid: false,
      email: null,
      phone_number: null,
      given_name: '',
      family_name: '',
      password: null,
      gender:'',
      firstnameTouched: false,
      lastnameTouched: false,
      emailTouched: false,
      phoneTouched: false,
      passwordTouched: false,
      showPassword: false,
      autoFormat: false,
      defaultCountry: 'IN',
      onlyCountries: [
          'IN', 'US', 'GB', 'CA', 'AU', 
          'AE', 'BH', 'KW', 'OM', 'QA', 'SA', 
          'SG', 'MY', 'NZ'
        ],
      dropdownOptions: {
        showFlags: true,
        showDialCodeInList:true,
        // showCountryName: true,
        showDialCodeInSelection: true,
         
      },
      inputOptions: {
        maxlength: "10",
        placeholder: "",
        required: true,
        autofocus: true,
        // styleClasses: 'form-control floating'

      },
      isValidPhone: ''
    };
  },
  computed: {
  },
  mounted(){
     this.$refs.phonenumber.focus();
  },
  methods: {
    updateInput(phone, phoneObject){
      this.phone_number = phoneObject.number // E.164 number convention
      this.isValidPhone = phoneObject.valid 

    },
    validfirstname() {
      const firstnameRegex = /^[a-zA-Z\s]{1,25}$/;
      return firstnameRegex.test(this.given_name);
    },
    validlastname() {
      const lastnameRegex = /^[a-zA-Z]{1,25}$/;
      return this.family_name === null || this.family_name === '' || lastnameRegex.test(this.family_name);
    },
    validEmail() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return this.email === null || this.email === '' || emailRegex.test(this.email);
    },
    validPhone() {
      const phoneNumberRegex = /^\+\d{10,14}$/;
      return phoneNumberRegex.test(this.phone_number) && this.isValidPhone;
    },
    
    passwordRules(pwd) {
      let passwordRegex =  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&^])[A-Za-z\d@.#$!%*?&]{8,25}$/;
      return passwordRegex.test(this.password);
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    validateFields() {
      if(!this.email) this.errors.push("Please enter an email");
      if(!this.password) this.errors.push("Please enter a password");
      if(!this.given_name) this.errors.push("Please enter your first name");
      if(!this.gender) this.errors.push("Please enter the gender");
      if(this.phone_number && !this.validPhone(this.phone_number)) this.errors.push("Enter a valid phone number");
      if(!this.validEmail(this.email)) this.errors.push("Enter a valid email");     
      if(this.password && !this.passwordRules(this.password)) this.errors.push("Password is weak; should be min 8 characters, atleast one uppercase, lowercase character, one number and one special character");
    },
    async submitForm() {
      console.log("thi is my number", this.phone_number)
      console.log("Signing up ", this.email)
      this.errors = [];
      this.info = null;
      this.working = true;
      this.validateFields();
      let phone = this.phone_number;
      if (!phone.startsWith("+")) phone = "+91"+ phone;
      if (this.errors.length>0) {
        this.working = false;
        return;
      }
      try {

         const otpResponse = await axios.post('/otp', {
            email: this.email,
            mobileNumber: phone,
          });

        // console.log('Patient record created successfully', response.data);
        // if (nextStep.signUpStep === "CONFIRM_SIGN_UP") {
        //   localStorage.setItem("confirmotpemail", nextStep.codeDeliveryDetails.destination);
        //   localStorage.setItem("signupemail", this.email);
        //   localStorage.setItem("signuppatientid", response.data);
        //   this.$router.push("/signup-otp");
        // } 
         if (otpResponse.data.status === "success") {
        // Store necessary details
            localStorage.setItem("confirmotpemail", this.email);
            localStorage.setItem("signupemail", this.email);
            localStorage.setItem("signupphone", phone);
            // localStorage.setItem("signuppatientid", response.data);
            localStorage.setItem("givenname", this.given_name);
            localStorage.setItem("password", this.password);
            localStorage.setItem("familyname", this.family_name);
            localStorage.setItem("gender", this.gender);

            // Redirect to the OTP confirmation page
            this.$router.push("/signup-otp");
          } 
        else {
          
          this.$router.push("/");
        }
      } catch (error) {
        this.working = false;
        console.log(error);
        if (error.name === "UsernameExistsException") {
          this.info = 'Your email is already registered.'
        } else
          this.errors.push(error.message);
      }
    },
  },
};
</script>
<style scoped>
.position-relative {
  position: relative;
}
.password-toggle {
  position: absolute;
  top: 52%;
  right: 30px;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
}
.password-toggle .fas {
  font-size: 1.2em;
  color: #6c757d;
}

.form-focus .form-control {
    height: 40px;
    padding: 21px 12px 6px;
}
.vue-tel-input input {
  border: none;
  box-shadow: none;
  outline: none; /* Remove any box-shadow if applicable */
}

.vue-tel-input {
  display: flex; /* Ensures the elements inside are aligned in a row */
  align-items: center; /* Vertically centers the input field with the flag */
}

.vue-tel-input input {
  border: none;
  box-shadow: none;
  outline: none;
  flex-grow: 1; /* Ensures the input takes the remaining space */
  padding-left: 8px; /* Adds some spacing between the flag and the input */
  height: 40px;
   /* Adjust height if needed to match other form elements */
}

.vue-tel-input:focus-within {
  box-shadow: none;
  border-color: #bbb !important;
}

</style>
