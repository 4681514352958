<template>
  <div class="main-wrapper">
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <layoutheader></layoutheader>

      <!-- Page Content -->
      <div class="doctor-content">
        <div class="container">
          <!-- Booking Success -->
          <div class="row">
            <div class="col-md-12">
              <div class="back-link">
                <router-link to @click="handleClick">
                  <i class="fa-solid fa-arrow-left-long"></i> {{ $t('back') }}
                </router-link>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-lg-6 col-md-8">
              <div class="success-content" v-if="!error">
                <h4>{{ $t('review_booking_details') }}</h4>
                <div>{{ $t('not_booked_yet') }}</div>
              </div>
              <div v-else>
                <h4>{{ $t('oops_that_didnt_work') }}</h4>
                <div>{{ error }}</div>
                <span v-if="before_time" class="AvailabilityRow-slots">
                  <a @click="changeTime(before_time)" class="AvailabilityRow-action AvailabilityRow-pill">{{
                    formatTime(before_time) }}</a>
                </span>
                <span v-if="after_time" class="AvailabilityRow-slots">
                  <a @click="changeTime(after_time)" class="AvailabilityRow-action AvailabilityRow-pill">{{
                    formatTime(after_time) }}</a>
                </span>
              </div>
              <div class="card booking-card">
                <div class="card-body booking-card-body booking-list-body">
                  <div class="booking-doctor-left booking-success-info">
                    <div class="booking-doctor-img">
                      <a href="javascript:void(0);">
                        <img
                          :src="doctorImage || require('@/assets/img/doctors/doctor-thumb-01.jpg')"
                          :alt="doctorDetails.doctorname" class="img-fluid" />
                      </a>
                    </div>
                    <div class="booking-doctor-info">
                      <h4>
                        <a href="javascript:void(0);">{{ doctorDetails.doctorname }}</a>
                      </h4>
                      <p>{{ doctorDetails.qualifications }}, {{ doctorDetails.specialization }}</p>
                      <div class="booking-doctor-location">
                        <p>
                          <a target="_blank"
                            :href="'https://www.google.com/maps/search/?api=1&ll=12.9141%2C74.8560&query_place_id=&z=13&query=' + clinicname">
                            <i class="feather-map-pin"></i>
                            {{ clinicname }}</a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="booking-list">
                    <div class="booking-date-list consultation-date-list">
                      <ul>
                        <li>
                          {{ $t('booking_date') }}:
                          <span>{{ formatDate(appointmentday) }}</span>
                        </li>
                        <li>
                          {{ $t('booking_time') }}:
                          <span>{{ formatTime(appointmenttime) }}</span>
                        </li>
                        <!-- <div :key="patient.patientid"> -->
                        <li>
                          {{ $t('patient_name') }}:
                          <span>{{ patientlist.firstname }} {{ patientlist.lastname }}</span>
                        </li>
                        <!-- </div> -->
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="success-btn">
                <router-link to='' @click="handleClick" class="btn btn-secondary">
                  {{ $t('make_changes') }}
                </router-link>&nbsp;
                <router-link to="#" class="btn btn-primary submit-btn" @click="confirmBooking"><span
                    class="spinner-border spinner-border-sm me-2" role="status" v-if="working"></span>
                  {{ $t('yes_book') }}</router-link>
              </div>
            </div>
          </div>
          <!-- /Booking Success -->
        </div>
      </div>
      <!-- /Page Content -->

      <cursor></cursor>
    </div>
    <!-- /Main Wrapper -->
  </div>
</template>
<script>
import axios from "axios";
import { format } from "date-fns";
import { enUS } from "date-fns/locale";
import moment from "moment";

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

export default {
  data() {
    return {
      doctorDetails: {},
      patientlist: [],
      appointmentday: this.$store.getters.getAppointmentDraft.day,
      appointmenttime: this.$store.getters.getAppointmentDraft.time,
      patientid: this.$store.getters.getAppointmentDraft.patientid,
      appointmentby: this.$store.getters.getAppointmentDraft.appointmentby,
      clinicid: this.$store.getters.getAppointmentDraft.clinic,
      clinicname: this.$store.getters.getAppointmentDraft.clinicname,
      doctorid: this.$store.getters.getAppointmentDraft.doctorid,
      age: this.$store.getters.getAppointmentDraft.age,
      reason: this.$store.getters.getAppointmentDraft.reason,
      doctorImage: this.$store.getters.getAppointmentDraft.doctorImage,
      error: null,
      before_time: null,
      after_time: null,
      working: false
    };
  },
  beforeMount() {
    this.fetchPatients();
  },
  methods: {
      handleClick() {
      const userType = sessionStorage.getItem("usertype");

      const allowedUserTypes = ["Doctor", "Clinic", "Patient"];
  
      if (allowedUserTypes.includes(userType)) {
        this.$router.go(-1);
      } else {
        this.$router.go(-2);
      }
    },
    async fetchPatients() {
      try {
        const response = await axios.get(`/patients/${this.patientid}`);
        this.patientlist = response.data;
      } catch (error) {
        console.error("Error fetching patient details:", error);
      }
    },
    formatDate(date) {
      if (!date) return "";
      const parsedDate = moment(date, "YYYY-MM-DD");
      if (!parsedDate.isValid()) {
        console.error("Invalid date format:", date);
        return "";
      } 
      return parsedDate.toDate().toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric"
      });
    },
    formatTime(time) {
      console.log(this.appointmenttime);
      if (!time) return "";
      return new Date(`2022-01-01T${time}`).toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true
      });
    },

    async confirmBooking() {
      this.working = true;
      const appointmentData = {
        patientid: this.patientid,
        doctorid: this.doctorid,
        clinicid: this.clinicid,
        appointmentdate: this.appointmentday,
        appointmenttime: this.appointmenttime,
        age: this.age,
        reason: this.reason,
        locale: this.$i18n.locale
      };

      let response;
      try {
        if (!this.appointmentby)
          this.appointmentby = this.patientid;
        response = await axios.post(
          `/patients/${this.appointmentby}/appointments`,
          appointmentData
        );

        if (response.status === 201 || response.status === 200) {
          const appointmentId = parseInt(
            response.data.appointmentid.match(/\d+/)[0]
          );

          console.log("Appointment booked successfully:", response.data);
          //this.$store.dispatch("resetAppointmentDraft", {
          //});
          this.$router.push({
            path: `/booking-success-one/${appointmentId}`,
            query: { patientId: this.appointmentby }
          });
        } else {
          this.error = response.data;
          this.working = false;
        }

        //CognitoIdentityServiceProvider.2gj3j66t0md4icir4i8escfhur.LastAuthUser
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.detail
        ) {
          const respdata = error.response.data.detail;
          if (respdata.code === "SLOT_TAKEN") {
            this.error =
              "The appointment could not be booked as the slot is already taken. Slot is available at following times";
            if (respdata.before_time) this.before_time = respdata.before_time;
            if (respdata.after_time) this.after_time = respdata.after_time;
            console.error("Error :", error);
          } else if (respdata.code === "EMAIL_ERROR") {
            const appointmentId = parseInt(
              response.data.details.appointmentid.match(/\d+/)[0]
            );
            this.$router.push({
              path: `/booking-success-one/${appointmentId}`,
              query: { patientId: this.patientid }
            });
          } else {
            this.error = "Some went wrong! Please try again later";
            console.error("Error :", error);
          }
        } else {
          this.error = "Some went wrong! Please try again later";
          console.error("Error :", error);
        }
        this.working = false;
      }
    },
    changeTime(selecttime) {
      this.$store.dispatch("updateAppointmentDraft", { time: selecttime });
      this.appointmenttime = selecttime;
      this.before_time = null;
      this.after_time = null;
      this.error = null;
    },
    async fetchDoctorDetails(doctorId) {

      try {
        let docDetails = sessionStorage.getItem(`doctorDetails${doctorId}`);
        if (!docDetails) {
          const response = await axios.get(`/doctors/${doctorId}`);
          docDetails = response.data;
          sessionStorage.setItem(`doctorDetails${doctorId}`, JSON.stringify(docDetails));
        } else {
          docDetails = JSON.parse(docDetails);
        }
        this.doctorDetails = docDetails;
      } catch (error) {
        console.error("Error fetching doctor details:", error);
      }
    }
  },
  mounted() {
    const doctorid = this.$store.getters.getAppointmentDraft.doctorid;
    this.fetchDoctorDetails(doctorid);
  }
};
</script>

<style>
.btn-group:hover .btn {
  background-color: #0E82FD;
  color: white;
}
</style>