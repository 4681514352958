<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <breadcrumb-doctor :title="title" :text="text" :text1="text1" />

    <!-- Page Content -->
    <div class="content">
      <div class="container">
        <div class="row">
          <doctorsidebar></doctorsidebar>

          <div class="col-md-7 col-lg-8 col-xl-9">
            <div class="card">
              <div class="card-body">
                <!-- Social Form -->                
                <form @submit.prevent="updateDoctorDetails">
                  <div class="row">
                    <div class="col-md-12 col-lg-8">
                      <div class="mb-3">
                        <label class="mb-2">Facebook URL</label>
                        <input type="text" class="form-control" v-model="doctorDetails.facebookurl" />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 col-lg-8">
                      <div class="mb-3">
                        <label class="mb-2">Twitter URL</label>
                        <input type="text" class="form-control" v-model="doctorDetails.twitterurl" />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 col-lg-8">
                      <div class="mb-3">
                        <label class="mb-2">Instagram URL</label>
                        <input type="text" class="form-control" v-model="doctorDetails.instagramurl" />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 col-lg-8">
                      <div class="mb-3"> 
                        <label class="mb-2">Pinterest URL</label>
                        <input type="text" class="form-control" v-model="doctorDetails.pintresturl" />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 col-lg-8">
                      <div class="mb-3">
                        <label class="mb-2">LinkedIn URL</label>
                        <input type="text" class="form-control" v-model="doctorDetails.linkedinurl" />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 col-lg-8">
                      <div class="mb-3">
                        <label class="mb-2">Youtube URL</label>
                        <input type="text" class="form-control" v-model="doctorDetails.youtubeurl" />
                      </div>
                    </div>
                  </div>
                  <div class="submit-section">
                    <b-button type="submit" class="btn btn-primary submit-btn">
                      Save Changes
                    </b-button>
                  </div>
                </form>
                <!-- /Social Form -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->
    <doctorfooter />
  </div>
  <!-- /Main Wrapper -->
</template>
<script>
import axios from "axios";
import Swal from 'sweetalert2';
export default {
  data() {
    return {
      title: "Social Media",
      text: "Home",
      text1: "Social Media",
      doctorDetails: {
        facebookurl: "",
        twitterurl: "",
        linkedinurl: "",
        youtubeurl: "",
        instagramurl: "",
        pintresturl: "",
      }
  };
},

beforeMount(){
  this.fetchDoctorDetails();
},
methods: { 
    async fetchDoctorDetails() {
      this.doctorid =  sessionStorage.getItem('userdbid');
      try {
        let docDetails = sessionStorage.getItem(`doctorDetails${this.doctorid}`);
        if (!docDetails) {
          const response = await axios.get(`/doctors/${this.doctorid}`);
          docDetails = response.data;
          sessionStorage.setItem(`doctorDetails${this.doctorId}`, JSON.stringify(docDetails));
        } else {
          docDetails = JSON.parse(docDetails);
        }
        this.doctorDetails = docDetails;
      } catch (error) {
        console.error("Error fetching doctor details:", error);
      }
    },
    async updateDoctorDetails() {
      try {
    
        const response = await axios.put(`/doctors/${this.doctorid}/socials`, this.doctorDetails);
        console.log("Doctor details updated successfully:", response.data);
      Swal.fire({
          icon: "success",
          title: "Success",
          text: "Doctor details updated successfully!",
        });
        
      } catch (error) {
        console.error("Error updating doctor details:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to update Doctor details. Please check details entered/Try again later.",
        });
        
      }
    },
},
};
</script>
