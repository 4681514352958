<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <clinicheader></clinicheader>
    <breadcrumb-clinic :title="title" :text="text" :text1="text1" />
    <!-- Page Content -->
    <div class="content">
      <div class="container">
        <div class="row">
          <doctorsidebar2></doctorsidebar2>

          <div class="col-md-7 col-lg-8 col-xl-9">
            <div class="row">
              <div class="col-md-12">
                <div class="card dash-card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-12 col-lg-4">
                        <div class="dash-widget dct-border-rht">
                          <div class="circle-bar circle-bar2">
                            <div class="circle-graph2">
                              <circle-progress :percent="65" />
                              <img src="@/assets/img/icon-02.png" class="img-fluid" alt="Patient" />
                            </div>
                          </div>
                          <div class="dash-widget-info">
                            <h6>Appointments Today</h6>
                            <h3>{{statDetails.todayAppointments}}</h3>
                            <p class="text-muted">{{formattedDate}}</p>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-12 col-lg-4">
                        <div class="dash-widget">
                          <div class="circle-bar circle-bar3">
                            <div class="circle-graph3">
                              <circle-progress :percent="50" />
                              <img src="@/assets/img/icon-03.png" class="img-fluid" alt="Patient" />
                            </div>
                          </div>
                          <div class="dash-widget-info">
                            <h6>Upcomming Appointments</h6>
                            <h3>{{statDetails.upcomingAppointment}}</h3>
                            <p class="text-muted">{{formattedDate}}</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 col-lg-4">
                        <div class="dash-widget dct-border-rht">
                          <div class="circle-bar circle-bar1">
                            <div class="circle-graph1">
                              <circle-progress :percent="75" />
                              <img src="@/assets/img/icon-01.png" class="img-fluid" alt="patient" />
                            </div>
                          </div>
                          <div class="dash-widget-info">
                            <h6>Total Patients</h6>
                            <h3>{{statDetails.totalPatients}}</h3>
                            <p class="text-muted">Till Today</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <h4 class="mb-4">Patient Appointments</h4>
                <div class="d-flex justify-content-between mb-3">
                   <router-link to="/add-clinic-appointment" class="btn btn-primary">
                     Add Appointment
                  </router-link>
                  <div>
                    <a
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="PDF"
                      @click="exportAppointments('pdf')"
                    >
                      <img src="@/assets/img/pdf.svg" alt="Export to PDF" class="export-icon" />
                    </a>
                    <a
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Excel"
                      @click="exportAppointments('excel')"
                    >
                      <img src="@/assets/img/excel.svg" alt="Export to Excel" class="export-icon" />
                    </a>
                  </div>
                </div>


                <div class="appointment-tab">
                  <!-- Appointment Tab -->
                  <ul class="nav nav-tabs nav-tabs-solid nav-tabs-rounded">
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        href="#today-appointments"
                        data-bs-toggle="tab"
                        @click="selectTodayAppointments"
                      >Today  <span class="badge custom-badge ms-1">{{ todayCount }}</span></a>
                    </li>
                    <li class="nav-item" v-for="(day, index) in nextFiveDays" :key="index">
                      <a
                        class="nav-link"
                        href="#specific-appointments"
                        @click="selectSpecificDay(day)"
                        data-bs-toggle="tab"
                      >{{ formatShortDate(day) }} <span class="badge custom-badge ms-1">{{ nextFiveDaysCount[index] }}</span></a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="#upcoming-appointments"
                        @click="selectUpcomingAppointments"
                        data-bs-toggle="tab"
                      >All Upcoming <span class="badge custom-badge ms-1">{{ upcomingCount }}</span></a>
                    </li>
                  </ul>
                  <!-- /Appointment Tab -->

                  <div class="tab-content">
                    <!-- Upcoming Appointment Tab -->
                    <div class="tab-pane" id="upcoming-appointments">
                      <div class="card card-table mb-0">
                        <div class="card-body">
                          <div class="table-responsive">
                            <table class="table table-hover table-center mb-0">
                              <thead>
                                <tr></tr>
                                <tr>
                                  <th>Patient Name</th>
                                  <th>Appt Date</th>
                                  <th>Clinic Name</th>
                                  <th>Reason</th>
                                  <!-- <th>Type</th> -->
                                  <!-- <th class="text-center">Paid Amount</th> -->
                                  <th style="text-align: right;">
                                    <label class="custom_check" style="text-align: right;">
                                      <input
                                        type="checkbox"
                                        id="active-appointments"
                                        v-model="isActive"
                                        @change="toggleActiveStatus"
                                        checked
                                      />
                                      <span class="checkmark"></span>
                                      Include Cancelled
                                    </label>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="appointment in upcomingAppointmentsfilter"
                                  :key="appointment.id"
                                >
                                  <td>
                                    <h2 class="table-avatar">
                                      <router-link
                                        :to="'patient-profile/' + appointment.patientid"
                                        class="avatar avatar-sm me-2"
                                      >
                                        <img
                                          class="avatar-img rounded-circle"
                                          :src="getPatientImage(appointment)"
                                          alt="User Image"
                                        />
                                      </router-link>
                                      <router-link :to="'patient-profile/' + appointment.patientid">
                                        {{ appointment.firstname }} {{ appointment.lastname }}
                                        <span>{{ appointment.patientid }}</span>
                                      </router-link>
                                    </h2>
                                  </td>
                                  <td>
                                    {{ formatedDate(appointment.appointmentdate) }}
                                    <span
                                      class="d-block text-info"
                                    >
                                      {{
                                      formatTime(appointment.appointmenttime)
                                      }}
                                    </span>
                                  </td>
                                  <td>{{ appointment.clinicname }}</td>
                                  <td>{{ appointment.reason }}</td>
                                  <!-- <td>{{ item.Type }}</td> -->
                                  <!-- <td class="text-center">{{ item.PaidAmount }}</td> -->
                                  <td class="text-end">
                                    <div class="table-action">
                                      <a
                                        href="javascript:void(0)"
                                        class="btn btn-sm bg-info-light"
                                        data-bs-toggle="modal"
                                        @click="viewAppointmentDetails(appointment)"
                                        data-bs-target="#appt_details"
                                      >
                                        <i class="far fa-eye"></i> View
                                      </a>

                                      <!-- <a
                                        href="javascript:void(0);"
                                        class="btn btn-sm bg-success-light me-2"
                                      >
                                        <i class="fas fa-check"></i> Accept
                                      </a>-->
                                      <a
                                        v-if="appointment.appointmentstatus === 'E'"
                                        href="javascript:void(0);"
                                        class="btn btn-sm bg-danger-light"
                                        @click="cancelAppointment(appointment)"
                                      >
                                        <i class="fas fa-times"></i> Cancel
                                      </a>
                                      <span v-else>
                                        <span class="badge bg-danger text-white ms-2">Cancelled</span>
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- /Upcoming Appointment Tab -->
                    <!-- Today Appointment Tab -->
                    <div class="tab-pane show active" id="today-appointments">
                      <div class="card card-table mb-0">
                        <div class="card-body">
                          <div class="table-responsive">
                            <table class="table table-hover table-center mb-0">
                              <thead>
                                <tr>
                                  <th>Patient Name</th>
                                  <th>Appt Date</th>
                                  <th>Clinic Name</th>
                                  <th>Reason</th>
                                  <!-- <th>Type</th> -->
                                  <!-- <th class="text-center">Paid Amount</th> -->
                                  <th style="text-align: right;">
                                    <label class="custom_check" style="text-align: right;">
                                      <input
                                        type="checkbox"
                                        id="active-appointments"
                                        v-model="isActive"
                                        @change="toggleActiveStatus"
                                        checked
                                      />
                                      <span class="checkmark"></span>
                                      Include Cancelled
                                    </label>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="appointment in currentAppointmentsfilter"
                                  :key="appointment.appointmentid"
                                >
                                  <td>
                                    <h2 class="table-avatar">
                                      <router-link
                                        :to="'patient-profile/' + appointment.patientid"
                                        class="avatar avatar-sm me-2"
                                      >
                                        <img
                                          class="avatar-img rounded-circle"
                                          :src="
                                            getPatientImage(appointment)
      "
                                          alt="User Image"
                                        />
                                      </router-link>
                                      <router-link :to="'patient-profile/' + appointment.patientid">
                                        {{ appointment.firstname }} {{ appointment.lastname }}
                                        <span>{{ appointment.patientid }}</span>
                                      </router-link>
                                    </h2>
                                  </td>
                                  <td>
                                    {{ formatedDate(appointment.appointmentdate)
                                    }}
                                    <span
                                      class="d-block text-info"
                                    >
                                      {{
                                      formatTime(appointment.appointmenttime)
                                      }}
                                    </span>
                                  </td>
                                  <td>{{ appointment.clinicname }}</td>
                                  <td>{{ appointment.reason }}</td>
                                  <!-- <td>{{ item.Type }}</td>
                                  <td class="text-center">{{ item.PaidAmount }}</td>-->
                                  <td class="text-end">
                                    <div class="table-action">
                                      <a
                                        href="javascript:void(0)"
                                        class="btn btn-sm bg-info-light"
                                        data-bs-toggle="modal"
                                        @click="viewAppointmentDetails(appointment)"
                                        data-bs-target="#appt_details"
                                      >
                                        <i class="far fa-eye"></i> View
                                      </a>

                                      <!-- <a
                                        href="javascript:void(0);"
                                        class="btn btn-sm bg-success-light me-2"
                                      >
                                        <i class="fas fa-check"></i> Accept
                                      </a>-->
                                      <a
                                        v-if="appointment.appointmentstatus === 'E'"
                                        href="javascript:void(0);"
                                        class="btn btn-sm bg-danger-light"
                                        @click="cancelAppointment(appointment)"
                                      >
                                        <i class="fas fa-times"></i> Cancel
                                      </a>
                                      <span v-else>
                                        <span class="badge bg-danger text-white ms-2">Cancelled</span>
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- /Today Appointment Tab -->
                    <!-- Specific Day Appointment Tab -->
                    <div class="tab-pane" id="specific-appointments">
                      <div class="card card-table mb-0">
                        <div class="card-body">
                          <div class="table-responsive">
                            <table class="table table-hover table-center mb-0">
                              <thead>
                                <tr>
                                  <th>Patient Name</th>
                                  <th>Appt Date</th>
                                  <th>Clinic Name</th>
                                  <th>Reason</th>
                                  <th style="text-align: right;">
                                    <label class="custom_check" style="text-align: right;">
                                      <input
                                        type="checkbox"
                                        id="active-appointments"
                                        v-model="isActive"
                                        @change="toggleActiveStatus"
                                        checked
                                      />
                                      <span class="checkmark"></span>
                                      Include Cancelled
                                    </label>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="appointment in filteredAppointments"
                                  :key="appointment.id"
                                >
                                  <td>
                                    <h2 class="table-avatar">
                                      <router-link
                                        :to="'patient-profile/' + appointment.patientid"
                                        class="avatar avatar-sm me-2"
                                      >
                                        <img
                                          class="avatar-img rounded-circle"
                                          :src="getPatientImage(appointment)"
                                          alt="User Image"
                                        />
                                      </router-link>
                                      <router-link :to="'patient-profile/' + appointment.patientid">
                                        {{ appointment.firstname }} {{ appointment.lastname }}
                                        <span>{{ appointment.patientid }}</span>
                                      </router-link>
                                    </h2>
                                  </td>
                                  <td>
                                    {{ formatedDate(appointment.appointmentdate) }}
                                    <span
                                      class="d-block text-info"
                                    >{{ formatTime(appointment.appointmenttime) }}</span>
                                  </td>
                                  <td>{{ appointment.clinicname }}</td>
                                  <td>{{ appointment.reason }}</td>
                                  <td class="text-end">
                                    <div class="table-action">
                                      <a
                                        href="javascript:void(0)"
                                        class="btn btn-sm bg-info-light"
                                        data-bs-toggle="modal"
                                        @click="viewAppointmentDetails(appointment)"
                                        data-bs-target="#appt_details"
                                      >
                                        <i class="far fa-eye"></i> View
                                      </a>
                                      <a
                                        v-if="appointment.appointmentstatus === 'E'"
                                        href="javascript:void(0);"
                                        class="btn btn-sm bg-danger-light"
                                        @click="cancelAppointment(appointment)"
                                      >
                                        <i class="fas fa-times"></i> Cancel
                                      </a>
                                      <span v-else>
                                        <span class="badge bg-danger text-white ms-2">Cancelled</span>
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- /Specific Day Appointment Tab -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->
  </div>
  <!-- /Main Wrapper -->
  <!-- Appointment Details Modal -->
  <div class="modal fade custom-modal" id="appt_details">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Appointment Details</h5>
          <b-button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></b-button>
        </div>
        <div class="modal-body">
          <ul class="info-details">
            <li>
              <div class="details-header">
                <div class="row">
                  <div class="col-md-6">
                    <li>
                      <span class="title">Appointment ID:</span>
                      <span class="text">{{selectedAppointment.appointmentid}}</span>
                    </li>
                    <li>
                      <span class="title">Appointment Date & Time:</span>
                      <span
                        class="text"
                      >{{ formatDate(selectedAppointment.appointmentdate) }} {{ formatTime(selectedAppointment.appointmenttime) }}</span>
                    </li>
                  </div>
                  <div class="col-md-6">
                    <div class="text-end">
                      <b-button
                        type="button"
                        :class="{'btn bg-success-light btn-sm': appointmentStatus === 'Upcoming', 'btn bg-danger-light btn-sm': appointmentStatus !== 'Upcoming'}"
                        id="topup_status"
                      >{{ appointmentStatus }}</b-button>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <span class="title">Status:</span>
              <span class="text">{{ appointmentStatus }}</span>
            </li>
            <li>
              <span class="title">Confirm Date:</span>
              <span class="text">{{formatedDate(selectedAppointment.createddate)}}</span>
            </li>
            <!-- <li>
              <span class="title">Paid Amount</span>
              <span class="text">$450</span>
            </li>-->
          </ul>
        </div>
      </div>
    </div>
  </div>
  <clinicfooter />
  <!-- /Appointment Details Modal -->
</template>
<script>
import axios from "axios";
import doctorupcoming from "@/assets/json/doctor/doctorupcoming.json";
import doctortoday from "@/assets/json/doctor/doctortoday.json";
import "sweetalert2/dist/sweetalert2.css";
import Swal from "sweetalert2/dist/sweetalert2.js";

import CircleProgress from "vue3-circle-progress";
import "vue3-circle-progress/dist/circle-progress.css";
import { ref } from "vue";
import jsPDF from "jspdf";
import "jspdf-autotable";
import ExcelJS from "exceljs";
export default {
  components: {
    CircleProgress
  },
  data() {
    return {
      title: "Dashboard",
      text: "Clinic Dashboard",
      text1: "Clinic Doctor Dashboard ",
      doctorDetails: [],
      DoctorId: null,
      statDetails: [],
      formattedDate: "",
      upcomingAppointments: [],
      upcomingAppointmentsfilter: [],
      currentAppointments: [],
      currentAppointmentsfilter: [],
      selectedAppointment: {},
      filteredAppointments: [],
      selectedDate: null,
      doctorupcoming: doctorupcoming,
      doctortoday: doctortoday,
      selectedTab: "today",
      isActive: true,
      doctorinfo: [],
    };
  },
  computed: {
    appointmentStatus() {
      if (this.selectedAppointment.appointmentstatus === "E") {
        return "Upcoming";
      } else {
        return "Cancelled";
      }
    },
    nextFiveDays() {
      const today = new Date();
      today.setDate(today.getDate() + 1);
      const nextFiveDays = [];
      for (let i = 0; i < 6; i++) {
        const nextDate = new Date(today);
        nextDate.setDate(today.getDate() + i);
        const formattedDate = nextDate.toISOString().slice(0, 10);
        nextFiveDays.push(formattedDate);
      }
      return nextFiveDays;
    },
    todayCount() {
      return this.currentAppointmentsfilter.length;
    },
    nextFiveDaysCount() {
      return this.nextFiveDays.map(date => {
        return this.upcomingAppointmentsfilter.filter(appointment => {
          return new Date(appointment.appointmentdate).toISOString().slice(0, 10) === date;
        }).length;
      });
    },
    upcomingCount() {
      return this.upcomingAppointmentsfilter.length;
    }
  },

  methods: {
    toggleActiveStatus() {
      this.isActive = !this.isActive;
      this.toggle();
    },
    toggle() {
      this.isActive = !this.isActive;
      if (this.selectedDate) {
        this.filterAppointmentsByDate(this.selectedDate);
      }
      this.filterCurrentAppointments();
      this.filterUpcomingAppointments();
    },

    formatShortDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric"
      });
    },
    filterAppointmentsByDate(date) {
      if (!this.isActive) {
        this.filteredAppointments = this.upcomingAppointments.filter(
          appointment => {
            return (
              new Date(appointment.appointmentdate).toLocaleDateString() ===
                date.toLocaleDateString() &&
              appointment.appointmentstatus === "E"
            );
          }
        );
      } else {
        this.filteredAppointments = this.upcomingAppointments.filter(
          appointment => {
            return (
              new Date(appointment.appointmentdate).toLocaleDateString() ===
              date.toLocaleDateString()
            );
          }
        );
      }
      console.log("Filtered appointments:", this.filteredAppointments);
    },

    filterUpcomingAppointments() {
      if (!this.isActive) {
        this.upcomingAppointmentsfilter = this.upcomingAppointments.filter(
          appointment => {
            return appointment.appointmentstatus === "E";
          }
        );
      } else {
        this.upcomingAppointmentsfilter = [...this.upcomingAppointments];
      }
      console.log("Filtered appointments:", this.filteredAppointments);
    },

    filterCurrentAppointments() {
      if (!this.isActive) {
        this.currentAppointmentsfilter = this.currentAppointments.filter(
          appointment => {
            return appointment.appointmentstatus === "E";
          }
        );
        console.log(
          "Active filtered appointments:",
          this.currentAppointmentsfilter
        );
      } else {
        this.currentAppointmentsfilter = [...this.currentAppointments];
      }
      console.log(
        "Active filtered appointments show:",
        this.currentAppointmentsfilter
      );
    },

    selectSpecificDay(date) {
      this.selectedDate = new Date(date);
      this.selectedTab = "specific";
      this.filterAppointmentsByDate(this.selectedDate);
    },

    selectUpcomingAppointments() {
      this.selectedTab = "upcoming";
    },
    selectTodayAppointments() {
      this.selectedTab = "today";
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric"
      });
    },
    formatedDate(date) {
      return new Date(date).toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric"
      });
    },
    formatTime(time) {
      return new Date(`2022-01-01T${time}`).toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true
      });
    },
    getPatientImage(appointment) {
      return (
        appointment.patientimage || require("@/assets/img/patients/patient.jpg")
      );
    },
    
    async fetchDoctorStatCount() {
      const doctorId =  this.doctorinfo.doctorId;

      const clinicId = this.doctorinfo.clinicId;
      try {
        const response = await axios.get(`/clinic/${doctorId}/doctorstats/${clinicId}`);
        this.statDetails = response.data;
        console.log("Additional Details:", this.statDetails);
      } catch (error) {
        console.error("Error fetching doctors count:", error);
      }
    },
    fetchUpcomingAppointments() {
      
      axios
        .get(`/clinic/${this.doctorinfo.doctorId}/clinicappointments/${this.doctorinfo.clinicId}?filter_type=upcoming`)
        .then(response => {
          this.upcomingAppointments = response.data.upcoming_appointments;
          this.currentAppointments = response.data.current_day_appointments;

          if (this.selectedDate && this.selectedTab === "specific") {
            this.filterAppointmentsByDate(this.selectedDate);
          } else if (this.selectedTab === "upcoming") {
            this.filterUpcomingAppointments();
          } else if (this.selectedTab === "today") {
            this.filterCurrentAppointments();
          }
          // this.filterCurrentAppointments();
          this.filterCurrentAppointments();
          this.filterUpcomingAppointments();
        })
        .catch(error => {
          console.error("Error fetching upcoming appointments", error);
        });
    },
    viewAppointmentDetails(appointment) {
      this.selectedAppointment = appointment;
    },
    cancelAppointment(appointment) {
      const {
        appointmentid,
        appointmentby,
        appointmentdate,
        appointmenttime
      } = appointment;

      Swal.fire({
        title: "Are you sure?",
        text: `Do you want to cancel the appointment on ${this.formatDate(
          appointmentdate
        )} at ${this.formatTime(appointmenttime)}?`,
        input: "text",
        inputLabel: "Reason for cancellation",
        inputPlaceholder: "Enter reason here...",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, cancel it!"
      }).then(result => {
        if (result.isConfirmed) {
          const cancelReason = result.value;

          const cancelApiUrl = `/patients/${appointmentby}/appointments/${appointmentid}`;
          axios
            .put(cancelApiUrl, { cancelreason: cancelReason })
            .then(response => {
              console.log("Appointment cancelled successfully", response.data);

              this.fetchUpcomingAppointments();
              this.fetchDoctorStatCount(this.DoctorId);
            })
            .catch(error => {
              console.error("Error cancelling appointment", error);
            });
        }
      });
    },
    fetchAppointments() {
      axios
        .get(`/doctors/${this.doctorinfo.doctorId}/appointments?filter_type=upcoming`)
        .then(response => {
          this.currentAppointments = response.data.current_day_appointments;
        })
        .catch(error => {
          console.error("Error fetching appointments:", error);
        });
    },
    exportAppointments(format) {
      if (format === "pdf") {
        if (this.selectedTab === "upcoming") {
          if (!this.isActive) {
            const upcomingAppointmentspdf = this.upcomingAppointments.filter(
              appointment => {
                return appointment.appointmentstatus === "E";
              }
            );
            this.exportToPDF(this.upcomingAppointmentspdf);
          } else {
            this.exportToPDF(this.upcomingAppointments);
          }
        } else if (this.selectedTab === "today") {
          if (!this.isActive) {
            const currentAppointmentspdf = this.currentAppointments.filter(
              appointment => {
                return appointment.appointmentstatus === "E";
              }
            );
            this.exportToPDF(this.currentAppointmentspdf);
          } else {
            this.exportToPDF(this.currentAppointments);
          }
        } else if (this.selectedTab === "specific") {
          if (!this.isActive) {
            const appointmentsForSelectedDayfilter = this.filteredAppointments.filter(
              appointment => {
                return (
                  new Date(appointment.appointmentdate).toLocaleDateString() ===
                    this.selectedDate.toLocaleDateString() &&
                  appointment.appointmentstatus === "E"
                );
              }
            );
            this.exportToPDF(appointmentsForSelectedDayfilter);
          } else {
            const appointmentsForSelectedDay = this.filteredAppointments.filter(
              appointment => {
                return (
                  new Date(appointment.appointmentdate).toLocaleDateString() ===
                  this.selectedDate.toLocaleDateString()
                );
              }
            );
            this.exportToPDF(appointmentsForSelectedDay);
          }
        }
      } else if (format === "excel") {
        if (this.selectedTab === "upcoming") {
          if (!this.isActive) {
            this.upcomingAppointmentspdf = this.upcomingAppointments.filter(
              appointment => {
                return appointment.appointmentstatus === "E";
              }
            );
            this.exportToExcel(this.upcomingAppointmentspdf);
          }
          this.exportToExcel(this.upcomingAppointments);
        } else if (this.selectedTab === "today") {
          if (!this.isActive) {
            this.currentAppointmentspdf = this.currentAppointments.filter(
              appointment => {
                return appointment.appointmentstatus === "E";
              }
            );
            this.exportToExcel(this.upcomingAppointmentspdf);
          }
          this.exportToExcel(this.currentAppointments);
        } else if (this.selectedTab === "specific") {
          if (!this.isActive) {
            const appointmentsForSelectedDayfilter = this.filteredAppointments.filter(
              appointment => {
                return (
                  new Date(appointment.appointmentdate).toLocaleDateString() ===
                    this.selectedDate.toLocaleDateString() &&
                  appointment.appointmentstatus === "E"
                );
              }
            );
            this.exportToExcel(appointmentsForSelectedDayfilter);
          } else {
            const appointmentsForSelectedDay = this.filteredAppointments.filter(
              appointment => {
                return (
                  new Date(appointment.appointmentdate).toLocaleDateString() ===
                  this.selectedDate.toLocaleDateString()
                );
              }
            );
            this.exportToExcel(appointmentsForSelectedDay);
          }
        }
      }
    },
    exportToPDF(Appointments) {
      const doc = new jsPDF();
      const appointments = Appointments.map(appointment => [
        appointment.firstname + " " + appointment.lastname,
        appointment.appointmentdate,
        this.formatTime(appointment.appointmenttime),
        appointment.clinicname
      ]);

      let heading;
      if (this.selectedTab === "today") {
        heading = "Appointments for Today";
      } else if (this.selectedTab === "upcoming") {
        heading = "Upcomming Appointments";
      } else {
        heading = "Appointments for " + this.formatDate(this.selectedDate);
      }

      const gap = 10;

      const yPos = gap;

      const textWidth =
        (doc.getStringUnitWidth(heading) * doc.internal.getFontSize()) /
        doc.internal.scaleFactor;
      const xPos = (doc.internal.pageSize.width - textWidth) / 2;

      doc.text(heading, xPos, yPos);
      doc.autoTable({
        head: [["Patient Name", "Appt Date", "Appt Time", "Clinic Name"]],
        body: appointments,
        startY: yPos + gap
      });
      doc.save("appointments.pdf");
    },
    exportToExcel(Appointments) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Appointments");
      worksheet.columns = [
        { header: "Patient Name", key: "patientName", width: 30 },
        { header: "Appt Date", key: "appointmentDate", width: 20 },
        { header: "Appt Time", key: "appointmentTime", width: 20 },
        { header: "Clinic Name", key: "appointmentClinic", width: 40 }
      ];
      Appointments.forEach(appointment => {
        worksheet.addRow({
          patientName: appointment.firstname + " " + appointment.lastname,
          appointmentDate: appointment.appointmentdate,
          appointmentTime: this.formatTime(appointment.appointmenttime),
          appointmentClinic: appointment.clinicname
        });
      });
      workbook.xlsx.writeBuffer().then(buffer => {
        const blob = new Blob([buffer], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "appointments.xlsx";
        link.click();
      });
    }
  },
  beforeMount() {
    
    this.doctorinfo = this.$store.getters.getclinicDetails;
    this.fetchDoctorStatCount();
    this.fetchUpcomingAppointments();
    this.fetchAppointments();
    
    this.formatDate();
    console.log("this is doctor details", this.doctorclinicid)
    const percent = ref(75);
    setInterval(() => {
      if (percent.value === 25) {
        percent.value = 75;
      } else {
        percent.value = 25;
      }
    }, 1000);
  },
  name: "doctor-dashboard"
};
</script>
<style scoped>
.nav-link {
  background-color: #d3d9df;
  border-color: #ced4da;
  transition: background-color 0.3s;
  margin: 5px 0;
}

.nav-link:hover {
  background-color: #0056b3;
}

.modal-content .btn {
  cursor: auto;
}

.custom-badge {
  background-color: #708090;
  color: white;
}

.export-icon {
  width: 40px;
  /* Adjust the width as needed */
  height: 40px;
  /* Adjust the height as needed */
}
</style>
