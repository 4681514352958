<template>
  <div
    class="modal fade custom-modal custom-medicalrecord-modal"
    id="add_medical_records_modal"
    data-select2-id="add_medical_records_modal"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Medical Records</h5>
          <b-button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></b-button>
        </div>
        <form id="medical_records_form" enctype="multipart/form-data">
          <div class="modal-body">
            <b-alert v-model="showDismissibleAlert" variant="danger" dismissible>{{ error }}</b-alert>
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="mb-3">
                  <label class="mb-2">Patient</label>
                  <multiselect
                    v-model="selectedPatient"
                    :options="patientOptions"
                    group-values="patients"
                    group-label="group"
                    track-by="name"
                    label="name"
                    placeholder="Select Patient"
                    :multiple="false"
                  ></multiselect>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="mb-3">
                  <label class="mb-2">Test Name</label>
                  <input
                    v-model="testname"
                    type="text"
                    name="description"
                    class="form-control"
                    placeholder="Enter Test Name"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="mb-3">
                  <label class="mb-2">Ordered By</label>
                  <input
                    v-model="orderedby"
                    type="text"
                    name="description"
                    class="form-control"
                    placeholder="Enter Orderedby Name"
                  />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="mb-3">
                  <label class="mb-2">Test Date</label>
                  <div class="mb-3">
                    <datepicker
                      v-model="testdate"
                      placeholder="Date"
                      class="form-control datetimepicker"
                      :editable="true"
                      :clearable="false"
                      :input-format="dateFormat"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="mb-3">
                  <label class="mb-2">Upload</label>
                  <div class="upload-medical-records">
                    <input
                      class="form-control"
                      type="file"
                      name="user_file"
                      id="user_files_mr"
                      @change="handleFileUpload"
                      ref="fileInput"
                    />
                    <div class="upload-content dropzone">
                      <div class="text-center">
                        <div class="upload-icon mb-2">
                          <img src="@/assets/img/upload-icon.png" alt="upload-icon" />
                        </div>
                        <h5>Drag & Drop</h5>
                        <h6>
                          or
                          <span class="text-danger">Browse</span>
                        </h6>
                        <div v-if="imagePreview" class="image-preview d-flex align-items-center mt-2">
                          <template v-if="isImage(report_file.name)">
                            <img :src="imagePreview" alt="Image Preview" class="img-thumbnail" />
                            <div class="image-name ms-2">{{ report_file.name }}</div>
                          </template>
                          <template v-else>
                            <div class="pdf-preview">
                              <i class="fas fa-file-alt fa-4x"></i>
                              <div class="image-name ms-2">{{ report_file.name }}</div>
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="row">
              <div class="col-12">
                <div class="mb-3">
                  <label class="mb-2">Symptoms</label>
                  <input
                    type="text"
                    data-role="tagsinput"
                    class="input-tags form-control"
                    value="Fever, Fatigue"
                    name="services"
                    id="services"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="mb-3">
                  <label class="mb-2">Date</label>
                  <div class="mb-3">
                    <datepicker
                      v-model="startdate"
                      placeholder="Date"
                      class="form-control datetimepicker"
                      :editable="true"
                      :clearable="false"
                      :input-format="dateFormat"
                    />
                  </div>
                </div>
              </div>
            </div>-->
            <div class="text-center mt-4">
              <div class="submit-section text-center">
                <b-button
                  type="submit"
                  @click.prevent="submitForm"
                  id="medical_btn"
                  class="btn btn-primary submit-btn"
                >Submit</b-button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>


<script>
import Multiselect from "vue-multiselect";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  props: {
    appointmentId: {
      type: Number,
      required: true
    }
  },
  components: {
    Multiselect
  },
  data() {
    return {
      currentDate: new Date(),
      dateFormat: "dd-MM-yyyy",
      patientOptions: [
        {
          group: "Myself",
          patients: []
        },
        {
          group: "Dependents",
          patients: []
        }
      ],
      selectedPatient: null,
      testname: null,
      orderedby: null,
      testdate: new Date(),
      report_file: null,
      imagePreview: null,
      showDismissibleAlert: false,
      error: null
    };
  },
  created() {
    const patientId = sessionStorage.getItem("userdbid");

    const myselfIndex = this.patientOptions.findIndex(
      option => option.group === "Myself"
    );
    if (myselfIndex !== -1) {
      this.patientOptions[myselfIndex].patients.push({
        id: patientId,
        name: "Self"
      });
    }
    axios
      .get(`/patients/${patientId}/dependents`)
      .then(response => {
        this.patientOptions.find(
          option => option.group === "Dependents"
        ).patients = response.data.map(dependent => ({
          id: dependent.patientid,
          name: `${dependent.firstname} ${dependent.lastname}`
        }));
      })
      .catch(error => {
        console.error("Error fetching dependents:", error);
      });
  },
  methods: {
    isImage(filename) {
      return /\.(jpg|jpeg|png|gif)$/i.test(filename);
    },
    handleFileUpload(event) {
      this.report_file = event.target.files[0];
      if (this.report_file) {
        this.imagePreview = URL.createObjectURL(this.report_file);
      }
    },
    submitForm() {
      if (!this.selectedPatient) {
        this.error = "Please select a patient.";
        this.showDismissibleAlert = true;
        return;
      }

      if (!this.testname) {
        this.error = "Please enter a test name.";
        this.showDismissibleAlert = true;
        return;
      }

      if (!this.orderedby) {
        this.error =
          "Please enter the name of the person who ordered the test.";
        this.showDismissibleAlert = true;
        return;
      }

      if (!this.testdate || isNaN(this.testdate.getTime())) {
        this.error = "Please enter a valid test date.";
        this.showDismissibleAlert = true;
        return;
      }

      if (!this.report_file) {
        this.error = "Please upload a report file.";
        this.showDismissibleAlert = true;
        return;
      }
      const patientId = sessionStorage.getItem("userdbid");

      const formattedDate = this.testdate.toISOString().slice(0, 10);
      let formData = new FormData();
      formData.append("patientid", this.selectedPatient.id);
      formData.append("appointmentid", this.appointmentId !== undefined ? this.appointmentId : 0);
      formData.append("test_name", this.testname);
      formData.append("test_date", formattedDate);
      formData.append("order_by_doctor", this.orderedby);
      formData.append("account_type", "P");
      formData.append("report_file", this.report_file);

      let testFilePath = `patient/${
        this.selectedPatient.id
      }/${new Date().getFullYear()}/${this.$refs.fileInput.files[0].name}`;
      formData.append("test_file_path", testFilePath);

      const headers = {
        "Content-Type": "multipart/form-data"
      };
      axios
        .post(`/patients/${patientId}/report`, formData, { headers })
        .then(response => {
          console.log("Report created successfully:", response.data);
          if (response.status === 200) {
            console.log("Report created successfully:", response.data);
            Swal.fire({
              icon: "success",
              title: "Medical Records Added Successfully",
              showConfirmButton: false,
              timer: 1500
            });
            this.$emit("records-added");
            const closeButton = document.querySelector(
              "#add_medical_records_modal .btn-close"
            );
            if (closeButton) {
              closeButton.click();
              this.error = "";
              this.showDismissibleAlert = false;
              this.selectedPatient = null;
              this.testname = null;
              this.testdate = null;
              this.orderedby = null;
              this.report_file = null;
              this.imagePreview = null;
            }
          }
        })
        .catch(error => {
          console.error("Error creating report:", error);
          if (
            error.response &&
            error.response.status === 400 &&
            error.response.data &&
            error.response.data.detail
          ) {
            this.error = error.response.data.detail;
            this.showDismissibleAlert = true;
          } else {
            this.error =
              "An unexpected error occurred. Please try again later.";
            this.showDismissibleAlert = true;
          }
        });
    }
  }
};
</script>

<style>
.image-preview {
  display: flex;
  align-items: center;
}
.image-preview img {
  max-width: 50px;
  height: auto;
}
.image-name {
  font-size: 0.9em;
  color: #666;
  margin-left: 10px;
}
</style>

