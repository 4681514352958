<template>
  <div role="tabpanel" id="doc_overview" class="tab-pane fade show active" v-if="doctor">
    <div class="row">
      <div class="col-md-12 col-lg-9">
        <!-- About Details -->
        <div class="widget about-widget">
          <h4 class="widget-title">About Me</h4>
          <p>{{doctor.description}}</p>
        </div>
        <!-- /About Details -->

        <!-- Education Details -->
        <div class="widget education-widget">
          <h4 class="widget-title">Education</h4>
          <div class="experience-box">
            <ul class="experience-list">
              <li>
                <div class="experience-user">
                  <div class="before-circle"></div>
                </div>
                <div class="experience-content">
                  <div class="timeline-content">
                    <a href="#" class="name">{{doctor.qualifications}}</a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!-- /Education Details -->

        <!-- Experience Details -->
        <div class="widget experience-widget">
          <h4 class="widget-title">Experience</h4>
          <div class="experience-box">
            <ul class="experience-list">
              <li>
                <div class="experience-user">
                  <div class="before-circle"></div>
                </div>
                <div class="experience-content">
                  <div class="timeline-content">
                    <a href="#" class="name">{{doctor.experience}} years</a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!-- /Experience Details -->

        <!-- Services List 
        <div class="service-list">
          <h4>Services</h4>
          <ul class="clearfix">
            <li>Tooth cleaning</li>
            <li>Root Canal Therapy</li>
            <li>Implants</li>
            <li>Composite Bonding</li>
            <li>Fissure Sealants</li>
            <li>Surgical Extractions</li>
          </ul>
        </div>-->
        <!-- /Services List -->

        <!-- Specializations List -->
        <div class="service-list">
          <h4>Specialization</h4>
          <ul class="clearfix">
            <li>{{doctor.specialization}}</li>
          </ul>
        </div>
        <!-- /Specializations List -->
        <!-- Langauge List -->
        <div class="service-list">
          <h4>Languages Spoken</h4>
          <ul class="clearfix">
            <li>{{doctor.languagesspoken}}</li>
          </ul>
        </div>
        <div class="service-list">
          <h4>URL</h4>
          <ul class="clearfix">
            <button v-if="!showShareModal" @click="showShareModal = !showShareModal" class="share-button"><i
                class="fas fa-share"></i>
            </button>
            <li>{{ doctor.weburl }}</li>
            <div v-if="showShareModal" class="share-modal">
              <button class="share-button" @click="share('whatsapp')"><i class="fab fa-whatsapp"></i></button>
              <button class="share-button" @click="share('facebook')"><i class="fab fa-facebook-f"></i></button>
              <button class="share-button" @click="share('twitter')"><i class="fab fa-twitter"></i></button>
              <button class="share-button" @click="share('linkedin')"><i class="fab fa-linkedin-in"></i></button>
              <button class="share-button" @click="share('telegram')"><i class="fab fa-telegram-plane"></i></button>
            </div>
          </ul>
        </div>
        <div class="service-list">
          <h4>Social Media</h4>
          <ul class="clearfix">
            <li v-if="doctor.instagramurl" style="clear: both;">
              <img
                class="hover-effect"
                src="@/assets/img/Social-media/instagram.png"
                alt="Instagram"
                style="width: 20px; height: 20px;"
              />
              <a
                :href="doctor.instagramurl"
                target="_blank"
                :title="doctor.instagramurl"
                style="margin-left: 5px"
                class="hover-effect"
              >Instagram</a>
            </li>
            <li v-if="doctor.youtubeurl" style="clear: both;">
              <img
                class="hover-effect"
                src="@/assets/img/Social-media/youtube.png"
                alt="Youtube"
                style="width: 20px; height: 20px;"
              />
              <a
                :href="doctor.youtubeurl"
                target="_blank"
                :title="doctor.youtubeurl"
                style="margin-left: 5px"
              >YouTube</a>
            </li>
            <li v-if="doctor.facebookurl" style="clear: both;">
              <img
                class="hover-effect"
                src="@/assets/img/Social-media/facebook.png"
                alt="Facebook"
                style="width: 20px; height: 20px;"
              />
              <a
                :href="doctor.facebookurl"
                target="_blank"
                :title="doctor.facebookurl"
                style="margin-left: 5px"
              >FaceBook</a>
            </li>
            <li v-if="doctor.linkedinurl" style="clear: both;">
              <img
                class="hover-effect"
                src="@/assets/img/Social-media/linkedin.png"
                alt="Linkedin"
                style="width: 20px; height: 20px;"
              />
              <a
                :href="doctor.linkedinurl"
                target="_blank"
                :title="doctor.linkedinurl"
                style="margin-left: 5px"
              >Linkedin</a>
            </li>
            <li v-if="doctor.twitterurl" style="clear: both;">
              <img
                class="hover-effect"
                src="@/assets/img/Social-media/twitter.png"
                alt="Twitter"
                style="width: 20px; height: 20px;"
              />
              <a
                :href="doctor.twitterurl"
                target="_blank"
                :title="doctor.twitterurl"
                style="margin-left: 5px"
              >Twitter</a>
            </li>
            <li v-if="doctor.pinteresturl" style="clear: both;">
              <img
                class="hover-effect"
                src="@/assets/img/Social-media/pinterest.png"
                alt="Pinterest"
                style="width: 20px; height: 20px;"
              />
              <a
                :href="doctor.pinterest"
                target="_blank"
                :title="doctor.pinteresturl"
                style="margin-left: 5px"
              >Pinterest</a>
            </li>
          </ul>
        </div>
        <!-- /Language List -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    doctor: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      showShareModal: false,  // Declare showShareModal here
    };
  },
  methods: {
    share(platform) {
      let url = this.doctor.weburl;
      let encodedUrl = encodeURIComponent(url);
      let shareUrl = '';

      switch (platform) {
        case 'whatsapp':
          shareUrl = `https://wa.me/?text=${encodedUrl}`;
          break;
        case 'facebook':
          shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
          break;
        case 'twitter':
          shareUrl = `https://twitter.com/intent/tweet?text=${encodedUrl}`;
          break;
        case 'linkedin':
          shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`;
          break;
        case 'telegram':
          shareUrl = `https://t.me/share/url?url=${encodedUrl}`;
          break;
      }
      window.open(shareUrl, '_blank');
      this.showShareModal = false;
    },
  },
};
</script>

<style scoped>
.hover-effect {
  transition: transform 0.3s ease;
}

.hover-effect:hover {
  transform: scale(1.2);
}
</style>